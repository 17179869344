<template>
<!-- data analysis shown by chart -->
    <Analytics/>
</template>

<script>
import Analytics from '../../components/common/Analytics'
export default {
    name:'Analysis',
    components:{
        Analytics
    },
}
</script>