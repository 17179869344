<template>
    <v-container pa-0>
        <v-row justify="center" class="pb-3"><v-img :src="logoImg" max-height="50" max-width="100"
                contain></v-img></v-row>
        <v-row class="black" justify="center">
            <v-col cols="8" class="white--text">
                <h2>{{ quiz.quizTitle }}</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="white--text"><span id="displayTimeSecond"></span></v-col>
            <v-spacer></v-spacer>
            <v-col v-if="this.quiz.allowQuizStop == true"><v-icon class="white--text"
                    @click="quizEnd()">highlight_off</v-icon></v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="6">
                <v-form ref="form">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="9">{{ $t('question') }} {{ this.serialToShow }} nga
                                    {{ this.quiz.questionsCount }}</v-col>
                                <v-col cols="3" v-if="this.eachQuestionMark > 0">{{ $t('marks') }}:
                                    {{ this.eachQuestionMark }}</v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text class="black--text">
                            <h3 v-if="question.isCodeSnippet == true">
                                <pre>{{ question.questionDetail }}</pre>
                            </h3>
                            <h3 v-else>{{ question.questionDetail }}</h3>
                        </v-card-text>


                        <v-card-text>
                            <v-textarea :disabled="confirmationStatus === 'disableanswer'"
                                v-model="answerDescriptiveByUser" :label="$t('writeYourAnswerHere')" rows="5" auto-grow
                                clearable></v-textarea>
                        </v-card-text>
                        <v-card-text class="black--text">
                            <h4 v-if="hintStatus == 1 || hintStatus == 2">HintA : {{ question.hintA }}</h4>
                            <h4 v-if="hintStatus == 2">HintB : {{ question.hintB }}</h4>
                        </v-card-text>
                        <!-- popup here...... -->
                        <v-dialog v-model="showHintPopup" max-width="500">
                            <v-card>
                                <v-card-title style="word-break: keep-all;">
                                    <span>{{ $t('hintMessagePopup') }} {{ deductionPoints }}
                                        <v-icon style="display: inline;">mdi-diamond</v-icon> {{ $t('fromYourBalance') }}
                                    </span>
                                </v-card-title>
                                <v-card-actions>
                                    <v-btn class="black--text" @click="useHints(responseInitialId)">{{ $t('accept')
                                        }}</v-btn>
                                    <v-btn class="black--text" v-if="subscribeBtnPopup" @click="buyCredit()">{{
                                        $t('btnBuyCredit') }}</v-btn>
                                    <v-btn class="black--text" @click="showHintPopup = false">{{ $t('cancel') }}</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="correctAnswerPopup" max-width="500"
                            @click:outside="correctAnswerNavigatePopup(responseInitialId)">
                            <v-card>
                                <v-card-title style="word-break: keep-all;">{{ correctAnswerMsgPopup }}</v-card-title>
                                <v-card-actions>
                                    <v-btn class="black--text" @click="correctAnswerNavigatePopup(responseInitialId)">{{
                                        $t('ok') }}</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-card-actions>
                            <v-btn @click="submitAnswer(question)" :disabled="confirmationStatus === 'disableanswer'"
                                class="black--text">{{ $t('submitAnswer') }}</v-btn>
                            <v-btn @click="fetchDeductionPoints(responseInitialId)" class="black--text"
                                :disabled="confirmationStatus === 'disableanswer' || hintStatus == 2 || question.hintA == ''">{{
                                $t('Hint') }} {{ hintStatus }}{{ $t('/2') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="9" class="black--text">{{ $t('answerExplanation') }}</v-col>
                            <v-col cols="9">{{ question.answerExplanation }}</v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import config from '../../../public/config'
import i18n from '@/plugins/i18n'
import { mapGetters } from 'vuex';

export default {
    name: 'startQuiz',
    data() {
        return {
            quiz: {},
            interval: null,
            questionSerial: null,
            question: {},
            hostUrl: null,
            answerDescriptiveByUser: '',
            responseA: false,
            responseB: false,
            responseC: false,
            responseD: false,
            responseE: false,
            userAnswer: '',
            serialToShow: null,
            eachQuestionMark: 0,
            confirmationStatus: '',
            responseInitialId: '',
            hintStatus: 0,
            showHintPopup: false,
            correctAnswerPopup: false,
            correctAnswerMsgPopup: '',
            answerExplanation: '',
            deductionPoints: 0,
            subscribeBtnPopup: false,

        }
    },
    methods: {
        quizEnd() {
            this.$router.push({ name: 'Dashboard' })
        },

        getLastSingleQuestion(quizResponseInitialId, item = null, fromHint = false) {
            this.serialToShow = this.$store.getters['dashboard/serialToShow'];
            
            this.$store.dispatch('dashboard/applyLoading');
            this.$store.dispatch('dashboard/fetchLastSingleQuestion', quizResponseInitialId)
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading');

                    if (response.data) {
                        const { status, responseMsg, perQuestionMark } = response.data;
                        if (!fromHint) {
                            switch (status) {
                                case 'error':
                                case 'complete':
                                    this.$root.$emit('message_from_parent', responseMsg);
                                    break;
                                case 'notFound':
                                    this.$router.push({ name: "Dashboard" });
                                    break;
                                case 'scheduled':
                                case 'scheduledEarlier':
                                    this.correctAnswerMsgPopup = responseMsg;
                                    this.correctAnswerPopup = true;
                                    break;

                                default:
                                    if (item !== null) {
                                        this.$router.push({ name: 'StartQuiz' });
                                        this.$store.dispatch('dashboard/startQuiz', item);
                                    }
                                    break;
                            }
                        }

                        this.question = response.data;
                        this.eachQuestionMark = perQuestionMark;
                        const obj = {
                            responseInitialId: this.responseInitialId,
                            questionId: response.data.quizQuestionId
                        }
                        if (response.data.quizQuestionId > 0) {
                            this.checkHintStatus(obj)
                        }
                    }
                })
                .catch(err => {
                    this.$router.push({ name: 'NoInternet' });
                    console.error(err);
                });
        },
        resetResponse() {
            this.answerDescriptiveByUser = ''
            this.responseA = false
            this.responseB = false
            this.responseC = false
            this.responseD = false
            this.responseE = false
        },
        answer() {
            this.$store.dispatch('dashboard/applyLoading');
            this.resetResponse();

            const objAnswer = {
                quizResponseInitialId: this.$store.getters['dashboard/responseInitial'].quizResponse.quizResponseInitialId,
                userAnswer: this.userAnswer
            };

            this.$store.dispatch('dashboard/createDetailResponse', objAnswer)
                .then((responseObj) => {
                    this.$store.dispatch('dashboard/cancelLoading');
                    const response = responseObj.data;

                    if (response && response.status == "success") {
                        // this.$root.$emit('message_from_parent', response.responseMsg)
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent', response.responseMsg);
                        }, 2000);
                        this.confirmationStatus = this.$store.getters['dashboard/responseInitial'].confirmation.status

                        this.getLastSingleQuestion(this.$store.getters['dashboard/responseInitial'].quizResponse.quizResponseInitialId, null, false)

                    } else if (response.status == "error") {
                        // this.$root.$emit('message_from_parent', response.responseMsg)
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent', response.responseMsg);
                        }, 2000);
                    } else {
                        console.error("Unexpected response data:", response.data);
                    }
                })
                .catch((error) => {
                    this.$store.dispatch('dashboard/cancelLoading');

                    console.error("Error:", error);

                    this.$router.push({ name: 'NoInternet' });
                });
        },
        buyCredit() {
            this.showHintPopup = false
            this.$router.push({ name: 'Analytics' })
        },
        useHints(quizResponseInitialId) {
            if (this.hintStatus < 2) {
                this.$store.dispatch('dashboard/applyLoading');
                this.$store.dispatch('dashboard/useHintsResponse', quizResponseInitialId)
                    .then((responseObj) => {
                        this.$store.dispatch('dashboard/cancelLoading');
                        const response = responseObj.data;
                        if (response.status == "error") {
                            this.subscribeBtnPopup = false
                            this.$root.$emit('message_from_parent_long', response.responseMsg);
                        } else if (response.status == "noCredit") {
                            this.subscribeBtnPopup = true
                            this.$root.$emit('message_from_parent_long', response.responseMsg);
                        } else {
                            this.showHintPopup = false

                            const obj = {
                                responseInitialId: this.responseInitialId,
                                questionId: this.question.quizQuestionId
                            }
                            this.checkHintStatus(obj)
                            this.getLastSingleQuestion(quizResponseInitialId, null, true)
                        }
                    })
                    .catch((error) => {
                        this.$store.dispatch('dashboard/cancelLoading');
                        console.log(error)
                        this.$router.push({ name: 'NoInternet' });
                    });
            } else {
                // this.$root.$emit('message_from_parent', 'You have already used both hints for this question.');
                setTimeout(() => {
                    this.$root.$emit('message_from_parent', 'You have already used both hints for this question.');
                }, 2000);
                this.showHintPopup = false;
            }

        },
        fetchDeductionPoints(quizResponseInitialId) {
            this.$store.dispatch('dashboard/applyLoading');
            this.$store.dispatch('dashboard/getDeductionPointsResponse', quizResponseInitialId)
                .then((responseObj) => {
                    this.$store.dispatch('dashboard/cancelLoading');
                    const response = responseObj.data;
                    this.deductionPoints = response;
                    this.showHintPopup = true;
                })
                .catch((error) => {
                    this.$store.dispatch('dashboard/cancelLoading');
                    console.log(error)
                    this.$router.push({ name: 'NoInternet' });
                });
        },

        correctAnswerNavigatePopup() {
            this.$router.push({ name: "Dashboard" });
            this.correctAnswerPopup = false;
        },
        checkHintStatus(obj) {
            this.$store.dispatch('dashboard/checkHintStatusResponse', obj)
                .then((responseObj) => {
                    this.$store.dispatch('dashboard/cancelLoading');
                    const response = responseObj.data;
                    this.hintStatus = response.hintStatus;
                })
                .catch((error) => {
                    this.$store.dispatch('dashboard/cancelLoading');
                    console.log(error)
                    this.$router.push({ name: 'NoInternet' });
                });

        },
        submitAnswer() {
            this.userAnswer = ''
            this.userAnswer = this.answerDescriptiveByUser != null ? this.answerDescriptiveByUser.trim() : ''
            if (this.userAnswer == '') {
                this.$root.$emit('message_from_parent_long', 'Përgjigje e Zbrazët');
            } else {
                this.answer()
            }
        }
    },
    computed: {
        ...mapGetters('dashboard', ['responseInitial']), // Map the responseInitial getter from the dashboard module
        logoImg: function () {
            return this.$store.getters['settings/logoOnExamPage'] == true ? config.hostname + this.$store.getters['settings/logoPath'] : ''
        },
        lastSingleQuestion() {
            return this.$store.getters['dashboard/lastSingleQuestion'];
        },
    },
    beforeDestroy() {
        clearInterval(this.interval)
        this.$store.dispatch('dashboard/saveQuizFlag', false)
        this.$store.dispatch('dashboard/saveResultFlag', true)
        this.$store.dispatch('settings/resetRefreshCount')
    },
    created() {
        this.serialToShow = this.$store.getters['dashboard/serialToShow']
        i18n.locale = (localStorage.getItem('language') == null || localStorage.getItem('language') == '') ? 'en' : localStorage.getItem('language')
        if (this.$store.getters['dashboard/quizFlag'] == false) {
            this.$router.push({ name: 'Dashboard' })
        }
        this.confirmationStatus = this.$store.getters['dashboard/responseInitial'].confirmation.status
        this.quiz = this.$store.getters['dashboard/quiz']
        this.responseInitialId = this.$store.getters['dashboard/responseInitial'].quizResponse.quizResponseInitialId;

        this.question = this.$store.getters['dashboard/lastSingleQuestion']
        const obj = {
            responseInitialId: this.$store.getters['dashboard/responseInitial'].quizResponse.quizResponseInitialId,
            questionId: this.question.quizQuestionId
        }
        this.checkHintStatus(obj)

        this.hostUrl = config.hostname

        if (this.$store.getters['settings/endExam'] == true) {
            if (window.location.reload) {
                this.$store.dispatch('settings/changeRefreshCount')
                if (this.$store.getters['settings/refreshCount'] == 2) {
                    this.$router.push({ name: 'QuizResult' })
                }
            }
        }
    }
}
</script>

<style scoped>
.iframe-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}
</style>