<template>
    <v-container class="px-0">
        <v-row>
            <v-col class="pl-2" cols="12" sm="6" md="4">
                <v-card class="grey lighten-1">
                    <v-card-title>
                        <v-icon x-large left>mdi-cash</v-icon>       
                        <span class="black--text" x-large right>{{totalCashBalance}}</span>
                    </v-card-title>
                    <v-card-text class="font-weight-bold">{{$t('cashBalance')}}</v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="6" md="4">
                <v-card class="grey lighten-2">
                    <v-card-title>
                        <v-icon x-large left>mdi-diamond</v-icon>
                        <span class="black--text" x-large right>{{totalPointBalance}}</span>    
                    </v-card-title>
                    <v-card-text class="font-weight-bold">{{$t('pointBalance')}}</v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="6" md="4">
                <v-card class="grey lighten-3">
                    <v-card-title>
                        <v-icon x-large left>mdi-trophy-variant-outline</v-icon>       
                        <span class="black--text" x-large right>{{totalQuizzesSolved}}</span>
                    </v-card-title>
                    <v-card-text class="font-weight-bold">{{$t('Trofe')}}</v-card-text>
                </v-card>
            </v-col>
            
        </v-row>
        <v-row justify="start" >  
            <v-col cols="12" sm="8" md="6">
                <v-select
                    :label="$t('selectonetofilter')"
                    :items="filterReport"
                    item-text="text"
                    item-value="value"
                    v-on:change="filterResult"
                    prepend-icon="emoji_objects"
                    menu-props="auto"
                    clearable
                >
                </v-select>
            </v-col>        
            <v-col justify="right" cols="12" sm="8" md="6">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        
        <v-data-table
            :headers="headersResults"
            :items="itemsResults"
            :search="search"
            class="elevation-1"
        >
            <template v-slot:top>
                <v-dialog
                    v-model="dialog"
                    max-width="1000"
                >
                    <v-card>
                        <Loading/>
                        <v-card-text>
                            <v-container class="px-0"> 
                                <v-row justify="center">
                                    <v-col cols="6" sm="2" class="font-weight-black"><h3>{{$t('resultSheet')}}</h3></v-col>
                                </v-row>   
                                <v-data-table
                                    :headers="headersSingleResult"
                                    :items="itemsSingleResult"
                                    disable-pagination
                                    hide-default-footer
                                >
                                    <template v-slot:[`item.questionDetail`]="{item}">
                                        <span>{{formatQuestionDetail(item)}}</span> 
                                    </template>
                                    <template v-slot:[`item.isBuyTime`]="{item}">
                                        <span>{{formatBuyTime(item)}}</span> 
                                    </template>
                                    <template v-slot:[`item.debitBalance`]="{item}">
                                        <span>{{formatDebitBalance(item)}}</span> 
                                    </template>
                                </v-data-table>
                            </v-container>                           
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
            <template v-slot:[`item.actions`]="{item}">
                <v-icon
                    class="mr-2"
                    small
                    @click="getDetailResult(item)"
                >
                    description
                </v-icon>
                <v-icon
                    small
                    v-if="chkCertificateVisibility(item)"
                    @click="getCertificate(item)"
                >
                    card_giftcard
                </v-icon>
            </template>
            <template v-slot:[`item.isExamined`]="{item}">
                <span>{{formatStatus(item)}}</span> 
            </template>
            <template v-slot:[`item.dateAdded`]="{item}">
                <span>{{formatDateTime(item)}}</span> 
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import Loading from '../../components/common/Loading'
import config from '../../../public/config'
export default {
    name:'Students',
    components:{
      Loading
    },
    data(){
        return{
            search: '',
            dialog:false,
            userInfo:{},
            quizes:[],
            itemsResults:[],
            filteredItemsResults:[],
            totalCashBalance:'',
            totalPointBalance:'',
            totalQuizzesSolved:'',
            filterReport:[
                { value: 1, text:this.$t('january')},
                { value: 2, text:this.$t('february')},
                { value: 3, text:this.$t('march')},
                { value: 4, text:this.$t('april')},
                { value: 5, text:this.$t('may')},
                { value: 6, text:this.$t('june')},
                { value: 7, text:this.$t('july')},
                { value: 8, text:this.$t('august')},
                { value: 9, text:this.$t('september')},
                { value: 10, text:this.$t('octember')},
                { value: 11, text:this.$t('november')},
                { value: 12, text:this.$t('december')}
            ],
            headersResults:[
                {text:this.$t('name'),value:'fullName'},
                // {text:this.$t('email'),value:'email'},
                {text:this.$t('title'),value:'quizTitle'},
                {text:this.$t('Analytics'),value:'pointBalance'},
                {text:this.$t('LEK'),value:'cashBalance'},
                {text:this.$t('userQuizPosition'),value:'userQuizPosition'},
                // {text:this.$t('marksObtained'),value:'userObtainedQuizMark'},
                {text:this.$t('solved'),value:'isExamined'},               
                // {text:this.$t('attemptNumber'),value:'attemptCount'},
                {text:this.$t('attemptDateTime'),value:'dateAdded'},
                {text:this.$t('actions'), value:'actions', sortable: false },
            ],
            itemsSingleResult:[],
            headersFiltered:[
                {text:this.$t('question'),value:'questionDetail'},
                {text:this.$t('debitBalance'),value:'debitBalance'},
                {text:this.$t('buyTime'),value:'isBuyTime'},
            ],
            allowCorrectOption:null,
            name:'',
            quizTitle:'',
            cashBalance:'',
            pointBalance:'',
            userQuizPosition:'',
            isExamined:'',
            dateAdded:'',
        }
    },
    methods:{     
        initializeReport(id){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('report/fetchReport',id)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                if(response.status==200){
                    this.totalCashBalance=response.data.totalCashBalance
                    this.totalPointBalance=response.data.totalPointBalance
                    this.totalQuizzesSolved=response.data.totalQuizzesSolved
                }
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        //List of Data in Select Search Filter
        getQuizesFiltered(id){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('report/fetchQuizesFiltered',id)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.quizes=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        //Data in the table
        quizResultsFiltered(id){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('report/fetchResultsFiltered',id)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsResults=response.data.filter(item => item.userQuizPosition > 0);
                this.filteredItemsResults=this.itemsResults
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        filterResult(selectedMonth) {
            if (selectedMonth == null) {
                this.itemsResults = this.filteredItemsResults;
            } else {
                const filteredData = this.filteredItemsResults.filter(item => {
                    const itemDate = new Date(item.dateAdded);
                    return itemDate.getMonth() + 1 === selectedMonth;
                });
                this.itemsResults = filteredData;
            }
        },
        //Details
        quizResult(id){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('dashboard/fetchFinishedExamResult',id)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsSingleResult=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        getDetailResult(item){
            this.dialog=true
            this.quizResult(item.quizResponseInitialId)           
        },
        chkCertificateVisibility(item){
            if(this.userInfo.roleName=='Admin'){
                return false
            }
            else if(item.certificateTemplateId!=null){
                return true
            }else{
                return false
            }
        },
        getCertificate(item){        
            window.open(config.hostname+'/api/Quiz/ShowCertificate/'+item.certificateTemplateId+'/'+item.userId+'/'+item.quizResponseInitialId)
        },
        formatStatus(item){           
            return item.isExamined==true ? 'Po':'Jo'
        },
        formatDateTime(item){
            return item.dateAdded.replace('T',' ').substring(0,19)
        },
        formatQuestionDetail(item){
            return item.questionDetail!=''?item.questionDetail.replace(/#####/g,','):''
        },
        formatDebitBalance(item){
            return item.debitBalance==0?'n/a':item.debitBalance
        },
        formatBuyTime(item){
            return item.isBuyTime==true?'yes':'no'
        },
    },
    computed:{
        headersSingleResult:function(){
            return this.userInfo.roleName=='Admin'?this.headersFiltered:
            (this.userInfo.roleName=='Student' && this.allowCorrectOption==true?this.headersFiltered:this.headersFiltered.filter(function(item){
                return item.text!='Correct Answer'
            }))
        }
    },
    created(){
        this.userInfo=this.$store.getters['dashboard/userInfo']
        if(this.userInfo.roleName=='Student'){
            this.quizResultsFiltered(this.userInfo.userId)
            this.getQuizesFiltered(this.userInfo.userId)
            this.initializeReport(this.userInfo.userId)
        }
    }
}
</script>