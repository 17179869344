<template>
    <v-container class="px-0">
        <!-- <v-btn @click="generatePdf" small outlined>{{$t('pdf')}}</v-btn>
        <vue-excel-xlsx class="btnExcel" :data="itemsPayment" :columns="excelColumnsPayment" :filename="'payment-table'" :sheetname="'payments'">{{$t('excel')}}</vue-excel-xlsx> -->
        <v-data-table
            :headers="headersPayment"
            :items="itemsPayment"
            class="elevation-1 mt-5"
        >
        </v-data-table>
    </v-container>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
    name:'Payments',
    data(){
        return{
            headersPayment:[
                {text:this.$t('userId'),value:'userId'},
                {text:this.$t('Diamonds'),value:'title'},
                {text:this.$t('priceUsd'),value:'price'},
                {text:this.$t('paymentMode'),value:'paymentMode'},
                {text:this.$t('transactionEmail'),value:'transactionEmail'},
                {text:this.$t('transactionDetail'),value:'transactionDetail'},
                {text:this.$t('stripeSessionId'),value:'stripeSessionId'},  
                {text:this.$t('attemptDateTime'),value:'dateAdded'},             
            ],
            itemsPayment:[],
            excelColumnsPayment : [
                {label:'User Id',field:'userId'},
                {label:'Title',field:'title'},
                {label:'Price',field:'price'},
                {label:'Mode',field:'paymentMode'},
                {label:'Transaction Email',field:'transactionEmail'},
                {label:'Paypal Session Id',field:'stripeSessionId'},  
                {label:'Date',field:'dateAdded'},             
           ],
        }
    },
    methods:{
        generatePdf(){
            const doc = new jsPDF({orientation:'l'})
            doc.autoTable({
                margin: { top: 10,left:2,right:1 },
                body: this.itemsPayment,
                columns: [
                    {header:'Diamonds',dataKey:'diamonds'},
                    {header:'Price',dataKey:'price'},
                    {header:'Mode',dataKey:'paymentMode'},
                    {header:'User Email',dataKey:'userEmail'},
                    {header:'Transaction Email',dataKey:'transactionEmail'},
                    {header:'Stripe Session Id',dataKey:'stripeSessionId'},  
                    {header:'Date-Time',dataKey:'dateAdded'}, 
                ],
            })
            doc.save('Payments-table.pdf')
        },
        initializeAdmin(){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('settings/fetchPayment')
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsPayment=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
    },
    created(){
        this.initializeAdmin()
    }
}
</script>