<template>
    <v-app>
        <nav>
            <v-navigation-drawer
                v-model="drawer"
                app
                dark
                temporary
            >
                <v-list-item class="pb-2">
                    <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        {{this.siteName}}
                    </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item
                        v-for="([icon, text, link], i) in items"
                        :key="i"
                        link
                        @click="$vuetify.goTo(link)"
                    >
                        <v-list-item-icon class="justify-center">
                            <v-icon>{{ icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="subtitile-1">
                            {{text}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item v-if="!isUserLoggedIn" @click="switchSignIn">
                        <v-list-item-icon class="justify-center">
                            <v-icon>login</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="subtitile-1">
                            {{$t('signIn')}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-else>
                        <v-list-item-content>
                          <v-list-item-title class="subtitile-1">
                            My Account
                          </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list> 
            </v-navigation-drawer>
            <v-app-bar app elevation="14">  
                <v-toolbar-title><v-img :src="this.logoPath" max-height="50" max-width="70" contain /></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon v-if="isXs==true" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <div v-else>
                    <v-btn text @click="$vuetify.goTo('#home')"><span class="text-capitalize">{{$t('home')}}</span></v-btn>
                    <v-btn text @click="$vuetify.goTo('#ranking')"><span class="text-capitalize">{{$t('Ranking')}}</span></v-btn>
                    <v-btn text @click="$vuetify.goTo('#contact')"><span class="text-capitalize">{{$t('contact')}}</span></v-btn>
                </div>
                <v-spacer v-if="isXs==false"></v-spacer>
                <v-btn v-if="isXs==false" outlined @click="switchSignIn" class="text-capitalize">{{$t('signIn')}}</v-btn>                              
            </v-app-bar>
        </nav>
        <v-main>
            <Message/>
            <section id="home">            
                <!-- <v-row justify="center" class="homeMargin">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" md="6" align-self="center">
                                <h1 class="header">{{this.settingsObj.homeHeader1}}</h1>
                                <div class="bodyText">{{this.settingsObj.homeDetail1}}</div>
                                <v-btn outlined @click="$vuetify.goTo('#pricing')" class="text-capitalize">{{$t('getRegister')}}</v-btn>
                            </v-col>
                            <v-col cols="12" md="6" align-self="start">
                                <v-img max-height="450" max-width="900" :src="this.homeImg"></v-img>
                            </v-col>
                        </v-row>
                        
                    </v-col>
                </v-row> -->
                <v-row justify="center">
                    <v-col cols="10" md="7">
                        <h1 class="header text-center">{{this.settingsObj.homeHeader2}}</h1>
                    </v-col>
                </v-row>
                <!-- <v-row justify="center">
                    <v-col cols="10" md="10">
                        <p class="bodyText text-center">{{this.settingsObj.homeDetail2}}</p>
                    </v-col>
                </v-row> -->
                <v-row justify="center" class="mt-16 mb-16">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" sm="6" md="3" v-for="item in itemsQuiz" :key="item.quizTopicId">
                                <v-card shaped elevation="16" min-height="150px">
                                    <v-card-title class="grey darken-3 white--text"><v-row justify="center">{{item.quizCategoryName}}</v-row></v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-actions class="font-weight-bold">
                                        <v-row justify="center"><v-btn @click="switchSignIn" class="quizButton text-capitalize" outlined>{{$t('Zgjedh Kuizin')}}</v-btn></v-row>
                                    </v-card-actions>    
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section>
            <section id="ranking" class="mt-16 mb-16">
                <v-row justify="center">
                    <v-col cols="10" md="7">
                        <h1 class="header text-center">{{"Top 10 users in our platform"}}</h1>
                    </v-col>
                </v-row>
                <!-- <v-row justify="center">
                    <v-col cols="10" md="10">
                        <p class="bodyText text-center">{{this.settingsObj.homeDetail2}}</p>
                    </v-col>
                </v-row> -->
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" md="6" align-self="center" order="1">
                                <v-card color="grey lighten-3" shaped elevation="6">
                                    <v-card-title class="grey darken-3 white--text"><v-row justify="center">{{"Top 10 Users"}}</v-row></v-card-title>
                                    <v-row>
                                        <v-col class="text-center font-weight-bold">Email</v-col>
                                        <v-col class="text-center font-weight-bold">Trofe</v-col>
                                    </v-row>

                                    <v-list class="text-center">
                                        <v-list-item class="py-0" v-for="item in itemsUser" :key="item.quizResponseInitialId">
                                            <v-list-item-title>{{item.email}} </v-list-item-title>
                                            <v-list-item-title>{{item.solvedQuizCount}} <v-icon>mdi-trophy-variant-outline</v-icon></v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6" order="2" style="margin-top: 50px;">
                                <v-img max-width="500" max-height="500" :src="this.feature1" contain></v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section> 
           
            <!-- <section id="features" class="mt-16 mb-16">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" md="6" align-self="center" order="1">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">{{this.settingsObj.feature1Header}}</h3></v-card-title>
                                    <v-card-subtitle>
                                        {{this.settingsObj.feature1Detail}}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6" order="2">
                                <v-img max-width="500" max-height="500" :src="this.feature1" contain></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" order="2" order-md="1">
                                <v-img max-width="500" max-height="500" :src="this.feature2"></v-img>
                            </v-col>
                            <v-col cols="12" md="6" align-self="center" order="1" order-md="2">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">{{this.settingsObj.feature2Header}}</h3></v-card-title>
                                    <v-card-subtitle>
                                        {{this.settingsObj.feature2Detail}}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" align-self="center" order="1">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">{{this.settingsObj.feature3Header}}</h3></v-card-title>
                                    <v-card-subtitle>
                                        {{this.settingsObj.feature3Detail}}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6" order="2">
                                <v-img max-width="500" max-height="500" :src="this.feature3"></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" order="2" order-md="1">
                                <v-img max-width="500" max-height="500" :src="this.feature4"></v-img>
                            </v-col>
                            <v-col cols="12" md="6" align-self="center" order="1" order-md="2">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">{{this.settingsObj.feature4Header}}</h3></v-card-title>
                                    <v-card-subtitle>
                                        {{this.settingsObj.feature4Detail}}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section> -->

            <!-- <section id="register" class="mt-16 mb-16">
                <v-card class="white--text text-center" dark>
                    <v-card-text class="pt-10">{{this.settingsObj.registrationText}}</v-card-text>
                    <v-row class="pb-10" justify="center"><v-card-actions><v-btn @click="$vuetify.goTo('#pricing')" class="text-capitalize" outlined>{{$t('registerNow')}}</v-btn></v-card-actions></v-row>
                </v-card>
            </section> -->

            <section id="contact" class="mt-16 mb-16">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row>
                             <v-col cols="12" sm="5">
                                <h1>{{$t('contactUs')}}</h1>
                                <h4 class="font-weight-light mt-3">
                                    {{this.settingsObj.contactUsText}}
                                </h4>
                                <h4 class="font-weight-light mt-3">
                                    {{$t('telephone')}}: {{this.settingsObj.contactUsTelephone}}
                                </h4>
                                <h4 class="font-weight-light">
                                    {{$t('email')}}: {{this.settingsObj.contactUsEmail}}
                                </h4>
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                                    <v-text-field
                                        v-model="name"
                                        :rules="nameRules"
                                        :label="$t('name')"
                                        required
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        :label="$t('email')"
                                        required
                                    ></v-text-field>

                                    <v-textarea
                                        v-model="textArea"
                                        :rules="textAreaRules"
                                        :label="$t('message')"
                                        required
                                    />

                                    <v-btn
                                        :disabled="!valid"
                                        color="primary"
                                        :dark="valid"
                                        rounded
                                        block
                                        class="mt-3"
                                        @click="submit"
                                    >
                                    {{$t('submit')}}
                                    </v-btn>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section>
        </v-main>
        <v-footer
            dark
            padless
        >
            <v-card
            flat
            tile
            width="100%"
            class="text-center"
            >
            <v-card-text>
                <v-btn
                v-for="icon in icons"
                :key="icon.img"
                :href="icon.route"
                class="mx-4 white--text"
                icon
                >
                <v-icon size="24px">
                    {{ icon.img }}
                </v-icon>
                </v-btn>
            </v-card-text>

            <v-card-text class="pt-0">
                {{this.settingsObj.footerText}}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="white--text">
                {{ new Date().getFullYear() }} — <strong>{{this.siteName}}</strong>
            </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
import config from '../../../public/config'
import Message from '../../components/common/Message'
import i18n from '@/plugins/i18n'

export default{
    name: "Landing",
    components: { Message },
    data() {
        return {
            itemsQuiz:[],
            itemsUser:[],
            drawer: null,
            isXs: false,
            items: [
                ["mdi-home-outline", "Home", "#home"],
                ["monetization_on", "Pricing", "#pricing"],
                ["featured_play_list", "Ranking", "#ranking"],
                ["contact_support", "Contact", "#contact"],
            ],
            icons: [
                { img:'mdi-facebook',text: 'facebook', route: ''},
                { img:'mdi-twitter',text: 'youtube', route: ''},
                { img:'mdi-linkedin',text: 'linkedin', route: ''},
                { img:'mdi-instagram',text: 'instagram', route: ''},
            ],
            logoPath: "",
            homeImg: window.location.origin + "/upload/header.jpg",
            feature1: window.location.origin + "/upload/feature1.jpg",
            feature2: window.location.origin + "/upload/feature2.webp",
            feature3: window.location.origin + "/upload/feature3.webp",
            feature4: window.location.origin + "/upload/feature4.jpg",
            siteName: "",
            defaultLogoUrl: window.location.origin + "/upload/logo.png",
            valid: true,
            name: "",
            nameRules: [
                (v) => !!v || "Name can not be empty",
                (v) => (v && v.length >= 6) || "Length must be greater than or equal to 6 caracteres",
            ],
            email: "",
            emailRules: [
                (v) => !!v || "Email can not be empty",
                (v) => /.+@.+\..+/.test(v) || "Wrong Email!",
            ],
            rules:{
                required:value=>!!value||this.$t('required')
            },
            textArea: "",
            textAreaRules: [
                (v) => !!v || "Message can not be empty",
                (v) => (v && v.length >= 10) || "Length must be greater than or equal to 10 caracteres",
            ],
            lazy: false,
            settingsObj:{},
        };
    },
    methods: {
        initializeQuizCity(){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('settings/fetchQuizCityList')
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsQuiz=response.data
                this.filteredItemsQuiz=this.itemsQuiz
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        initializeTop10Users(){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('settings/fetchTop10Users')
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsUser=response.data
                this.filteredItemsUser=this.itemsUser
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        onResize() {
            this.isXs = window.innerWidth < 850;
        },
        switchSignIn() {
            this.$router.push({ name: "SignIn" });
        },
        initializeText(){
            this.$store.dispatch('settings/fetchSiteSettings')
            .then((response)=>{
                this.settingsObj=response.data
                this.icons[0].route=this.settingsObj.footerFacebook
                this.icons[1].route=this.settingsObj.footerTwitter
                this.icons[2].route=this.settingsObj.footerLinkedin
                this.icons[3].route=this.settingsObj.footerInstagram       
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        submit() {
            if (this.$refs.form.validate()) {
                const obj = {
                    name: this.name,
                    email: this.email,
                    message: this.textArea
                };
                this.$store.dispatch('settings/createContacts', obj)
                    .then(response => {
                    if (response.status == 200) {
                        // this.$root.$emit("message_from_parent", response.data.responseMsg);
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent',response.data.responseMsg);
                        }, 2000);
                    }
                    else if (response.status == 202) {
                        // this.$root.$emit("message_from_parent_long", response.data.responseMsg);
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent_long',response.data.responseMsg);
                        }, 2000);
            }
                })
                .catch(err => {
                    console.log(err);
                });
            }
        },
        updateClientUrl(){
            const obj={
                displayName:window.location.origin
            }
            this.$store.dispatch('settings/updateClientUrl',obj)
        },
        paypalGetUrl(id){
            return config.hostname+'/payment-with-paypal-setup?renew=0&billingPlanId='+id
        }
    },
    watch: {
        isXs(value) {
            if (!value) {
                if (this.drawer) {
                    this.drawer = false;
                }
            }
        },
    },
    computed:{
        isUserLoggedIn() {
            return localStorage.getItem('loggedUserId') != null;
        }        
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
    },
    created() {
        i18n.locale=(localStorage.getItem('language')==null || localStorage.getItem('language')=='')?'en':localStorage.getItem('language')
        this.$store.dispatch('dashboard/signOut')
        this.logoPath = this.$store.getters["settings/logoPath"] == "" ? this.defaultLogoUrl : config.hostname + this.$store.getters["settings/logoPath"];
        this.siteName = this.$store.getters["settings/siteTitle"] == "" ? "Assess" : this.$store.getters["settings/siteTitle"];
        this.initializeText()
        this.updateClientUrl()
        this.initializeQuizCity()
        this.initializeTop10Users()
    },
}
</script>
<style scoped>
    .header{
        font-size: 35px;
        margin-bottom: 20px;
    }
    .headerFeature{
        white-space:pre-wrap;
        word-break:break-word;
    }
    .homeMargin{
        margin-bottom: 150px;
    }
    .bodyText{
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 35px;
    }
    .cardBorder{
        border-color: black;
        border-radius: 1px;
    }
    .quizButton{
        margin-top: 20px;
    }
</style>