<template>
    <v-container class="px-0">
        <Message/>
        <!-- <v-btn @click="generatePdf" small outlined>{{$t('pdf')}}</v-btn>
        <vue-excel-xlsx class="btnExcel" :data="itemsPlan" :columns="excelColumnsPlan" :filename="'billing-table'" :sheetname="'billing'">{{$t('excel')}}</vue-excel-xlsx> -->
        <v-data-table
            :headers="headersPlan"
            :items="itemsPlan"
            class="elevation-1 mt-5"
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >        
                    <v-dialog
                        v-model="dialog"
                        max-width="800"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="primary"
                        dark
                        class="text-capitalize mb-2"
                        v-bind="attrs"
                        v-on="on"               
                        absolute
                        right
                        >
                        {{$t('newPlan')}}
                        </v-btn>
                    </template>
                    <v-card>
                        <Loading/>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-form ref="form">
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="8"
                                        >                     
                                            <v-text-field
                                                v-model="editedItem.title"
                                                :label="$t('diamond')"
                                                :rules="[rules.required]" 
                                                clearable                      
                                            ></v-text-field>   
                                        </v-col>  
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                        >                     
                                            <v-text-field
                                                v-model="editedItem.price"
                                                :label="$t('priceUsd')"
                                                type="number"
                                                clearable                      
                                            ></v-text-field>   
                                        </v-col>                                                                                                             
                                    </v-row>
                                    <v-row>
                                    </v-row>
                                </v-form>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="close"
                        >
                        {{$t('cancel')}}
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="save"
                        >
                        {{$t('save')}}
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="headline">{{$t('deleteConfirmMessage')}}</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">{{$t('cancel')}}</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{$t('ok')}}</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{item}">
                <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                >
                mdi-pencil
                </v-icon>
                <v-icon
                small
                @click="deleteItem(item)"
                >
                mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Message from '../../components/common/Message'
import Loading from '../../components/common/Loading.vue'

export default {
    name:'BillingPlan',
    components:{
      Message,
      Loading
    },
    data(){
        return{
            rules:{
                required:value=>!!value||this.$t('required'),
                minimumVal:value=>value>0
            },
            intervals:['Monthly','Yearly'],
            intervalSelect:'',
            dialog:false,
            dialogDelete:false,
            headersPlan:[
                {text:this.$t('diamond'),value:'title'},
                {text:this.$t('priceUsd'),value:'price'},
                {text:this.$t('actions'),value:'actions', sortable: false }
            ],
            itemsPlan:[],
            editedIndex:-1,
            editedItem:{
                title:'',
                price:0,
                additionalText:''       
            },
            defaultItem:{
                title:'',
                price:0,
                interval:'',
                assessmentCount:0,
                responseAllAssessmentCount:0,   
                responsePerAssessmentCount:0,
                additionalText:''
            },
            excelColumnsPlan : [
                {label:'Title',field:'title'},
                {label:'Price',field:'price'},          
           ],
        }
    },
    methods:{
        generatePdf(){
            const doc = new jsPDF()
            doc.autoTable({
                margin: { top: 10,left:2,right:1 },
                body: this.itemsPlan,
                columns: [
                    {header:'Title',dataKey:'title'},
                    {header:'Price',dataKey:'price'},
                ],
            })
            doc.save('billing-plan.pdf')
        },
        initialize(){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('settings/fetchBillingPlans')
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsPlan=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        editItem(item){
            this.editedIndex=this.itemsPlan.indexOf(item)
            this.editedItem=Object.assign({},item)
            this.dialog=true
        },
        deleteItem(item){
            this.editedItem = Object.assign({}, item)
            this.dialogDelete=true
        },
        deleteItemConfirm () {
            this.$store.dispatch('settings/deleteBillingPlans',this.editedItem.billingPlanId)
            .then(response=>{
            if(response.status==200){
                // this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
                setTimeout(() => {
                    this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg));
                }, 2000);
                this.$store.dispatch('settings/fetchBillingPlans')
                .then((response)=>{
                    this.itemsPlan=response.data                  
                })
                .catch((err)=>{
                    console.log(err)
                })
            }else if(response.status==202){
                // this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                setTimeout(() => {
                            this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg));
                        }, 2000);
            }
            })
            .catch(err=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
            this.closeDelete()
        },
        close(){
            this.dialog=false
            this.$nextTick(()=>{
                this.editedItem=Object.assign({},this.defaultItem)
                this.editedIndex=-1
            })
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save(){
            if(this.$refs.form.validate()){
                this.$store.dispatch('dashboard/applyLoading')
                const objPlan={
                    title:this.editedItem.title.trim(),
                    price:this.editedItem.price==null?0:this.editedItem.price,
                    interval:this.intervalSelect,
                    assessmentCount:this.editedItem.assessmentCount,
                    responseAllAssessmentCount:this.editedItem.responseAllAssessmentCount,
                    responsePerAssessmentCount:this.editedItem.responsePerAssessmentCount,
                    additionalText:this.editedItem.additionalText,
                    addedBy:parseInt(localStorage.getItem('loggedUserId')),
                }
                if (this.editedIndex > -1){   
                    objPlan.billingPlanId=this.editedItem.billingPlanId
                    objPlan.lastUpdatedBy=parseInt(localStorage.getItem('loggedUserId'))

                    this.$store.dispatch('settings/updateBillingPlans',objPlan)
                    .then(response=>{
                        this.$store.dispatch('dashboard/cancelLoading')
                        if(response.status==200){
                            // this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                            setTimeout(() => {
                            this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg));
                        }, 2000);
                            this.close()
                            this.initialize()
                        }else if(response.status==202){
                                // this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
                                setTimeout(() => {
                                    this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg));
                                }, 2000);
                            }
                        })
                        .catch(err=>{
                            console.log(err)
                            this.$router.push({name:'NoInternet'})
                        })
                }else{ 
                    this.$store.dispatch('settings/createBillingPlans',objPlan)
                    .then(response=>{
                        this.$store.dispatch('dashboard/cancelLoading')
                        if(response.status==200){
                            this.$root.$emit('message_from_parent','Successfully saved')
                            this.close()
                            this.initialize()
                        }else if(response.status==202){
                                // this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
                                setTimeout(() => {
                                    this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg));
                                }, 2000);
                            }
                        })
                        .catch(err=>{
                            console.log(err)
                            this.$router.push({name:'NoInternet'})
                        })         
                }
            }
        },
        
    },
    computed:{
      formTitle:function(){
        return this.editedIndex===-1?this.$t('newPlan'):this.$t('editPlan')
      }
    },
    watch:{
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created(){
        this.initialize()
    }
}
</script>