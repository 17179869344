import axios from 'axios'
import config from './../../../public/config'

const state={
    switchToRegister:false,
    isLanding:true,
    status:'',
    token:localStorage.getItem('token')||'',
    user:{},
    confirmation:{},
    menus:[],
    visible:false,
    overlay:false,
    loading:false,
    clientInfo:{},
    categories: [],
    quizes:[],
    quiz:{},
    question:{},
    randomQuestion:[],
    questionSerial:0,
    serialToShow:1,
    responseInitial:{},
    quizFlag:false,
    resultFlag:false,
    result:null,
    pendingExamine:null,
    resultInfo:{},
    appSummary:{},
    quizChartDate:[],
    useHints:{},
    hintStatus:0,
    deductionPointsForBuyTime:0,
    deductionPoints:0
};

const getters={
    switchToRegister:state=>state.switchToRegister,
    isLanding:state=>state.isLanding,
    isLoggedIn:state=>!!state.token,
    authStatus:state=>state.status,
    userInfo:state=>state.user,
    menus:state=>state.menus,
    categories: state => state.categories,
    deductionPointsForBuyTime:state=>state.deductionPointsForBuyTime,
    quizes:state=>state.quizes,
    quiz:state=>state.quiz,
    question:state=>state.question,
    lastSingleQuestion: state => state.lastSingleQuestion,
    randomQuestion:state=>state.randomQuestion,
    questionSerial:state=>state.questionSerial,
    serialToShow:state=>state.serialToShow,
    responseInitial:state=>state.responseInitial,
    quizFlag:state=>state.quizFlag,
    resultFlag:state=>state.resultFlag,
    resultInfo:state=>state.resultInfo,
    result:state=>state.result,
    pendingExamine:state=>state.pendingExamine,
    appSummary:state=>state.appSummary,
    quizChartDate:state=>state.quizChartDate,
    useHints:state=>state.useHints,
    deductionPoints:state=>state.deductionPoints,
    visible:state=>state.visible,
    overlay:state=>state.overlay,
    loading:state=>state.loading,
    clientInfo:state=>state.clientInfo
};

const actions={
    fetchSigninInfo({commit},credential){
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/User/GetLoginInfo/${credential.email}/${credential.password}`)
            .then(function(response){
            if(response.status==200){
                const token = 'Bearer '+response.data.token
                localStorage.setItem('token', token)
                localStorage.setItem('loggedUserId', response.data.obj.userId)
                axios.defaults.headers.common['Authorization'] = token
                commit('auth_success', response.data)               
            }
            resolve(response)   
            })
            .catch(function (error) {
                commit('auth_error')
                localStorage.removeItem('token')
                localStorage.removeItem('loggedUserId')
                localStorage.removeItem('logCode')
                delete axios.defaults.headers.common['Authorization']
                reject(error)
            })
         })
    },
    signOut({commit}){
        return new Promise((resolve) => {                   
            commit('signOut')
            localStorage.removeItem('token')
            localStorage.removeItem('loggedUserId') 
            localStorage.removeItem('billingPlanId')  
            localStorage.removeItem('billingPlanPrice')    
            localStorage.removeItem('verifiedNumber')
            localStorage.removeItem('isNumberVerified')   
            delete axios.defaults.headers.common['Authorization']
            resolve()
        })
    },
    resetApp({commit}){
        commit('signOut')
    },
    checkVerificationStatus({commit},credential) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/User/ChkVerificationStatus/${credential.email}/${credential.password}`)
            .then((response)=>{
                commit('setRandomQuestion',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    updateEmailVerification({commit},vref) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/User/ConfirmEmailVerification/${vref}`)
            .then((response)=>{
                commit('setRandomQuestion',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchMenu({commit},info) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Menu/GetSidebarMenu/${info.roleId}`)
            .then((response)=>{
                commit('setMenu',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    createLogHistory({commit},objLogHistory) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+'/api/User/CreateLoginHistory',objLogHistory)
            .then((response)=>{
                if(response.status==200){
                    localStorage.setItem('logCode',response.data.responseMsg)
                    commit('logHistory',response.data)
                }             
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    updateLogHistory({commit},logCode) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/User/UpdateLoginHistory/${logCode}`)
            .then((response)=>{
                commit('logHistory',response.data)
                localStorage.removeItem('logCode')
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchCategories({commit}, userId) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Quiz/GetQuizCategory/${userId}`)      //riemerto GetQuizCategories
            .then((response)=>{
                commit('setCategories',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchCategoryQuizes({commit},obj) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Quiz/QuizTopicsByCategory/${obj.quizCategoryId}/${obj.userId}`)
            .then((response)=>{
                commit('setQuizes',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchLastSingleQuestion({ commit }, quizResponseInitialId) {
        return new Promise((resolve, reject) => {
            axios.get(config.hostname + `/api/Quiz/GetLastSingleQuestion/${quizResponseInitialId}`)
                .then(response => {
                    commit('setLastSingleQuestion', response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },    
    fetchRandomQuestion({commit},obj) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Quiz/GetRandomQuestions/${obj.quizId}/${obj.randomFlag}`)
            .then((response)=>{
                commit('setRandomQuestion',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    createInitialResponse({commit},obj) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+'/api/Quiz/CreateQuizResponseInitial',obj)
            .then((response)=>{
                commit('setInitialResponse',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    useHintsResponse({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+'/api/Quiz/useHints?quizResponseInitialId='+id)
            .then((response)=>{
                commit('setUseHints',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    }, 
    getDeductionPointsResponse({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Quiz/GetDeductionPoints?quizResponseInitialId=${id}`)
            .then((response)=>{
                commit('setDeductionPoints',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    }, 
    checkHintStatusResponse({commit}, obj) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+'/api/Quiz/CheckHintStatus?quizResponseInitialId='+obj.responseInitialId+'&quizQuestionId='+obj.questionId)
            .then((response)=>{
                commit('setCheckHintStatus',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    createDetailResponse({commit},obj) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+'/api/Quiz/CreateQuizResponseDetail?quizResponseInitialId='+obj.quizResponseInitialId+'&userAnswer='+obj.userAnswer)
            .then((response)=>{
                commit('setDetailResponse',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error);
            });
        })
    },
    createBuyTimeResponse({commit},quizResponseInitialId) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+`/api/Quiz/BuyTime/buy-time/${quizResponseInitialId}`)
            .then((response)=>{
                commit('setBuyTimeResponse',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error);
            });
        })
    },
    getDeductionPointsForBuyTime({commit},quizResponseInitialId) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+`/api/Quiz/GetDeductionPointsForBuyTime/buy-time/${quizResponseInitialId}`)
            .then((response)=>{
                commit('setGetDeductionPointsForBuyTimeResponse',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error);
            });
        })
    },
    fetchFinishedExamInfo({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Report/GetFinishedExamInfo/${id}`)
            .then((response)=>{
                commit('setResultInfo',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchFinishedExamResult({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Report/GetFinishedExamResult/${id}`)
            .then((response)=>{
                commit('setResult',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchSummary({commit}) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+'/api/Dashboard/GetStatus')
            .then((response)=>{
                commit('setAppSummary',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    saveQuestionSerial({commit},serial){
        commit('setQuestionSerial',serial)
    },
    saveSerialToShow({commit},serial){
        commit('setSerialToShow',serial)
    },
    saveQuizLiveTime({commit},time){
        commit('setLiveTime',time)
    },
    startQuiz({commit},quiz){
        commit('setStartQuiz',quiz)
    },
    saveQuizFlag({commit},val){
        commit('setQuizFlag',val)
    },
    saveResultFlag({commit},val){
        commit('setResultFlag',val)
    },
    changeVisibility({commit}){
        commit('setVisibility')
    },
    switchLanding({commit},val){
        commit('setLanding',val)
    },
    switchRegister({commit},val){
        commit('setRegister',val)
    },
    applyOverlay({commit}){
        commit('setOverlay')
    },
    clearOverlay({commit}){
        commit('resetOverlay')
    },
    applyLoading({commit}){
        commit('setLoading')
    },
    cancelLoading({commit}){
        commit('resetLoading')
    },
    fetchClientInfo({commit}){
        axios.get('https://api.ipify.org?format=json')
        .then((response)=>{
            const { detect } = require('detect-browser');
            const browser = detect();
            const data={
                userIp:response.data.ip,
                browserName:browser.name,
                browserVersion:browser.version,
                platform:browser.os,
            }
            commit('setClientInfo',data)         
        })
    }
};

const mutations ={
    auth_request:(state)=>{
        state.status='loading'
    },
    auth_success:(state, data)=>{
        state.status = 'success'
        state.token = data.token
        state.user = data.obj
    },
    auth_error:(state)=>{
        state.status = 'error'
    },
    signOut:(state)=>{
        state.status = ''
        state.token = ''
        state.visible=false
        state.overlay=false
        state.loading=false
    },
    setMenu:(state,menus)=>{
        state.menus=menus
    },
    logHistory:(state,confirmation)=>{
        state.confirmation=confirmation
    },
    setCategories:(state,data)=>{
        state.categories=data
    },
    setQuizes:(state,quizes)=>{
        state.quizes=quizes
    },
    // setLiveTime:(state,time)=>{
    //     state.quizTime=time
    // },
    setStartQuiz:(state,quiz)=>{
        state.quiz=quiz
        state.visible=false
        state.quizFlag=true
    },
    setQuizFlag:(state,flag)=>{
        state.quizFlag=flag
    },
    setResultFlag:(state,flag)=>{
        state.resultFlag=flag
    },
    setLastSingleQuestion(state, lastSingleQuestion) {
        state.lastSingleQuestion = lastSingleQuestion;
    },
    setRandomQuestion:(state,randomQuestion)=>{
        state.randomQuestion=randomQuestion
    },
    setQuestionSerial:(state,serial)=>{
        state.questionSerial=serial
    },
    setSerialToShow:(state,serial)=>{
        state.serialToShow=serial
    },
    setInitialResponse:(state,responseInitial)=>{
        state.responseInitial=responseInitial
    },
    setDetailResponse:(state,confirmation)=>{
        state.confirmation=confirmation
    },
    setBuyTimeResponse:(state,confirmation)=>{
        state.buyTimeResponse=confirmation
    },
    setGetDeductionPointsForBuyTimeResponse:(state,confirmation)=>{
        state.deductionPointsForBuyTime=confirmation
    },
    setResultInfo:(state,info)=>{
        state.resultInfo=info
    },
    setResult:(state,result)=>{
        state.result=result
    },
    setAppSummary:(state,obj)=>{
        state.appSummary=obj
    },

    setUseHints:(state,obj)=>{
        state.useHints=obj
    },
    setDeductionPoints:(state, obj)=>{
        state.deductionPoints = obj
    },
    setCheckHintStatus:(state,obj)=>{
        state.hintStatus=obj
    },
  
 

    
    
    
    
    setVisibility:(state)=>{
        state.visible=true
    },
    setLanding:(state,value)=>{
        state.isLanding=value
    },
    setRegister:(state,value)=>{
        state.switchToRegister=value
    },
    setOverlay:(state)=>{
        state.visible=false
        state.overlay=true
    },
    resetOverlay:(state)=>{
        state.visible=true
        state.overlay=false
    },
    setLoading:(state)=>{
        state.loading=true
    },
    resetLoading:(state)=>{
        state.loading=false
    },
    setClientInfo:(state,data)=>{
        state.clientInfo=data
    },
};

export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}