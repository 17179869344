<template>
    <v-container class="px-0">
        <Message/>
        <v-row>
            <v-col cols="12" md="4" class="font-weight-black"><h3><v-icon @click="switchToList()">arrow_back</v-icon>{{this.quizObj.quizTitle}}</h3></v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="3">
                <v-btn @click="generatePdf" small outlined>{{$t('pdf')}}</v-btn>
                <vue-excel-xlsx class="btnExcel" :data="itemsQuestions" :columns="excelColumnsQuestions" :filename="'questions-table'" :sheetname="'questions'">{{$t('excel')}}</vue-excel-xlsx>
                <v-btn small outlined><download-csv :data="itemsQuestions" name= "questions-table.csv">{{$t('csv')}}</download-csv></v-btn>
            </v-col>
            <v-spacer></v-spacer>              
        </v-row>
        
        <v-data-table           
            :headers="headersQuestions"
            :items="itemsQuestions"                     
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >         
                    <v-dialog
                        v-model="dialog"
                        max-width="900"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-if="!quizObj.isQuizSolved && roleName=='Admin'"
                                color="primary"
                                dark
                                class="text-capitalize mb-2"
                                v-bind="attrs"
                                v-on="on"               
                                absolute
                                right
                            >
                            {{$t('newQuestion')}}
                            </v-btn>
                        </template>
                        <v-card>
                            <Loading/>
                            <v-card-title v-if="roleName=='Admin'">
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text style="word-break: keep-all;" >
                                <v-container>
                                    <v-form ref="form">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="6" 
                                                md="6"                                                                                            
                                            >
                                                <v-textarea
                                                    v-model="editedItem.questionDetail"
                                                    :label="$t('question')"
                                                    :rules="[rules.required]"
                                                    rows="1"
                                                    clearable                       
                                                ></v-textarea>
                                            </v-col>  
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                                v-if="quizObj.isLekAward == true"
                                            >
                                                <v-menu
                                                    v-model="startTime"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="editedItem.scheduleStartDate"
                                                            :label="$t('scheduleStartDate')"
                                                            prepend-icon="mdi-calendar-clock"
                                                            :rules="isLeckAward==true? [rules.required] : []"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            clearable
                                                        ></v-text-field>
                                                    </template>
                                                    <v-card>
                                                        <v-tabs v-model="tab">
                                                            <v-tab>{{ $t('Date') }}</v-tab>
                                                            <v-tab>{{ $t('Time') }}</v-tab>
                                                        </v-tabs>

                                                        <v-tabs-items v-model="tab">
                                                            <v-tab-item>
                                                                <v-date-picker
                                                                    v-model="selectedDate"
                                                                    @input="setDateTime"
                                                                    :min="currentDate"
                                                                ></v-date-picker>
                                                            </v-tab-item>
                                                            <v-tab-item>
                                                                <v-time-picker
                                                                    v-model="selectedTime"
                                                                    @input="setDateTime"
                                                                ></v-time-picker>
                                                            </v-tab-item>
                                                        </v-tabs-items>
                                                    </v-card>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="12"
                                                v-if="quizObj.isIpRequired == true"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.questionIp"
                                                    :label="$t('Question IP')"                                                                                                    
                                                    clearable                       
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>                                      
                                        <v-row>
                                            <v-col
                                                cols="12"  
                                                sm="3"                 
                                                md="3"
                                            >
                                                <v-img                       
                                                    :src="previewImage"
                                                    max-height="100"
                                                    max-width="150"
                                                    contain                      
                                                >
                                                </v-img>
                                            </v-col>
                                        </v-row>
                                        <v-row>                                           
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                                v-if="quizObj.isLekAward == true"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.hintA"
                                                    :label="$t('Hint-A')"    
                                                    :rules="isLeckAward==true? [rules.required] : []"                                       
                                                    clearable                       
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                                v-if="quizObj.isLekAward == true"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.hintB"
                                                    :label="$t('Hint-B')"     
                                                    :rules="isLeckAward==true? [rules.required] : []"                                           
                                                    clearable                       
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                    
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.correctOption"
                                                    :label="$t('Correct Answer')"
                                                    :rules="[rules.required]"                                                                                                      
                                                    clearable
                                                >                                                   
                                                </v-text-field>
                                            </v-col>
                                        
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                            >
                                                <v-textarea
                                                    v-model="editedItem.answerExplanation"
                                                    :label="$t('answerExplanation')"
                                                    rows="1"
                                                    clearable                       
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>                                   
                                    </v-form>                
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                >
                                {{$t('cancel')}}
                                </v-btn>
                                <v-btn
                                    v-if="roleName=='Admin'"
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                >
                                {{$t('save')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="headline">{{$t('deleteConfirmMessage')}}</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">{{$t('cancel')}}</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{$t('ok')}}</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{item}">
                <v-icon
                    v-if="!quizObj.isQuizSolved && roleName=='Admin'"
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    v-if="roleName=='Admin'"
                    small
                    class="mr-2"
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
                <v-icon
                    v-if="roleName=='Analyser'"
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    remove_red_eye
                </v-icon>
            </template>
            <template v-slot:[`item.scheduleStartDate`]="{item}">
                <span>{{formatStartTime(item)}}</span> 
            </template>
        </v-data-table>
    </v-container>
</template>
<script>
import Message from '../../components/common/Message'
import Loading from '../../components/common/Loading'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import axios from 'axios'
import moment from 'moment';

export default{
    name:'quizQuestions',
    components:{
      Message,
      Loading
    },
    data(){
        return{
            tab: null,
            startTime:false,
            selectedDate: null,
            selectedTime: null,  
            previewImage:null,
            imageFile:null,
            selectedFile:null,
            quizObj:{},
            dialog:false,
            dialogDelete:false,
            allOptionsVisible:true,
            options:['Hint-A','Hint-B'],
            rules:{
                required:value=>!!value||this.$t('required'),
                minimumVal:value=>value
            },
            headersQuestions:[
                {text:this.$t('questions'),value:'questionDetail'},
                {text:this.$t('scheduleStartDate'),value:'scheduleStartDate'},
                {text:this.$t('Hint-A'),value:'hintA'},
                {text:this.$t('Hint-B'),value:'hintB'},
                {text:this.$t('IP'),value:'questionIp'},
                {text:this.$t('Count'),value:'questionSolvedCount'},
                {text:this.$t('correctAnswer'),value:'correctOption'},
                {text:this.$t('actions'), value: 'actions',sortable: false},
            ],
            itemsQuestions:[],
            editedIndex:-1,
            editedItem:{
                quizQuestionId:null,
                quizTopicId:null,               
                questionDetail:'',
                hintA:'',
                hintB:'',
                correctOption:'',
                questionIp:'',
                answerExplanation:'',
                isCodeSnippet:false,            
            },
            defaultItem:{
                quizQuestionId:null,
                quizTopicId:null,
                questionDetail:'',
                hintA:'',
                hintB:'',
                correctOption:'',
                answerExplanation:'',
                questionIp:'',
                isCodeSnippet:false,        
                scheduleStartDate:null    
            },
            excelColumnsQuestions : [
                {label:'Questions',field:'questionDetail'},
                {label: 'Schedule Start Date', field:'scheduleStartDate'},
                {label:'Hint-A',field:'hintA'},
                {label:'Hint-B',field:'hintB'},
                {label:'IP',field:'questionIp'},
                {label:'Correct Answer',field:'correctOption'},  
                {label:'Answer Explanation',field:'answerExplanation'},
                {label:'Is Code Snippet',field:'isCodeSnippet'},       
           ],
        }        
    },
    methods:{
        setDateTime() {
                if (this.selectedDate && this.selectedTime) {
                const [hours, minutes] = this.selectedTime.split(':');
                
                const dateWithTime = moment(this.selectedDate)
                    .hours(parseInt(hours))
                    .minutes(parseInt(minutes));
                
                this.editedItem.scheduleStartDate = dateWithTime.format("YYYY-MM-DDTHH:mm");
                
                this.startTime = false;
            }
        },
        generatePdf(){
            const doc = new jsPDF()
            doc.autoTable({
                styles:{cellWidth:23},
                margin: { top: 10,left:2,right:1 },
                body: this.itemsQuestions,
                columns: [
                    {header:'Questions',dataKey:'questionDetail'},
                    {header:'ScheduleStartDate',datakey:'scheduleStartDate'},
                    {header:'HintA',dataKey:'hintA'},
                    {header:'HintB',dataKey:'hintB'},
                    {header:'IP',dataKey:'questionIp'},
                    {header:'Answer',dataKey:'correctOption'},  
                    {header:'Explain',dataKey:'answerExplanation'},                  
                ],
            })
            doc.save('questions-table.pdf')
        },
        downloadSampleCsv(){
            axios({
                url: window.location.origin+'/upload/sample-upload.csv',
                method: 'GET',
                responseType: 'blob'
            })
            .then((response) => {
                const url = window.URL
                    .createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'sample-upload.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
        },
        downloadInstructionsPdf(){
            axios({
                url: window.location.origin+'/upload/Instructions-to-upload-questions.pdf',
                method: 'GET',
                responseType: 'blob'
            })
            .then((response) => {
                const url = window.URL
                    .createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Instructions-to-upload-questions.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
        },
        OnCsvSelected(file){
            this.$store.dispatch('dashboard/applyLoading')
            if(file!=null){
                if(file.name.includes('.csv')){
                    let fd=new FormData()
                    fd.append('csv',file)
                    this.$store.dispatch('quiz/uploadQuestionCsv',fd)
                    .then(response=>{                
                        if(response.status==200){
                            const obj={
                                path:response.data.dbPath,
                                quizTopicId:this.quizObj.quizTopicId,
                                addedBy:parseInt(localStorage.getItem('loggedUserId'))
                            }
                            this.$store.dispatch('quiz/readQuestionCsvData',obj)
                            .then(response=>{
                                if(response.status==200){
                                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                                    this.$store.dispatch('question/fetchQuizQuestions',this.quizObj.quizTopicId)
                                    .then((response)=>{
                                        this.itemsQuestions=response.data
                                    })
                                }else if(response.status==202){
                                    this.$root.$emit('message_from_parent_long',this.$t('csvUploadInstruction'))
                                }
                            })                 
                        }
                    })
                }else{
                    this.$root.$emit('message_from_parent_long',this.$t('csvUploadError'))
                }
            }
            file=null
            this.$store.dispatch('dashboard/cancelLoading')
        },
        onFileSelected(e){           
            if(e!=null){
                this.selectedFile=e
                const reader=new FileReader()
                reader.readAsDataURL(this.selectedFile)
                reader.onload=e=>{
                    this.previewImage=e.target.result
                }
            }else{               
                this.selectedFile=null
                this.previewImage=null
                this.editedItem.imagePath=''
            }                      
        },
        switchToList(){
            if(this.$store.getters['dashboard/userInfo'].roleName=='Admin'){
                this.$router.push({name:'QuizTopics'})
            }else if(this.$store.getters['dashboard/userInfo'].roleName=='Analyser'){
                this.$router.push({name:'Quizes'})
            }
        },
        formatStartTime(item){
            return moment(item.scheduleStartDate).format('YYYY-MM-DD HH:mm');
        },
        formatRunning(item){
            if(item.isRunning){
                if(item.scheduleStartDate!=null){
                    if(new Date().toJSON().slice(0,16)>=new Date(item.scheduleStartDate).toJSON().slice(0,16)){
                        return 'On-live'
                    }else{
                        return 'Draft'
                    }
                }else{
                    return 'On-live'
                }
            }else{
                return 'Draft'
            }
        },
        getTypeOptions(){
            this.$store.dispatch('quiz/fetchTypeOptions')
            .then((response)=>{
                this.typeOptionItems=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
    
        initialize(){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('question/fetchQuizQuestions',this.quizObj.quizTopicId)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsQuestions=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
       
        
        editItem(item){
            item.scheduleStartDate=item.scheduleStartDate==null?null:moment(item.scheduleStartDate).format('YYYY-MM-DDTHH:mm')
            this.editedIndex=this.itemsQuestions.indexOf(item)           
            this.editedItem=Object.assign({},item)
            this.dialog=true
        },
        deleteItem(item){
            this.editedItem = Object.assign({}, item)
            this.dialogDelete=true
        },
        deleteItemConfirm () {       
            this.$store.dispatch('question/deleteQuestion',this.editedItem.quizQuestionId)
            .then(response=>{
                if(response.status==200){
                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                    this.$store.dispatch('question/fetchQuizQuestions',this.quizObj.quizTopicId)
                    .then((response)=>{
                        this.itemsQuestions=response.data                  
                    })
                    .catch((err)=>{
                        console.log(err)
                    })
                }else if(response.status==202){
                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                }
            })
            .catch(err=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
            this.closeDelete()
        },
        close(){
            this.dialog=false
            this.$nextTick(()=>{    
                this.imageFile=null
                this.selectedFile=null
                this.previewImage=null    
                this.editedItem=Object.assign({},this.defaultItem)
                this.editedIndex=-1
            })
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        insertOrUpdateQuestion(){
            if(this.quizObj.quizMarkOptionId!=2&&this.quizObj.allowMultipleAnswer==false){
                this.setCurrentAnswerSingle(this.editedItem.correctOption)
            }else if(this.quizObj.quizMarkOptionId!=2&&this.quizObj.allowMultipleAnswer==true){
                this.setCurrentAnswerMultiple(this.editedItem.correctOption)
            }

            const objQuestion = {
                quizTopicId: this.quizObj.quizTopicId,
                questionDetail: this.editedItem.questionDetail.trim(),
                hintA: this.editedItem.hintA.trim(),
                hintB: this.editedItem.hintB.trim(),
                correctOption: this.editedItem.correctOption.trim(),
                answerExplanation: this.editedItem.answerExplanation,
                questionIp: this.editedItem.questionIp,
                isCodeSnippet: this.editedItem.isCodeSnippet,
                addedBy: parseInt(localStorage.getItem('loggedUserId')),
                scheduleStartDate: null
            };

            if (this.quizObj.isLekAward == true) {
                objQuestion.scheduleStartDate = this.editedItem.scheduleStartDate;
            } else {
                // objQuestion.scheduleStartDate = moment(this.editedItem.scheduleStartDate).format('YYYY-MM-DDTHH:mm');
                objQuestion.scheduleStartDate = moment().format('YYYY-MM-DDTHH:mm');
            }

            if(this.quizObj.quizMarkOptionId!=2&&this.editedItem.correctOptionText==''){
                setTimeout(() => {
                    this.$root.$emit('message_from_parent_long',this.$t('emptyCorrectAnswer'));
                }, 2000);
            }else{
                this.$store.dispatch('dashboard/applyLoading')
                if (this.editedIndex > -1){
                    objQuestion.quizQuestionId=this.editedItem.quizQuestionId
                    objQuestion.lastUpdatedBy=parseInt(localStorage.getItem('loggedUserId'))
                    if(this.quizObj.quizMarkOptionId==3){
                        objQuestion.perQuestionMark=this.editedItem.perQuestionMark
                    }

                    this.$store.dispatch('question/updateQuestion',objQuestion)
                    .then(response=>{
                        this.$store.dispatch('dashboard/cancelLoading')
                        if(response.status==200){
                            this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                            this.$store.dispatch('question/fetchQuizQuestions',this.quizObj.quizTopicId)
                            .then((response)=>{
                                this.close()
                                this.itemsQuestions=response.data                  
                            })
                            .catch((err)=>{
                                console.log(err)
                            })                      
                        }else if(response.status==202){
                            this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                            }
                        })
                    .catch(err=>{
                        console.log(err)
                        this.$router.push({name:'NoInternet'})
                    })

                }else{
                    if(this.quizObj.quizMarkOptionId==3){
                        objQuestion.perQuestionMark=this.editedItem.perQuestionMark
                    }
                    this.$store.dispatch('question/createQuestion',objQuestion)
                    .then(response=>{
                        this.$store.dispatch('dashboard/cancelLoading')
                        if(response.status==200){
                            this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                            this.$store.dispatch('question/fetchQuizQuestions',this.quizObj.quizTopicId)
                            .then((response)=>{
                                this.close()
                                this.itemsQuestions=response.data                  
                            })
                            .catch((err)=>{
                                console.log(err)
                            })                      
                        }else if(response.status==202){
                            this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                            }
                        })
                    .catch(err=>{
                        console.log(err)
                        this.$router.push({name:'NoInternet'})
                    })
                }
            }
        },
        save(){
            if(this.$refs.form.validate()){
                if(this.selectedFile!=null){
                 let fd=new FormData()
                 fd.append('image',this.selectedFile)
                 this.$store.dispatch('question/uploadImage',fd)               
                    .then(response=>{
                        if(response.status==200){
                            this.editedItem.imagePath='/'+response.data.dbPath                           
                            this.insertOrUpdateQuestion()
                        }
                    })
                    .catch(err=>{
                        console.log(err)
                    })
                }else{
                    this.insertOrUpdateQuestion()
                }
            }
        }
    },
    computed:{
        currentDate() {
            const today = new Date();
            // Ensure proper formatting for the min prop
            return today.toISOString().split('T')[0];
        },
        formTitle(){
            return this.editedIndex===-1?this.$t('newQuestion'):this.$t('editQuestion')
        },
        chkAllOptionsVisible(){
            return this.allOptionsVisible
        },
        roleName(){
            return this.$store.getters['dashboard/userInfo'].roleName
        },

    },
    watch:{
        dialog (val) {         
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
    },
    created(){
        this.quizObj=this.$store.getters['question/quiz']
        //this.userInfo=this.$store.getters['dashboard/userInfo']
        this.initialize()
    }
}
</script>