<template>
    <div class="login-page">  
        <!-- login -->
        <Message/>    
        <v-card elevation="14" class="login-box" shaped style="padding-top: 55px;">
            <v-row v-if="this.logoImg!=''" justify="center" class="pt-2"> <a><v-img @click="switchRegister" :src="this.logoImg" max-height="50" max-width="150" contain></v-img></a> </v-row>
            <v-row v-else justify="center" class="pt-2"><a><v-img @click="switchRegister" :src="this.defaultLogoUrl" max-height="50" max-width="150" contain></v-img> </a> </v-row>
            <v-card-text class="text-center pt-4">
                <h2 class="black--text">{{this.title}}</h2>
                <p class="grey--text">{{this.description}}</p>         
            </v-card-text>

            <v-card-text >
                <v-form ref="form">
                    <v-text-field 
                        :label="$t('email')"  
                        v-model="emailLogin" 
                        append-icon="email"
                        :rules="[rules.required,rules.emailRules]"
                        outlined
                        dense
                        rounded
                    >
                    </v-text-field>
                    <v-text-field 
                        :label="$t('password')"  
                        v-model="passwordLogin" 
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show ? 'text' : 'password'"                            
                        :hint="$t('passwordLengthMessage')"                                     
                        @click:append="show = !show"
                        class="mb-10"
                        outlined
                        rounded
                        dense
                    >
                    </v-text-field>

                    <v-btn :loading="loading" class="text-capitalize mb-2" @click="submit" rounded block dark>{{$t('signIn')}}</v-btn>
                    <v-btn color="grey lighten-2" class="text-capitalize black--text" @click="registerStudentWithoutPaypal=true" rounded block>{{$t('register')}}</v-btn><!--switchPricing || registerStudent=true -->
                </v-form>
            </v-card-text>
            <v-card-actions class="mt-6">
                <v-row justify="center">
                    <v-btn text class="text-capitalize grey--text" @click="forget = true">{{$t('forgetPassword')}}</v-btn>
                </v-row>             
            </v-card-actions>

            <v-expand-transition>
                <v-card v-if="forget" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                    <v-row v-if="this.logoImg!=''" justify="center" class="pt-2"><a><v-img @click="switchRegister" :src="this.logoImg" max-height="50" max-width="150" contain></v-img></a></v-row>
                    <v-row v-else justify="center" class="pt-2"><a><v-img @click="switchRegister" :src="this.defaultLogoUrl" max-height="50" max-width="150" contain></v-img></a></v-row>
                    <v-card-text class="pb-0">
                        <v-form ref="formForget">
                            <v-text-field
                              v-model="emailForget"
                              :label="$t('email')"
                              :rules="[rules.required,rules.emailRules]"
                              clearable                       
                            ></v-text-field>                                                                               
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                        <v-btn @click="forget = false" text color="grey darken-4" class="text-capitalize">{{$t('close')}}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="sendPassword" text color="grey darken-4" class="text-capitalize">{{$t('sentPassword')}}</v-btn>                       
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
            <!-- registration -->
            <v-expand-transition>
                <v-card v-if="registerStudent" class="transition-fast-in-fast-out v-card--reveal">
                    <v-row v-if="this.logoImg!=''" justify="center" class="pt-2"> <a><v-img @click="switchRegister" :src="this.logoImg" max-height="50" max-width="150" contain></v-img></a></v-row>
                    <v-row v-else justify="center" class="pt-2"><a><v-img @click="switchRegister" :src="this.defaultLogoUrl" max-height="50" max-width="150" contain></v-img></a></v-row>                    
                    <v-card-text class="text-center">
                        <h2 class="black--text">{{$t('paymentCompleted')}}</h2>                              
                    </v-card-text>
                    <v-card-text>
                        <v-form ref="formRegister"  style="height: 350px;">
                            <p>{{$t('paymentCompletedDescription')}}</p>
                        </v-form>
                    </v-card-text>
                    <v-card-actions style="padding-bottom: 30px;">                      
                        <v-btn @click="registrationStudent" class="text-capitalize" block dark rounded>{{$t('continue')}}</v-btn>                       
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-expand-transition>
                <v-card v-if="registerStudentWithoutPaypal" class="transition-fast-in-fast-out v-card--reveal">
                    <v-row v-if="this.logoImg!=''" justify="center" class="pt-2"> <a><v-img @click="switchRegister" :src="this.logoImg" max-height="50" max-width="150" contain></v-img></a></v-row>
                    <v-row v-else justify="center" class="pt-2"><a><v-img @click="switchRegister" :src="this.defaultLogoUrl" max-height="50" max-width="150" contain></v-img></a></v-row>                    
                    <v-card-text class="text-center">
                        <h2 class="black--text">{{$t('candidateRegister')}}</h2>                              
                    </v-card-text>
                    <v-card-text>
                        
                        <v-form ref="formRegister">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field 
                                        :label="$t('name')" 
                                        v-model="fullNameRegister"                               
                                        :rules="[rules.required]"
                                        clearable
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field 
                                        :label="$t('lastName')" 
                                        v-model="lastNameRegister"                               
                                        :rules="[rules.required]"
                                        clearable
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        :label="$t('email')"
                                        v-model="emailRegister"                            
                                        :rules="[rules.required,rules.emailRules]"
                                        clearable                       
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field 
                                        :label="$t('password')" 
                                        v-model="passwordRegister" 
                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]"
                                        :type="show ? 'text' : 'password'"                            
                                        :hint="$t('passwordLengthMessage')"                                     
                                        @click:append="show = !show"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-menu
                                        v-model="startTime"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="dateOfBirthRegister"
                                                :label="$t('dateOfBirth')"
                                                prepend-icon="mdi-calendar"
                                                :rules="[rules.required]"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable                                                         
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateOfBirthRegister"
                                            @input="startTime = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-select
                                        :label="$t('gender')"
                                        v-model="selectedGender"
                                        :items="genderRegister"
                                        item-value="value"
                                        item-text="text"
                                        :rules="[rules.required]"
                                        clearable
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field 
                                        :label="$t('address')" 
                                        v-model="addresseRegister"                               
                                        :rules="[rules.required]"
                                        clearable
                                    >
                                    </v-text-field>   
                                </v-col>
                            </v-row>
                            <v-btn color="grey lighten-2" class="text-capitalize black--text" @click="registrationStudentWithoutPayment" rounded block>{{$t('register')}}</v-btn>
                        </v-form>
                        <v-spacer></v-spacer>
                        <v-row justify="center" style="margin-top:5px">
                            <v-btn text class="text-capitalize grey--text" @click="registerStudentWithoutPaypal=false">{{$t('signIn')}}</v-btn>                                
                        </v-row>        
                    </v-card-text>
                </v-card>
            </v-expand-transition>
        </v-card>      
    </div>               
</template>

<script>
import Message from '../../components/common/Message'
import config from '../../../public/config'
import i18n from '@/plugins/i18n'

export default {
    name:'Signin',
    components:{
        Message
    },
    data(){
        return{
            rules:{
                required:value=>!!value||this.$t('required'),
                min: v => v.length >= 8 ||this.$t('min8Character'),
                emailRules:v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('emailInvalid')
            },
            registerStudent: false,
            registerStudentWithoutPaypal:false,
            forget:false,
            emailForget:'',
            fullNameRegister:'',
            emailRegister:'',
            startTime:false,
            lastNameRegister:'',
            dateOfBirthRegister:'',
            addresseRegister:'',
            passwordRegister:'',
            emailLogin:'',
            passwordLogin:'',
            show: false,
            loading:false,
            userInfo:null,
            msg:'',
            userId:null,
            logoImg:'',
            title:'',
            description:'',
            allowPaidRegistration:false,
            registrationAmountWithText:'',
            stripeSessionId:'',
            refAdminId:null,
            defaultLogoUrl:window.location.origin+'/upload/logo-quick-test-black.png',  
            isEmailAllowed:false,
            selectedGender: '', // Initially no gender selected
            genderRegister: [
                { value: 'Male', text: 'Male' },
                { value: 'Female', text: 'Female' },
            ],
        }
    },
    methods:{     
        adminCred(){
            this.emailLogin='admin@quizplus.com'
            this.passwordLogin='abcd1234'
        },
        userCred(){
            this.emailLogin='candidate@quizplus.com'
            this.passwordLogin='abcd1234'
        },
        switchRegister(){
            this.$router.push({name:'Landing'})
        },
        switchPricing(){
            this.$store.dispatch('dashboard/switchRegister',true)
            this.$router.push({name:'Landing'})
        },
        sendPassword(){
            if(this.$refs.formForget.validate()){
                this.$store.dispatch('user/fetchUserInfo',this.emailForget)
                .then((response)=>{
                    if(response.status==200){
                        const objEmail={
                            toEmail:this.emailForget,
                            logoPath:config.hostname+(this.$store.getters['settings/logoPath']==''?this.defaultLogoUrl:this.$store.getters['settings/logoPath'].replace(/\\/g, '/')),
                            siteUrl:window.location.origin,
                            siteTitle:this.title,
                            password:response.data.password
                        }
                        this.$store.dispatch('settings/passwordEmailSent',objEmail)
                        // this.$root.$emit('message_from_parent',this.$t('pleaseCheckyouremail'))
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent',this.$t('pleaseCheckyouremail'));
                        }, 2000);
                    }else if(response.status==202){
                        // this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg));
                        }, 2000);
                    }
                })
            } 
        },
        registrationStudentWithoutPayment(){
            if(this.$refs.formRegister.validate()){
                const emailVerifyRef=this.generateRandomString(30).trim()
                this.$store.dispatch('dashboard/applyLoading')
                const objUser={          
                    fullName:this.fullNameRegister,
                    lastName:this.lastNameRegister,
                    dateOfBirth:this.dateOfBirthRegister,
                    gender:this.selectedGender,
                    address:this.addresseRegister,
                    email:this.emailRegister,
                    password:this.passwordRegister,
                    isNumberVerified:true,
                    emailVerificationRef:emailVerifyRef,
                    IsEmailVerified:false,
                    addedBy:this.refAdminId,       
                }
                this.$store.dispatch('user/createRegistration',objUser)
                .then(response=>{
                    this.$store.dispatch('dashboard/cancelLoading')
                    if(response.status==200){
                        if(this.isEmailAllowed==false){
                            this.$router.push({name:'EmailSettingsAlert'})
                        }else{
                            const objEmail={
                                toEmail:this.emailRegister,
                                name:this.fullNameRegister,
                                logoPath:config.hostname+(this.$store.getters['settings/logoPath']==''?this.defaultLogoUrl:this.$store.getters['settings/logoPath'].replace(/\\/g, '/')),
                                siteUrl:window.location.origin,
                                siteTitle:this.title,
                                refUrl:window.location.origin+'/Verify?vrfc='+emailVerifyRef
                            }
                            this.$store.dispatch('settings/verifyEmailSent',objEmail)
                            this.$router.push({name:'VerifyEmail'})
                        }                                       
                    }else if(response.status==202){
                        // this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg));
                        }, 2000);
                    }
                })
                .catch(err=>{
                    console.log(err)
                    this.$router.push({name:'NoInternet'})
                })
            }
        },

        registrationStudent(){
            this.$router.push({name:'Dashboard'})
        },
        submit(){
            if(this.$refs.form.validate()){
                this.loading=true
                const credential={
                    email:this.emailLogin,
                    password:this.passwordLogin
                }
                this.logIn(credential)
            }
        },
        logIn(credential){
            this.$store.dispatch('dashboard/checkVerificationStatus',credential)
            .then(response=>{
                if(response.status==200){
                    this.$store.dispatch('dashboard/fetchSigninInfo',credential)
                    .then(response=>{                                              
                            if(response.status==200){
                                this.userId=parseInt(localStorage.getItem('loggedUserId'))                                                     
                                const objLogHistory={
                                    userId:this.userId,
                                    ip:this.$store.getters['dashboard/clientInfo'].userIp,
                                    browser:this.$store.getters['dashboard/clientInfo'].browserName==null?'others':this.$store.getters['dashboard/clientInfo'].browserName,
                                    browserVersion:this.$store.getters['dashboard/clientInfo'].browserVersion,
                                    platform:this.$store.getters['dashboard/clientInfo'].platform                             
                                }
                                this.$store.dispatch('dashboard/createLogHistory',objLogHistory)
                                .then((response)=>{
                                    if(response.status==200){
                                        this.$router.push({name:'Dashboard'})                                                                      
                                    }
                                })                                                                          
                            }else if(response.status==204){
                                this.loading=false
                                this.msg=this.$t('incorrectEmailPassword')
                                // this.$root.$emit('message_from_parent',this.msg)
                                setTimeout(() => {
                                    this.$root.$emit('message_from_parent',this.msg);
                                }, 2000);
                            }                      
                        })
                    .catch(err => {                   
                        if(this.$store.getters['dashboard/authStatus']==='error'){
                            this.loading=false                      
                            this.msg=this.$t('errorNetworkConnection')
                            // this.$root.$emit('message_from_parent',this.msg)
                            setTimeout(() => {
                                this.$root.$emit('message_from_parent',this.msg);
                            }, 2000)
                        }
                        console.log(err)
                    })
                }else if(response.status==202){
                    this.loading=false
                    // this.$root.$emit('message_from_parent_long',response.data.responseMsg)
                    setTimeout(() => {
                        this.$root.$emit('message_from_parent',response.data.responseMsg);
                    }, 2000);
                }
            })
            .catch(err => {                   
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })  
        },
        getSettings(){
            this.$store.dispatch('settings/fetchSiteSettings')
            .then(response=>{
                if(response.status==200){
                    this.logoImg=response.data.logoPath==''?'':config.hostname+response.data.logoPath.replace(/\\/g, '/')
                    this.title=response.data.siteTitle==''?'Quiz Plus':response.data.siteTitle
                    this.description=response.data.welComeMessage==''?'Hello there,Sign in to start your task!':response.data.welComeMessage
                    const favicon = document.getElementById('favicon')
                    favicon.href = config.hostname+response.data.faviconPath.replace(/\\/g, '/')

                    if(response.data.defaultEmail=='' || response.data.password=='' || response.data.port<=0 || response.data.host==''){
                        this.isEmailAllowed=false
                    }else{
                        this.isEmailAllowed=true
                    }
                }
            })
            .catch(err=>{
                console.log(err)
            })
        },      
        paidRegistration(){
            var url = new URL(window.location.href)
            var sessionId = url.searchParams.get('session_id')
            var refId = url.searchParams.get('ref')         
            var billingPlanPrice=parseInt(localStorage.getItem('billingPlanPrice'))
            var numberVerificationStatus=Boolean(localStorage.getItem('isNumberVerified'))
            this.mobile=localStorage.getItem('verifiedNumber')
            
            if(refId && numberVerificationStatus==true){
                this.stripeSessionId=refId
                fetch(config.hostname+'/verify-paypal-payment?paymentId='+refId)
                .then(response => response.json())
                .then(data=>{
                        if(data){
                            this.registerStudent=true 
                            this.emailRegister=url.searchParams.get('emailPayee')
                            const obj={
                                billingObj:{transactionEmail:this.emailRegister,
                                stripeSessionId:this.stripeSessionId,
                                price:billingPlanPrice,
                                paymentMode:'Online',
                                transactionDetail:this.stripeSessionId,
                               },
                               userId:localStorage.getItem('loggedUserId')                               
                            }
                            this.$store.dispatch('settings/createRegistrationPayment',obj)
                        }                  
                    }
                )
                .catch(function (err) {
                    console.log('Error when fetching Checkout session', err);
                });
            }
            else if(sessionId){
                this.stripeSessionId=sessionId.replace(/{|}/g,'')
                fetch(config.hostname+'/checkout-session?sessionId='+this.stripeSessionId)
                .then(response => response.json())
                .then(data=>{
                        if(data.id==this.stripeSessionId && data.paymentStatus=='paid'){
                            this.registerStudent=true
                            this.emailRegister=data.customerDetails.email

                            const obj={
                                transactionEmail:data.customerDetails.email,
                                stripeSessionId:this.stripeSessionId,
                                price:data.amountTotal/100,
                                paymentMode:'Online',
                                transactionDetail:this.stripeSessionId,
                                userEmail:localStorage.getItem('loggedUserId')
                            }
                            this.$store.dispatch('settings/createRegistrationPayment',obj)
                        }
                    }
                )
                .catch(function (err) {
                    console.log('Error when fetching Checkout session', err);
                });
            }else if(billingPlanPrice==0 && numberVerificationStatus==true){
                this.registerStudent=true
            } 
        },
        generateRandomString(length){
            const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = ' ';
            const charactersLength = characters.length;
            for ( let i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }
    },
    computed:{
        chkPaidRegistration(){
            return this.allowPaidRegistration
        },
        stripePostUrl(){
            return config.hostname+'/create-checkout-session-registration'
        }
    },
    created(){
        i18n.locale=(localStorage.getItem('language')==null || localStorage.getItem('language')=='')?'en':localStorage.getItem('language')
        this.paidRegistration()
        if(localStorage.getItem('logCode')!=null){
            this.$store.dispatch('dashboard/updateLogHistory',localStorage.getItem('logCode'))
        }
        this.$store.dispatch('dashboard/switchRegister',false)
        this.$store.dispatch('dashboard/resetApp')
        this.$store.dispatch('dashboard/fetchClientInfo')
        this.$store.dispatch('settings/resetRefreshCount')
        this.getSettings()
    }
}
</script>

<style scoped>
    .login-page {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
    }    
    .login-box {
        width: 450px;
    }
    .v-card--reveal {
        bottom: 0;
        opacity: 1 !important;
        position: absolute;
        width: 100%;
    }
</style>