<template>
    <v-container>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card height="400" color="transparent" flat>
            <div class="display-3 mt-5">{{ this.headerText }}</div>
            <div class="grey--text lighten-5">
               {{ this.verificationText }}
            </div>
            <div class="paragraph-text mt-2">
              <br />
              <br />{{$t('errorBackMsg')}} <router-link to="/">{{$t('here')}}</router-link>.          
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>  
</template>

<script>
import config from '../../../public/config'

export default {
    name:'NotFound',
    data(){
      return{
        headerText:'Need Verification',
        verificationText:'A verification link has sent to your registered email. Please check.',
        allSettings:{},
        userInfo:{}
      }
    },
    methods:{ 
      sentWelcomeEmail(){
        if(this.$store.getters['settings/allSettings'].allowWelcomeEmail==true){
          const objEmail={
            toEmail:this.userInfo.email,
            name:this.userInfo.fullName,
            logoPath:config.hostname+(this.$store.getters['settings/logoPath']==''?this.defaultLogoUrl:this.$store.getters['settings/logoPath'].replace(/\\/g, '/')),
            siteUrl:window.location.origin,
            siteTitle:this.allSettings.siteTitle,
            password:this.userInfo.password,
          }
          this.$store.dispatch('settings/welcomeEmailSent',objEmail)
        }
      },
      linkVerification(){
        var url = new URL(window.location.href)
        var vrfc = url.searchParams.get('vrfc')
        if(vrfc!=null){
          this.$store.dispatch('dashboard/updateEmailVerification',vrfc) 
          .then(response=>{
            if(response.status==200){
              this.userInfo=response.data.obj
              this.$root.$emit('message_from_parent_long','Email Verification Done!')              
              this.sentWelcomeEmail()
              this.$router.push({name:'SignIn'})
            }else if(response.status==202){
              this.$root.$emit('message_from_parent_long',response.data.responseMsg)
            }
          })
        }
        
      }
    },
    created(){
      this.$store.dispatch('dashboard/signOut')
      this.allSettings=this.$store.getters['settings/allSettings']
      this.linkVerification()
    }
}
</script>
<style scoped>
  .paragraph-text {
    font-size: 18px;
  }
</style>