<template>
    <v-container>
        <section id="balance" v-if="roleName == 'Student'">
            <v-row justify="center" class="font-weight-bold">{{ $t('balance') }}</v-row>

            <v-row justify="center">
                <v-col cols="10">
                    <v-row>
                        <v-col cols="12" sm="6" md="3" v-for="item in itemsPlan" :key="item.billingPlanId">
                            <v-card hover shaped outlined class="cardBorder">
                                <div class="parent-container">
                                    <v-card-title class="text-center"
                                        style="display: flex; align-items: center; justify-content: center; padding-bottom: 0;">
                                        <v-list-item-title style="font-size: 30px; margin-right: 5px;"><v-icon
                                                style="font-size: 30px;"
                                                x-large>mdi-diamond</v-icon>{{ item.title }}</v-list-item-title>
                                        <v-list-item-title style="font-size: 16px;">{{ item.price }}
                                            LEK</v-list-item-title>
                                    </v-card-title>
                                </div>
                                <v-card-actions class="py-6">
                                    <v-row justify="center" v-if="item.price > 0">
                                        <v-btn @click="saveBillingPlan(item)" class="text-capitalize"
                                            outlined>{{ $t('subscribe') }}</v-btn>
                                    </v-row>
                                    <v-row justify="center" v-else><v-btn @click="saveBillingPlan(item)"
                                            class="text-capitalize" outlined>{{ $t('subscribe') }}</v-btn></v-row>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </section>
        <!-- dialog -->
        <v-dialog v-model="dialog" width="600">
            <v-card style="height: 300px; overflow-y: auto;">
                <v-card-title style="word-break: keep-all;" class="text-h5 grey lighten-2">
                    {{$t('needVerification')}}
                </v-card-title>

                <v-card-text style="padding-top: 20px; word-break: keep-all;">
                    {{$t('needVerificationDesc')}}
                    <v-container>
                        <v-row v-if="showNumber == true">
                            <v-col cols="12" sm="6" md="6">
                                <vue-phone-number-input v-model="mobile" @update="onCountryChanged" clearable
                                    outlined></vue-phone-number-input>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-btn @click="sentVerificationCode()">
                                    {{$t('sendCode')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="showCode == true">
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="verificationCodeUser" label="Code" :rules="[rules.required]"
                                    outlined clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-btn @click="verifyCode()">
                                    {{$t('verify')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<style>
    .parent-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
<script>
import config from '../../../public/config'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
    name: 'Analytics',
    components: {
        VuePhoneNumberInput,
    },
    data() {
        return {
            itemsPlan: [],
            paypalUrl: config.hostname,
            dialog: false,
            mobile: '',
            phoneNumber: '',
            verificationCodeUser: '',
            showMobileBlock: false,
            showVerificationBlock: false,
            rules: {
                required: value => !!value || this.$t('required')
            },
        }
    },
    methods: {
        onCountryChanged(countryData) {
            this.phoneNumber = countryData.formattedNumber;
        },
        initializePlan() {
            this.$store.dispatch('settings/fetchBillingPlans')
                .then((response) => {
                    this.itemsPlan = response.data

                })
                .catch((err) => {
                    console.log(err)
                    this.$router.push({ name: 'NoInternet' })
                })
        },
        saveBillingPlan(item) {
            this.mobile = this.phoneNumber;
            localStorage.setItem('billingPlanId', item.billingPlanId)
            localStorage.setItem('billingPlanPrice', item.price)
            localStorage.setItem('isNumberVerified', true);
            this.$store.dispatch('user/fetchSingleUser', parseInt(localStorage.getItem('loggedUserId')))
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    this.mobile = response.data.mobile
                    const mobileNumber = this.mobile = response.data.mobile

                    if (mobileNumber != null) {
                        this.dialog = false
                        window.open(config.hostname + '/payment-with-paypal-setup?renew=0&billingPlanId=' + parseInt(localStorage.getItem('billingPlanId')), '_self');
                    }
                    else {
                        this.dialog = true
                        this.showMobileBlock = true
                        this.showVerificationBlock = false
                    }
                })
        },
        sentVerificationCode() {
            this.mobile = this.phoneNumber;
            if (this.mobile.length < 6) {
                setTimeout(() => {
                    this.$root.$emit('message_from_parent', 'Ivalid number');
                }, 2000);
            } else {
                const objVerify = {
                    mobile: this.mobile,
                    //verificationCode:new Date().getTime().toString().substring(7,13),
                }
                //console.log(objVerify)
                this.$store.dispatch('user/createVerifyMobile', objVerify)
                    .then(response => {
                        if (response.status == 200) {
                            setTimeout(() => {
                                this.$root.$emit('message_from_parent_long', response.data.responseMsg);
                            }, 2000);
                            this.showMobileBlock = false
                            this.showVerificationBlock = true
                        } else if (response.status == 202) {
                            setTimeout(() => {
                                this.$root.$emit('message_from_parent_long', response.data.responseMsg);
                            }, 2000);
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }

        },
        verifyCode() {
            this.mobile = this.phoneNumber;

            if (this.verificationCodeUser.length != 6) {
                setTimeout(() => {
                    this.$root.$emit('message_from_parent', 'Invalid Code!');
                }, 2000);
            } else {
                this.isLoading = true;

                const objVerify = {
                    mobile: this.mobile,
                    verificationCode: this.verificationCodeUser,
                };

                Promise.all([
                    this.$store.dispatch('user/updateVerifyMobile', objVerify),
                    this.updateUserMobile()
                ])

                    .then(([verifyResponse, profileResponse]) => {
                        localStorage.setItem('verifiedNumber', this.mobile);
                        localStorage.setItem('isNumberVerified', true);

                        setTimeout(() => {
                            this.$root.$emit('message_from_parent', verifyResponse.data.responseMsg);
                        }, 2000);
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent', profileResponse.data.responseMsg);
                        }, 2000);

                        if (parseInt(localStorage.getItem('billingPlanPrice')) == 0) {
                            this.$router.push({ name: 'Analytics' });
                        } else if (parseInt(localStorage.getItem('billingPlanPrice')) > 0) {
                            window.open(config.hostname + '/payment-with-paypal-setup?renew=0&billingPlanId=' + parseInt(localStorage.getItem('billingPlanId')), '_self');
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent', 'An error occurred while processing your request.');
                        }, 2000);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        updateUserMobile() {
            this.mobile = this.phoneNumber;
            const objUserProfile = {
                userId: parseInt(localStorage.getItem('loggedUserId')),
                mobile: this.mobile,
                lastUpdatedBy: parseInt(localStorage.getItem('loggedUserId')),
            };

            return this.$store.dispatch('user/updateUserMobile', objUserProfile);
        }
    },
    computed: {
        roleName() {
            return this.userInfo.roleName
        },
        showNumber() {
            return this.showMobileBlock
        },
        showCode() {
            return this.showVerificationBlock
        }
    },
    created() {
        this.$store.dispatch('dashboard/changeVisibility')

        this.userInfo = this.$store.getters['dashboard/userInfo']
        this.initializePlan()
    },
}
</script>