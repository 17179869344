<template>
    <v-container class="px-0">
        <v-data-table
            :headers="headersBrowse"
            :items="itemsBrowse"
            :options.sync="options"
            :server-items-length="totalItemsBrowse"
            :loading="loading"
            class="elevation-1"
        >
        <template v-slot:[`item.logInTime`]="{item}">
            <span>{{formatLoginTime(item)}}</span> 
        </template>
        <template v-slot:[`item.logOutTime`]="{item}">
            <span>{{formatLogoutTime(item)}}</span> 
        </template>
        </v-data-table>
    </v-container>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
    name:'BrowseList',
    data(){
        return{
            totalItemsBrowse: 0,
            itemsBrowse: [],
            loading: true,
            options: {},
            headersBrowse:[
                {text:this.$t('name'),value:'fullName'},
                {text:this.$t('email'),value:'email'},
                {text:this.$t('logInTime'),value:'logInTime'},
                {text:this.$t('logOutTime'),value:'logOutTime'},
                {text:this.$t('ip'),value:'ip'},
                {text:this.$t('browser'),value:'browser'},
                {text:this.$t('browserVersion'),value:'browserVersion'},
                {text:this.$t('platform'),value:'platform'}
            ],
            excelColumnsBrowser : [
                {label:'Name',field:'fullName'},
                {label:'Email',field:'email'},
                {label:'LogIn Time',field:'logInTime'},
                {label:'LogOut Time',field:'logOutTime'},
                {label:'IP',field:'ip'},
                {label:'Browser',field:'browser'},
                {label:'Browser Version',field:'browserVersion'},
                {label:'OS',field:'platform'}           
           ],
        }
    },
    methods:{
        generatePdf(){
            const doc = new jsPDF()
            doc.autoTable({
                margin: { top: 10,left:2,right:1 },
                body: this.itemsBrowse,
                columns: [
                    {header:'Name',dataKey:'fullName'},
                    {header:'Email',dataKey:'email'},
                    {header:'LogIn',dataKey:'logInTime'},
                    {header:'LogOut',dataKey:'logOutTime'},
                    {header:'IP',dataKey:'ip'},
                    {header:'Browser',dataKey:'browser'},
                    {header:'Version',dataKey:'browserVersion'},
                    {header:'OS',dataKey:'platform'}
                ],
            })
            doc.save('browse-table.pdf')
        },
        initialize(){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('user/fetchBrowseList')
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsBrowse=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        formatLoginTime(item){
            return item.logInTime.replace('T',' ').substring(0,19)
        },
        formatLogoutTime(item){
            return item.logOutTime!=null?item.logOutTime.replace('T',' ').substring(0,19):''
        },
        getDataFromApi () {
            this.loading = true
            this.apiCallToFetchData().then(data => {
                this.itemsBrowse = data.items
                this.totalItemsBrowse = data.total
                this.loading = false
            })
        },
        apiCallToFetchData () {
            return new Promise((resolve) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options

                this.$store.dispatch('user/fetchBrowseList')
                .then((response)=>{                 
                    let items = response.data
                    const total = items.length

                    if (sortBy.length === 1 && sortDesc.length === 1) {
                        items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]]
                        const sortB = b[sortBy[0]]

                        if (sortDesc[0]) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                        } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                        }
                        })
                    }

                    if (itemsPerPage > 0) {
                        items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    }

                    setTimeout(() => {
                        resolve({
                            items,
                            total,
                        })
                    }, 1000)

                })
                .catch((err)=>{
                    console.log(err)
                    this.$router.push({name:'NoInternet'})
                })
            })
        },
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
      },
    },
}
</script>