<template>
    <v-container class="px-0">
        <v-row v-if="itemsQuiz.length==0 && roleName=='Student'" justify="center" class="font-weight-bold">{{$t('noAssessment')}}</v-row>
        <v-row v-else-if="itemsQuiz.length>0 && roleName=='Student'" justify="center" class="font-weight-bold">{{$t('cityQuiz')}}</v-row>
        <v-row justify="center" v-if="roleName=='Student'">
            <v-col cols="12" sm="6" md="4" v-for="item in itemsQuiz" :key="item.quizTopicId">
                <v-card color="grey lighten-3" shaped elevation="6">
                    <v-card-text class="pb-0">
                        <p class="title text--primary">{{item.quizTitle}}</p>
                    </v-card-text>
                    
                    <v-list class="pa-0">
                        <v-list-item class="py-0">
                            <v-list-item-icon>
                                <v-icon>attach_money</v-icon>
                              </v-list-item-icon>                                                           
                            <v-list-item-title class="dynamic-width">{{$t('quizPrice')}}</v-list-item-title>
                            <v-list-item-title class="spacer">{{item.isLekAward == true ? item.quizPrice : $t('0')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="py-0">
                            <v-list-item-icon>
                                <v-icon>done</v-icon>
                              </v-list-item-icon>                              
                            <v-list-item-title class="dynamic-width">{{$t('isSolved')}}</v-list-item-title>
                            <v-list-item-title class="spacer">{{item.isQuizSolved == true ? $t('Yes') : $t('No')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="py-0">
                            <v-list-item-icon><v-icon>help</v-icon></v-list-item-icon>
                            <v-list-item-title class="dynamic-width">{{ $t('totalQuestions') }}</v-list-item-title>
                            <v-list-item-title class="spacer">{{item.questionsCount}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="py-0">
                            <v-list-item-icon><v-icon>mdi-close</v-icon></v-list-item-icon>
                            <v-list-item-title class="dynamic-width">{{ $t('withoutAnswer') }}</v-list-item-title>
                            <v-list-item-title class="spacer">{{unsolvedQuestionCounts[item.quizTopicId]}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-dialog v-model="showBuyTimePopup" max-width="500">
                        <v-card>
                          <v-card-title style="word-break: keep-all;"><span>{{ messagePopup }}<span v-if="buyTimeBtnPopup"> {{ $t('buyTimeMessagePopup')}} {{deductionPointsForBuyTime}}<v-icon style="display: inline;">mdi-diamond</v-icon> {{ $t('fromYourBalance')}}</span> </span></v-card-title>
                          <v-card-actions>                         
                            <v-btn class="black--text" v-if="buyTimeBtnPopup"  @click="buyTime(item)">{{ $t('buyTime') }}</v-btn>
                            <v-btn class="black--text" v-if="subscribeBtnPopup" @click="buyCredit()" >{{ $t('btnBuyCredit') }}</v-btn>
                            <v-btn class="black--text" @click="showBuyTimePopup = false">{{ $t('close') }}</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    <v-card-actions>
                        <v-btn class="white--text" color="grey darken-2" @click="initializeInstruction(item)">{{ $t('start') }}</v-btn>
                    </v-card-actions>                   
                </v-card>
                <v-row justify="center">
                    <v-dialog
                        v-model="dialog"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                    >      
                        <v-card>
                            <v-toolbar
                                dark                       
                            >
                                <v-btn
                                    icon
                                    dark
                                    @click="dialog = false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-toolbar-title>{{$t('instructions')}}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn                          
                                    text
                                    class="text-capitalize"
                                    @click="continueQuiz(item)"
                                    >
                                    <h3>{{$t('continue')}}</h3> 
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-timeline align-top dense>
                                <v-timeline-item small dark v-for="item in itemsInstruction" :key="item.instructionId">
                                    {{item.description}}
                                </v-timeline-item>
                            </v-timeline>
                        </v-card>        
                    </v-dialog>
                </v-row>
            </v-col>
            
        </v-row>
     
    </v-container>
</template>

<script>
import config from '../../../public/config'

export default {
    name:'categoryQuizes',
    data(){
        return{       
            unsolvedQuestionCounts: {}, // Store unsolved question counts for each quiz topic    
            itemsQuiz:[],  
            itemsInstruction:[],
            userInfo:{},
            totalStudents:'',
            totalQuizes:'',
            liveQuizes:'',
            totalQuestions:'',
            unansweredQuestionCount:'',
            userId:null,
            dialog:false ,
            notifications:false,
            userIcon:'',
            assessIcon:'',
            questionIcon:'',
            showBuyTimePopup : false,
            messagePopup:'',
            buyTimeBtnPopup: false,
            subscribeBtnPopup: false,
            responseIntialId:'',   
            deductionPointsForBuyTime:0      
        }
    },
    methods:{
        initializeInstruction(item){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('settings/fetchInstructions',item.quizTopicId)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                if(response.data.length>0){
                    this.dialog=true
                    this.itemsInstruction=response.data
                }else{
                    this.startQuiz(item)
                }              
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        fetchUnsolvedQuestionsCount(userId, quizTopicId) {
            const obj = {
                userId: userId,
                quizTopicId: quizTopicId
            };
            return this.$store.dispatch('report/fetchUnsolvedQuestionsCount', obj)
                .then((response) => {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        throw new Error('Failed to fetch unsolved questions count');
                    }
                })
                .catch((err) => {
                    console.error(err);
                    throw err;
                });
        },
        loadUnsolvedQuestionCounts() {
            const userId = this.userInfo.userId; // Assuming userInfo is accessible in this scope
            this.itemsQuiz.forEach((item) => {
                this.fetchUnsolvedQuestionsCount(userId, item.quizTopicId)
                    .then((count) => {
                        // Update unsolvedQuestionCounts with the fetched count
                        this.$set(this.unsolvedQuestionCounts, item.quizTopicId, count);
                    })
                    .catch((err) => {
                        console.error(`Failed to fetch unsolved questions count for quiz topic ID ${item.quizTopicId}`, err);
                    });
            });
        },

        getLastSingleQuestion(quizResponseInitialId, item = null) {
            this.serialToShow = this.$store.getters['dashboard/serialToShow'];
            this.$store.dispatch('dashboard/applyLoading');
            this.$store.dispatch('dashboard/fetchLastSingleQuestion', quizResponseInitialId)
                .then(response => {
                this.$store.dispatch('dashboard/cancelLoading');

                if (response.data) {
                    const { status, responseMsg, perQuestionMark } = response.data;

                switch (status) {
                case 'error':
                case 'complete':
                    this.showBuyTimePopup = false;
                    // this.$root.$emit('message_from_parent', responseMsg);
                    setTimeout(() => {
                        this.$root.$emit('message_from_parent', responseMsg);
                    }, 2000);
                    break;

                    case 'scheduled':
                        this.messagePopup = responseMsg;
                        this.buyTimeBtnPopup = true;
                        this.fetchDeductionPointsForBuyTime()
                        this.showBuyTimePopup = true;
                        this.subscribeBtnPopup = false;
                        break;

                    case 'scheduledErlier':
                        this.buyTimeBtnPopup = false;
                        this.messagePopup = responseMsg;
                        this.showBuyTimePopup = true;
                        this.subscribeBtnPopup = false;
                        break;

                    default:
                        if (item !== null) {
                        this.$router.push({ name: 'StartQuiz' });
                        this.$store.dispatch('dashboard/startQuiz', item);
                        }
                        break;
                    }

                    this.question = response.data;
                    this.eachQuestionMark = perQuestionMark;
            }
            })
            .catch(err => {
            this.$router.push({ name: 'NoInternet' });
            console.error(err);
            });
        },
        buyTime(item){
            this.$store.dispatch('dashboard/applyLoading');
            this.$store.dispatch('dashboard/createBuyTimeResponse',this.responseIntialId)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                if(response.status==200){
                    if(response.data.status != "error"){
                        // this.$root.$emit('message_from_parent_long', this.$t(response.data.responseMsg))
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent_long', this.$t(response.data.responseMsg));
                        }, 2000);
                        this.getLastSingleQuestion(this.responseIntialId, item)
                    }else{
                        this.subscribeBtnPopup = true;
                        // this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent_long', this.$t(response.data.responseMsg));
                        }, 2000);
                    }
                    
                }
            }).catch((err) => {
                this.$router.push({name:'NoInternet'})
                console.log(err)
            });
        },
        fetchDeductionPointsForBuyTime(){
            this.$store.dispatch('dashboard/applyLoading');
            this.$store.dispatch('dashboard/getDeductionPointsForBuyTime',this.responseIntialId)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                if(response){
                    this.deductionPointsForBuyTime = response.data
                }
            }).catch((err) => {
                this.$router.push({name:'NoInternet'})
                console.log(err)
            });
        },
        buyCredit(){
            this.showBuyTimePopup = false
            this.$router.push({name:'Analytics'})
        },
        startQuiz(item){
            this.$store.dispatch('dashboard/applyLoading')
            const objResponseInitial={
                userId:parseInt(localStorage.getItem('loggedUserId')),
                email:this.userInfo.email,
                quizTopicId:item.quizTopicId,
                quizTitle:item.quizTitle,
                quizMark:item.quizTotalMarks,
                quizPassMarks:item.quizPassMarks,
                // quizTime:item.quizTime,
                addedBy:parseInt(localStorage.getItem('loggedUserId'))
            }
            this.$store.dispatch('dashboard/createInitialResponse',objResponseInitial)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                if(response.data.confirmation.status == 'error' && response.data.quizResponse == null){
                        this.$root.$emit('message_from_parent_long', this.$t(response.data.confirmation.responseMsg));
                }else{
                    if(response.status==200){
                    this.responseIntialId = response.data.quizResponse.quizResponseInitialId;
                    this.getLastSingleQuestion(response.data.quizResponse.quizResponseInitialId, item)
                    }else if(response.status==202){
                        // this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                        setTimeout(() => {
                            this.$root.$emit('message_from_parent_long', this.$t(response.data.responseMsg));
                        }, 2000);
                        if(response.data.status!='alert'){
                            // this.$root.$emit('message_for_renew_plan',this.$t('totalAttemptLimitExpired'))
                            setTimeout(() => {
                                this.$root.$emit('message_for_renew_plan',this.$t('totalAttemptLimitExpired'));
                            }, 2000);
                        }
                    }
                }
            }).catch((err) => {
                this.$router.push({name:'NoInternet'})
                console.log(err)
            });
        },
        continueQuiz(item){
            this.dialog=false
            this.startQuiz(item)
       },
        makeRenewal(){
            var url = new URL(window.location.href);
            var renewId = url.searchParams.get('renew_id')
            if(renewId){
                var stripeUniqueId=renewId.replace(/{|}/g,'')
                fetch(config.hostname+'/checkout-session?sessionId='+stripeUniqueId)
                .then(response => response.json())
                .then(data=>{
                        if(data.id==stripeUniqueId && data.paymentStatus=='paid'){
                            const objNewPlan={
                                addedBy:this.userInfo.userId,
                                transactionEmail:data.customerDetails.email,
                                userEmail:this.userInfo.email,
                                stripeSessionId:stripeUniqueId,
                                billingPlanId:localStorage.getItem('billingPlanId'),
                                paymentMode:'Online',
                                transactionDetail:stripeUniqueId
                            }
                            this.$store.dispatch('user/updateBillingPlan',objNewPlan)
                            .then((response)=>{
                                if(response.status==200){
                                    this.$router.push({name:'Landing'})
                                    // this.$root.$emit('message_from_parent_long',this.$t('billingPlanRenewalSuccess'))   
                                    setTimeout(() => {
                                        this.$root.$emit('message_from_parent_long',this.$t('billingPlanRenewalSuccess'));
                                    }, 2000);                  
                                }
                            })
                            .catch((err)=>{
                                console.log(err)
                            })                         
                        }
                    }
                )
                .catch(function (err) {
                    console.log('Error when fetching Checkout session', err);
                    this.$router.push({name:'NoInternet'})
                });
            }
        }
    },
    computed:{   
        roleName(){
            return this.userInfo.roleName
        },
    },
    created(){
        this.userInfo=this.$store.getters['dashboard/userInfo']
        this.itemsQuiz = this.$store.getters['dashboard/quizes'];
        this.$store.dispatch('dashboard/changeVisibility')
        this.$store.dispatch('dashboard/saveQuestionSerial',0)
        this.$store.dispatch('dashboard/saveSerialToShow',1)
        this.$store.dispatch('dashboard/saveQuizFlag',false)

        if(this.userInfo.roleName=='Student'){    
            this.makeRenewal()
        }
    },
    mounted() {
        // Call loadUnsolvedQuestionCounts when the component is mounted
        this.loadUnsolvedQuestionCounts();
    },
}
</script>
<style>
    .dynamic-width{
        min-width: 160px;
    }
    .spacer {
        margin-left: 30px; 
    }
</style>