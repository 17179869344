var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0"},[_c('v-row',{staticClass:"pt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-select',{attrs:{"label":_vm.$t('selectonetofilter'),"items":_vm.filterReport,"item-text":"text","item-value":"value","prepend-icon":"emoji_objects","menu-props":"auto","clearable":""},on:{"change":_vm.filterResult}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersResults,"items":_vm.itemsResults,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.dialogReport),callback:function ($$v) {_vm.dialogReport=$$v},expression:"dialogReport"}},[_c('v-card',[_c('v-card-text',{staticStyle:{"word-break":"keep-all"}},[_c('v-container',{staticClass:"px-0"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"font-weight-black",attrs:{"cols":"6","sm":"2"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('resultSheet')))])])],1),_c('v-data-table',{attrs:{"headers":_vm.headersSingleResult,"items":_vm.itemsSingleResult,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.questionDetail",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatQuestionDetail(item)))])]}},{key:"item.isBuyTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatBuyTime(item)))])]}},{key:"item.debitBalance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDebitBalance(item)))])]}}],null,true)})],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.getDetailResult(item)}}},[_vm._v(" description ")])]}},{key:"item.isExamined",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatStatus(item)))])]}},{key:"item.dateAdded",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }