import axios from 'axios'
import config from './../../../public/config'

const state={
    quizes:[],
    reportResults:[],
    results:[],
    certificateInfo:{},
    singleTemplate:{},
    confirmation:{},
    appReport:{},
    unsolvedQuestions:{}
};

const getters={
    quizes:state=>state.quizes,
    results:state=>state.results,
    certificateInfo:state=>state.certificateInfo,
    singleTemplate:state=>state.singleTemplate,
    confirmation:state=>state.confirmation,
    appReport:state=>state.appReport,
    unsolvedQuestions:state=>state.unsolvedQuestions,
};

const actions={
    fetchQuizes({commit}) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+'/api/Quiz/GetQuizesForReports')
            .then((response)=>{
                commit('setQuizesAdmin',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchReportResults({commit}) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+'/api/Report/GetResults')
            .then((response)=>{
                commit('setReportResults',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchQuizesFiltered({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Report/GetFilteredQuiz/${id}`)
            .then((response)=>{
                commit('setQuizes',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchReport({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Report/GetUserBalances?userId=${id}`)
            .then((response)=>{
                commit('setReport',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchResultsFiltered({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Report/GetResultsById/${id}`)
            .then((response)=>{
                commit('setResults',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchCertificateData({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Quiz/GetSingleTemplate/${id}`)
            .then((response)=>{
                commit('setSingleCertificate',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchUnsolvedQuestionsCount({commit},obj) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Report/UnsolvedQuestionsCount?userId=${obj.userId}&quizTopicId=${obj.quizTopicId}`)
            .then((response)=>{
                commit('setUnsolved',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    storeCertificateInfo({commit},info){
        commit('setCertificateInfo',info)
    },
    sentCheckedEmailToNotifyStudents({commit},obj) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+'/api/Settings/SentEmailToCheckedStudents',obj)
            .then((response)=>{
                commit('sentEmailChecked',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    saveMarksObtain({commit},obj) {
        return new Promise((resolve,reject)=>{
            axios.put(config.hostname+`/api/Report/UpdateMarksObtain/${obj.id}/${obj.marks}`)
            .then((response)=>{    
                commit('setMarks',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
};

const mutations ={
    setQuizes:(state,quizes)=>{
        state.quizes=quizes
    },
    setQuizesAdmin:(state,quizes)=>{
        state.quizes=quizes
    },
    setReportResults:(state,info)=>{
        state.reportResults=info
    },
    setResults:(state,results)=>{
        state.results=results
    },
    setCertificateInfo:(state,info)=>{
        state.certificateInfo=info
    },
    setSingleCertificate:(state,info)=>{
        state.singleTemplate=info
    },
    sentEmailChecked:(state,confirmation)=>{
        state.confirmation=confirmation
    },
    setMarks:(state,confirmation)=>{
        state.confirmation=confirmation
    },
    setReport:(state,obj)=>{
        state.appReport=obj
    },
    setUnsolved:(state,unsolvedQuestions)=>{
        state.unsolvedQuestions=unsolvedQuestions
    },
};

export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}