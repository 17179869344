import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        quizesSolved:'Solved Quizes',
        isBuyTime:'Buy Time',
        hintUsage:'Hint Usage',   
        home:'Home',
        pricing:'Pricing',
        features:'Features',
        contact:'Contact',
        signIn:'Sign In',
        getRegister:'Get Registered',
        registerNow:'Register Now',
        contactUs:'Contact-us',
        admin:'Admin',
        candidate:'Users',
        FirstName:'First Name',
        assess:'Quizzes',
        liveAssess:'Live Quizzes',
        question:'Questions',
        dashboardChart1:'Payment(Date wise)',
        dashboardChart2:'Participation(Date wise)',
        dashboardChart3:'Participation(Topic wise)',
        dashboardChart4:'Login(Date wise)',
        language:'Language',
        personalize:'Personalize',
        leaveHeader:'Want to leave?',
        leaveConfirm:'Press Signout to leave',
        stay:'Stay Here',
        signOut:'Sign Out',
        Profile:'Profile',
        Password:'Password',
        Browsing:'Browsing',
        FAQ:'FAQ',
        email:'Email',
        telephone:'Telephone',
        message:'Message',
        submit:'Submit',
        password:'Password',
        name:'Name',
        forgetPassword:'Forget Password',
        register:'Register',
        close:'Close',
        sentPassword:'Sent Password',
        candidateRegister:'Candidate Registration',
        adminRegister:'Admin Registration',
        passwordLengthMessage:'At least 8 characters',
        required:'Required',
        min8Character:'Min 8 characters',
        emailInvalid:'E-mail must be valid',
        assessments:'quizzes',
        responsesForAllAssessment:'responses for all quiz',
        responsesForEachAssessment:'responses for each quiz',
        subscribe:'Buy',
        newMenu:'New Menu',
        editMenu:'Edit Menu',
        menuTitle:'Menu Title',
        url:'URL',
        orderNo:'Order No.',
        iconMaterial:'Icon(Material-Icon)',
        actions:'Actions',
        save:'Save',
        cancel:'Cancel',
        ok:'OK',
        deleteConfirmMessage:'Are you sure you want to delete this item?',
        mobile:'Mobile',
        dateOfBirth:'Date of Birth',
        displayName:'Display Name',
        role:'Role',
        plan:'Plan',
        interval:'Interval',
        paymentMode:'Payment Mode',
        expireyDate:'Expiry Date',
        transactionDetail:'Transaction Detail',
        newUser:'New User',
        editUser:'Edit User',
        billingPlan:'Billing Plan',
        profilePicture:'Profile Picture',
        renewPlan:'Renew Plan',
        previousPlan:'Previous Plan',
        previousPaymentMode:'Previous Payment Mode',
        previousTransactionDetail:'Previous Transaction Detail',
        renew:'Renew',
        newPlan:'New Diamond',
        newMode:'New Mode',
        delete:'Delete',
        description:'Description',
        newRole:'New Role',
        editRole:'Edit Role',
        assignedMenus:'Assigned Menus',
        edit:'Edit',
        newFaq:'New Faq',
        editFaq:'Edit Faq',
        title:'Quiz',
        dateTime:'Date-Time',
        assessment:'Quiz',
        priceUsd:'Price(LEK)',
        diamond:'Diamond',
        questionPerAssessmentCount:'Questions(Per Quiz)',
        responsePerAssessmentCount:'Response(Per Quiz)',
        responseAllAssessmentCount:'Response(All Quiz)',
        startDate:'Date Start',
        endDate:'Date End',
        stripeSessionId:'Paypal Session Id',
        userEmail:'User Email',
        transactionEmail:'Transaction Email',
        editPlan:'Edit Plan',
        additionalText:'Additional Text',
        Monthly:'Monthly',
        yearly:'Yearly',
        generalSettings:'General Settings',
        examSettings:'Exam Settings',
        paymentSettings:'Payment Settings',
        emailSettings:'Email Settings',
        colorSettings:'Color Settings',
        landingPage:'Landing Page',
        emailText:'Email Text',
        siteTitle:'Site Title',
        welcomeMessage:'Welcome Message',
        copyrightText:'Copyright Text',
        allowWelcomeEmail:'Allow Welcome Email',
        allowFAQ:'Allow FAQ',
        siteLogo:'Site Logo',
        appVersion:'App Version',
        siteFavicon:'Site Favicon',
        browserrefreshendtheexam:'Browser refresh end the exam',
        sitelogoonexampage:'Site logo on exam page',
        stripe:'Stripe',
        currency:'Currency',
        debitBalance:"Debit Balance",
        currencySymbol:'Currency Symbol',
        stripeSecretkey:'Stripe Secret key',
        port:'Email Port',
        pointBalance: 'Remaining Diamonds',
        cashBalance:'Earned LEK',
        userQuizPosition:'User Quiz Position',
        hostSmtp:'Host(SMTP)',
        appBar:'App Bar',
        footer:'Footer',
        body:'Body',
        homeHeaderTop:'Home Header Top',
        homeDetailTop:'Home Detail Top',
        homeHeaderBottom:'Home Header Bottom',
        homeDetailBottom:'Home Detail Bottom',
        homeBox1Header:'Home Box1 Header',
        homeBox1Detail:'Home Box1 Detail',
        homeBox2Header:'Home Box2 Header',
        homeBox2Detail:'Home Box2 Detail',
        homeBox3Header:'Home Box3 Header',
        homeBox3Detail:'Home Box3 Detail',
        homeBox4Header:'Home Box4 Header',
        homeBox4Detail:'Home Box4 Detail',
        feature1Header:'Feature1 Header',
        feature1Detail:'Feature1 Detail',
        feature2Header:'Feature2 Header',
        feature2Detail:'Feature2 Detail',
        feature3Header:'Feature3 Header',
        feature3Detail:'Feature3 Detail',
        feature4Header:'Feature4 Header',
        feature4Detail:'Feature4 Detail',
        registrationText:'Registration Text',
        conactusText:'Conact-us Text',
        conactusTelephone:'Conact-us Telephone',
        conactusEmail:'Conact-us Email',
        landingFooterText:'Landing Footer Text',
        footerFacebook:'Footer Facebook',
        footerTwitter:'Footer Twitter',
        footerLinkedIn:'Footer LinkedIn',
        footerInstagram:'Footer Instagram',
        subject:'Subject',
        withoutAnswer:'Unsolved Question(s)',
        header:'Header',
        forgetPasswordEmail:'Forget Password Email',
        welcomeEmail:'Welcome Email',
        invitationEmail:'Invitation Email',
        reportEmail:'Report Email',
        newPassword:'New Password',
        confirmNewPassword:'Confirm New Password',
        change:'Change',
        logInTime:'LogIn Time',
        logOutTime:'LogOut Time',
        ip:'IP',
        browser:'Browser',
        browserVersion:'Browser Version',
        platform:'Platform',
        id:'ID',
        quizCategoryName:'City ',
        newCategory:'New City',
        editCategory:'Edit City',
        internetDisruption:'Internet Disruption!',
        noInternetMsg:"It's looks like your internet connectivity is not stable or you are in offline.You can stay here, we will let you know when you back in online.",
        entryRestricted:'Entry Restricted',
        forbiddenMsg:'You have no access here.You are trying something which access has limited for you.',
        errorBackMsg:'Try going back to sign in page by clicking',
        here:'here',
        pagenotfound:'Page not found',
        notFoundMsg:'The page you are trying to get to never existed in this reality,or has migrated to a parallel universe.',
        tokenExpired:'Token Expired',
        unauthorizedMsg:'Your current token has expired! you need to sign in again.',
        numberofQuestions:'Number of Questions',
        timeMin:'Time(min)',    
        marks:'Marks',
        multipleInput:'Multiple Input',
        multipleAnswer:'Multiple Answer',
        ipRequired:'IP Required',
        multitleAttempt:'Multitle Attempt',
        scheduleStartDate:'Schedule Start Date',
        scheduleEndDate:'Schedule End Date',
        status:'Status',
        goLive:'Go Live',
        stopLive:'Stop Live',
        newAssessment:'New Quiz',
        editAssessment:'Edit Quiz',
        markOption:'Mark Option',
        isIpRequired:'Is IP Required',
        buyTime: "Spend",
        allowMultipleAnswer:'Take multiple answer for single question by user',
        isLekAward:'Is Lek Award',
        allowMultipleAttempt:'Allow Unlimited attempt for a single user',
        allowMultipleInput:'Multiple option set for single question',
        reqTimeInMin:'Required Time(In minutes)',
        passMarks:'Pass Marks',
        certificateTemplate:'Certificate Template',
        displayStopOption:'Display Stop Option',
        displaySkipOption:'Display Buy Time Option',
        questionSuffle:'Question Suffle',
        correctAnswerDisplay:'Correct answer display to candidates',
        participantOption:'Participation Option',
        sentInvitationtoEmail:'Sent Invitation to Email',
        participantsEmail:'Participants Email(Comma seperated)',
        sentLive:'Are you sure you want to send this Quiz live?',
        stopFromLive:'Are you sure you want to cancel this quiz?',
        addQuestions:'Add Questions',
        addInstructions:'Add Instructions',
        newInstruction:'New Instruction',
        editInstruction:'Edit Instruction',
        quizPrice: 'Quiz Price',
        instruction:'Instruction',
        newQuestion:'New Question',
        editQuestion:'Edit Question',
        sampleCSV:'Sample CSV',
        uploadInstructions:'Upload Instructions',
        questions:'Questions',
        category:'City',
        correctAnswer:'Correct Answer',
        codeSnippet:'Code Snippet',
        questionType:'Question Type',
        complexityLavel:'Complexity Lavel',
        quizCategory:'Quiz City',
        questionYoutubeVideo:'Question Video(youtube embed video)',
        questionImage:'Question Image',
        answerExplanation:'Note',
        selectcategorytofilter:'Select city to filter',
        time:'Time',
        minutes:' minutes',
        view:'View',
        newTemplate:'New Template',
        editTemplate:'Edit Template',
        certificateTitle:'Certificate Title',
        certificateTopHeader:'Certificate Top Header',
        certificateMainText:'Certificate Main Text',
        topLeftLogo:'Top Left Logo',
        topRightLogo:'Top Right Logo',
        bottomLogo:'Bottom Logo',
        backGround:'BackGround',
        signatureLeft:'Signature Left',
        signatureLeftText:'Signature Left Text',
        signatureRight:'Signature Right',
        signatureRightText:'Signature Right Text',
        selectonetofilter:'Select one to filter',
        sentEmail:'Send Email',
        sentemailtocheckedstudents:'Send email to checked students',
        search:'Search',
        timeTakenMin:'Time taken(min)',
        marksObtained:'Marks Obtained',
        attemptNumber:'Attempt Number',
        attemptDateTime:'Date',
        questionMark:'Question Mark',
        answerInput:'Answer Input',
        skipped:'Skipped?',
        yourMarks:'Your Marks',
        pendingExamine:'Pending Examine',
        resultSheet:'Result Sheet',
        certificate:'Certificate',
        amountPaidUSD:'Amount Paid(USD)',
        noAssessment:'No Quiz Available!',
        availableAssessment:'Available Quiz',
        instructions:'Instructions',
        continue:'Continue',
        Dashboard:'Dashboard',
        Menus:'Menus',
        Roles:'Roles',
        Users:'Users',
        user:'User',
        Trofe:'Total Trophies',
        Category:'Cities',
        Assessments:'Quizzes',
        Questions:'Questions',
        Reports:'Reports',
        CertificateTemplate:'Templates',
        ExamineAndReports:'Reports',
        Analytics:'Diamonds',
        Contacts:'Contacts',
        Payments:'Payments',
        Plans:'Diamonds',
        AppSettings:'Settings',
        nameEmptyRuleLanding:'Name can not be empty',
        nameCharacterRuleLanding:'Length must be greater than or equal to 6 caracteres',
        emailEmptyRuleLanding:'Email can not be empty',
        emailWrongRuleLanding:'Wrong Email!',
        messageEmptyRuleLanding:'Message can not be empty',
        messageCharacterRuleLanding:'Length must be greater than or equal to 10 caracteres',
        successfullySubmitted:'Successfully Submitted',
        planExpiredMsg:'Your payment plan expired! Click renew to get that again.',
        billingPlanRenewed:'Billing plan renewed',
        planRenewalSuccess:'Billing plan renewal is successful ! Sign in again.',
        newContentsAvailable:'New contents available! Click refresh to affect changes.',
        mainText:'Main Text',
        menuDeleteRestriction:'This Menu is restricted to delete.',
        successfullyDeleted:'Successfully Deleted',
        successfullySaved:'Successfully Saved',
        duplicateMenuTitle:'Duplicate Menu Title!',
        duplicateURL:'Duplicate URL!',
        duplicateOrderNo:'Duplicate Order No.!',
        orderNomustgreaterthan0:'Order No. must greater than 0!',
        somethingUnexpected:'Something Unexpected!',
        successfullyUpdated:'Successfully Updated',
        successfullyAssigned:'Successfully Assigned',
        successfullyUnAssigned:'Successfully un-assigned',
        limitExpired:'You exceed your Billing plan limit.Not allwed to create a new one!',
        dateExpired:'You exceed your Billing plan Date.Not allwed to create a new one!',
        duplicateAssessmentTitle:'Duplicate Quiz Title!',
        backOnline:'Back Online!',
        backOnlineMsg:'Connection is back and you can start browsing again.',
        backgroundColor:'BackgroundColor',
        pleasecheckyourEmail:'Please check your Email',
        emailsentSuccessful:'Email sent Successful',
        notanimage:'Not an image',
        duplicateFaq:'Duplicate FAQ!',
        duplicateInstruction:'Duplicate Instruction!',
        successfullyRenewed:'Successfully renewed',
        notallowedtodeletethisondemoversion:'this operation not allowed on demo version!',
        thisplanhasexistingusersNotallowedtodeletethis:'This plan has existing users. Not allowed to delete this.',
        duplicateTitle:'Duplicate Title!',
        duplicatePrice:'Duplicate Price!',
        thereisnouserforthisemail:'There is no user for this email',
        pleasepayfirstthisisaninvalidsession:'Please pay first. This is an invalid session!',
        thisemailalreadyhaveauser:'This Email already have a user!',
        thisemailnotallowedtoregister:'This email not allowed to register. Please check!',
        rolerestricted:'This Role is restricted to delete.',
        duplicateRolename:'Duplicate Role name!',
        surveyReset:'As you Switched to Survey, Mark & Required time values are reset to initial state.',
        marksBasedReset:'As you switched to mark based Quiz,you need to set correct answer for all questions of this Assessment.',
        questionWiseReset:'As you switched to Question wise set Mark Option,you need to check correct answer & mark for all questions of this Assessment.',
        equalDistributionReset:'As you switched to Equal distribution Mark Option,you have to live this Quiz again.',
        marksChangedReset:'As you changed the Marks,you have to live this Quiz again.',
        liveInterruptionForNoQuestion:'Not possible to live this Quiz.Please add some questions!',
        liveInterruptionForNoCorrectAnswer:'Not possible to live this Quiz.Questions have no correct answer.Please set them first!',
        liveInterruptionForNoMarksSet:'Not possible to live this Quiz.Questions marks not set yet.Please set them first!',
        thissssessmentislivenow:'This Quiz is live now!',
        thisAssessmentisstoppedfromlive:'This Quiz is stopped from live!',
        deleteAssessmentReset:'As you deleted a question from a live Quiz, you have to start this Quiz again from Quizzes List.',
        maxQuestionQuota:'You already reached maximun quota of question limit ! Not allwed to create a new one!',
        duplicateQuestion:'Duplicate Question!',
        newQuestionAddAssessmentStart:'As you added a new question to a live Quiz, you have to start this Quiz again from Quizzes list',
        questionMarksChangedAssessmentStart:'This item is stopped from live as you changed marks of a running Quizzes. you have to start this Quiz again from Quizzes list',
        bulkQuestionAdd:'As you added bulk questions, you have to start this Quiz again from Quizzes list if it was live',
        categoryUsageOthers:'This category is using with question. Not possible to delete.',
        categoryExists:'This Category already exists!',
        pdf:'PDF',
        excel:'EXCEL',
        csv:'CSV',
        typePassword:'Type password',
        wrongPassword:'Wrong password!',
        passwordNotMatched:'New and Confirm password not matched!',
        passwordChangedNotAllowedDemo:'Password change not allowed for this user on demo version.',
        onlinePaymentnotallowed:'Online Payment not allowed from here!',
        pleaseCheckyouremail:'Please Check your email',
        incorrectEmailPassword:'Incorrect Email/Password!',
        errorNetworkConnection:'Error established in network connection!',
        thisFieldMaynotbeEmpty:'This field may not be empty',
        theValueMustbeanIntegerNumber:'The value must be an integer number',
        theValueMustBeGreaterThanZero:'The value must be greater than zero',
        emailSettingsNotDone:'Email settings not done yet! Please ask your admin to do so.',
        noCheckedStudent:'No checked candidates found! First check candidates from list then sent email to them',
        emailSentSuccessfully:'Email sent successfully',
        markCanNotBeLessZero:'Marks Obtain can not be empty and less then 0!',
        marksObtaincannotgreaterthanQuestionMarks:'Mark Obtain can not greater than Question Mark!',
        containsDescriptiveQuestions:'This quiz contains descriptive question. You will find this result on Reports after reviewing by Admin.',
        student:'Student',
        yourAnswer:'Your Answer',
        questionMarks:'Question Marks',
        thisAssessmentWilllive:'This quiz will live based on schedule.',
        paymentSettingsNotDone:'Your payment settings not done yet! Please set those then impose price here.',
        noScheduleStartDate:'No Schedule start date found! please put one.',
        scheduleStartDateShouldGreater:'Schedule end date should be greater than or equal to Schedule start date!',
        noCommaSeperatedEmail:'No comma seperated emails found! Add some comma seperated emails to Participants Email',
        passMarksshouldnotgreaterthanAssessmentMarks:'Pass Mark should not greater than Quiz Mark!',
        writeYourAnswerHere:'Write your answer here',   
        submitAnswer:'Submit Answer',     
        emptyAnswer:'Empty answer!',
        multipleAnswernotAllowed:'Multiple answer not allowed!',
        csvUploadInstruction:'Not uploaded! Please check your CSV carefully also follow upload instructions.',
        csvUploadError:'This is not an CSV file ! Only CSV file upload is allowed',
        emptyCorrectAnswer:'Correct answer empty!please check.',
        onlyYouTubeVideo:'Only youtube embed video is allowed!please check the Question video.',
        billingPlanRenewalSuccess:'Billing plan renewal is successful ! Sign in again.',
        csvUpload:'CSV Upload',
        skip:'Skip',
        start:'Start',
        startQuiz: 'Quizzes',
        refresh:'Refresh',
        pay:'Pay',
        billingCycleExpired:'Your Billing cycle expired!',
        singleAttemptLimitExpired:'Your attempt limit for this quiz exceeded!',
        totalAttemptLimitExpired:'Limit exceeded! Your quiz limit exceeded.',
        allocationLimitExpired:'Limit exceeded! Your allocation for all quiz response exceeded.',
        isSolved: 'Is solved',
        totalQuestions:'Total questions',
        totalCashBalance:'Cach',
        totalPointBalance:'Point',
        totalQuizzesSolved:'Quizzes Solved',
        userName:'Username',
        userId:'User Id',
        quizCard: 'Quizzes',
        lastName:'Last Name',
        address:'Address',
        gender:'Gender',
        balance:'Balance',
        solved:'Solved',
        paymentCompleted:'Payment completed',
        paymentCompletedDescription:' Your payment with PayPal has been successfully completed. Thank you for your transaction. Your purchase has been confirmed. If you have any further questions or concerns regarding your payment, please don`t hesitate to contact our customer support team.',
        cityQuizes:'Cities',
        cityQuiz: 'City Quizzes',
        btnBuyCredit: 'Buy credit',
        noCategoriesAvailable:'You have solved all available quizzes.'
        ,january:'January',
        february:'February',
        march:'March',
        april:'April',
        may:'May',
        june:'June',
        july:'July',
        august:'August',
        septemebr:'September',
        octember:'Octember',
        november:'November',
        december:'December',
        accept:'Accept',
        buyTimeMessagePopup: 'To reveal the question 12 hours before the scheduled time, you have to spend',
        fromYourBalance: 'from your balance.',
        hintMessagePopup:'Getting a clarification on the question will cost',
        needVerification:'Need Verification',
        needVerificationDesc:'Before proceed to payment we need to verify your number.Please follow the step shown below.',
        sendCode:'Send Code',
        verify:'Verify'

    },
    'al': {
        january:'Janar',
        february:'Shkurt',
        march:'Mars',
        april:'Prill',
        may:'Maj',
        june:'Qershor',
        july:'Korrik',
        august:'Gusht',
        septemebr:'Shtator',
        octember:'Tetor',
        november:'Nëntor',
        december:'Dhjetor',
        solved:'Zgjidhur',
        quizesSolved:'Kuizet e zgjidhura',
        isBuyTime:'Blej kohë?',
        hintUsage:'Ndihma e përdorur',
        withoutAnswer:'Pyetje jo zgjidhur',
        gender:'Gjinia',
        address:'Adresa',
        lastName:'Mbiemri',
        quizCard: 'Kuizet',
        startQuiz:'Kuizet',
        start:'Fillo',
        home:'Ballina',
        pricing:'Çmimet',
        features:'Karakteristikat',
        contact:'Kontakt',
        signIn:'Kyçu',
        getRegister:'Regjistrohu',
        registerNow:'Regjistrohu Tani',
        contactUs:'Na kontaktoni',
        admin:'Admin',
        FirstName:'Emri',
        candidate:'Kandidatët',
        assess:'Kuize',
        liveAssess:'Kuize Live',
        question:'Pyetje',
        dashboardChart1:'Pagesa (sipas datës)',
        dashboardChart2:'Pjesëmarrja (sipas datës)',
        dashboardChart3:'Pjesëmarrja (sipas temës)',
        dashboardChart4:'Kyçja (sipas datës)',
        language:'Gjuha',
        personalize:'Personalizo',
        leaveHeader:'Dëshironi të largoheni?',
        leaveConfirm:'Shtypni Dil per tu larguar.',
        stay:'Rri këtu',
        signOut:'Dil',
        submitAnswer:'Dërgo përgjigjen',  
        writeYourAnswerHere:'Shkruani përgjigjen tuaj këtu', 
        Profile:'Profili',
        Password:'Fjalëkalimi',
        Browsing:'Shfletimi',
        FAQ:'Pyetjet e Bëra Më Së Shpeshti',
        email:'Emaili',
        telephone:'Telefoni',
        message:'Mesazhi',
        submit:'Dërgo',
        password:'Fjalëkalimi',
        name:'Emri',
        forgetPassword:'Harrova Fjalëkalimin',
        register:'Regjistrohu',
        close:'Mbyll',
        sentPassword:'Fjalëkalimi u dërgua',
        candidateRegister:'Regjistrimi i Kandidatit',
        adminRegister:'Regjistrimi i Adminit',
        passwordLengthMessage:'Të paktën 8 karaktere',
        required:'E nevojshme',
        min8Character:'Min 8 karaktere',
        emailInvalid:'Emaili duhet të jetë i valid',
        assessments:'kuize',
        responsesForAllAssessment:'përgjigje për të gjitha kuizet',
        responsesForEachAssessment:'përgjigje për secilin test',
        subscribe:'Blej',
        newMenu:'Menyja e Re',
        editMenu:'Ndrysho Menynë',
        menuTitle:'Titulli i Menysë',
        url:'URL',
        orderNo:'Numri i Porosisë',
        iconMaterial:'Ikona (Material-Icon)',
        actions:'Veprimet',
        save:'Ruaj',
        cancel:'Anulo',
        ok:'OK',
        deleteConfirmMessage:'Jeni të sigurt që dëshironi të fshini këtë gjë?',
        mobile:'Telefoni celular',
        dateOfBirth:'Data e lindjes',
        displayName:'Emri i shfaqur',
        role:'Roli',
        plan:'Plan',
        interval:'Intervali',
        paymentMode:'Mënyra e Pagesës',
        expireyDate:'Data e Skadimit',
        transactionDetail:'Detajet e Transaksionit',
        newUser:'Përdorues i Ri',
        editUser:'Ndrysho Përdoruesin',
        billingPlan:'Plani i Faturimit',
        profilePicture:'Foto e Profilit',
        renewPlan:'Rinovo Planin',
        previousPlan:'Plan i Mëparshëm',
        previousPaymentMode:'Mënyra e Pagesës e Mëparshme',
        previousTransactionDetail:'Detajet e Transaksionit të Mëparshëm',
        renew:'Rinovo',
        newPlan:'Plan i Ri',
        newMode:'Mënyrë e Re',
        delete:'Fshij',
        description:'Përshkrimi',
        newRole:'Roli i Ri',
        editRole:'Ndrysho Rolin',
        assignedMenus:'Menytë e Caktuara',
        edit:'Ndrysho',
        newFaq:'Pyetje e Re',
        editFaq:'Ndrysho Pyetjen',
        title:'Kuizi',
        dateTime:'Data',
        assessment:'Kuizi',
        priceUsd:'Çmimi (USD)',
        questionPerAssessmentCount:'Pyetje (Për Kuiz)',
        responsePerAssessmentCount:'Përgjigje (Për Kuiz)',
        responseAllAssessmentCount:'Përgjigje (Për të Gjitha Kuizet)',
        startDate:'Data e Fillimit',
        endDate:'Data e Mbarimit',
        stripeSessionId:'ID-ja e sesionit të Paypal',
        userEmail:'Emaili i Përdoruesit',
        transactionEmail:'Emaili i Transaksionit',
        editPlan:'Ndrysho Planin',
        additionalText:'Teksti Shtesë',
        Monthly:'Mujore',
        yearly:'Vjetore',
        generalSettings:'Cilësimet e Përgjithshme',
        examSettings:'Cilësimet e Kuizit',
        paymentSettings:'Cilësimet e Pagesës',
        emailSettings:'Cilësimet e Emailit',
        colorSettings:'Cilësimet e Ngjyrave',
        landingPage:'Faqja e Nisjes',
        emailText:'Teksti i Emailit',
        siteTitle:'Titulli i Faqes',
        welcomeMessage:'Mesazhi i Mirëseardhjes',
        copyrightText:'Teksti i Drejtave',
        allowWelcomeEmail:'Lejo Emailin e Mirëseardhjes',
        allowFAQ:'Lejo FAQ',
        siteLogo:'Logoja e Faqes',
        appVersion:'Versioni i Aplikacionit',
        siteFavicon:'Favicon i Faqes',
        browserrefreshendtheexam:'Rifresko shfletuesin për të mbyllur testimin',
        sitelogoonexampage:'Logoja e faqes në faqen e testimin',
        stripe:'Stripe',
        currency:'Monedha',
        debitBalance:"Bilanci i Debitit",
        currencySymbol:'Simboli i Monedhës',
        stripeSecretkey:'Çelësi Sekret i Stripe',
        port:'Porti e Emailit',
        pointBalance: 'Diamant',
        cashBalance:'LEK',
        quizSolved:'Trofe',
        userQuizPosition:'Rankimi',
        hostSmtp:'Pritësi (SMTP)',
        appBar:'Shiriti i Aplikacionit',
        footer:'Këmba',
        body:'Trupi',
        homeHeaderTop:'Krye Headeri Kryesor',
        homeDetailTop:'Detajet Kryesore të Kreut',
        homeHeaderBottom:'Poshtë Headeri Kryesor',
        homeDetailBottom:'Detajet Kryesore të Kreut',
        homeBox1Header:'Kuti Header 1 e Kreut',
        homeBox1Detail:'Detajet e Kuti 1 të Kreut',
        homeBox2Header:'Kuti Header 2 e Kreut',
        homeBox2Detail:'Detajet e Kuti 2 të Kreut',
        homeBox3Header:'Kuti Header 3 e Kreut',
        homeBox3Detail:'Detajet e Kuti 3 të Kreut',
        homeBox4Header:'Kuti Header 4 e Kreut',
        homeBox4Detail:'Detajet e Kuti 4 të Kreut',
        feature1Header:'Header i Karakteristikës 1',
        feature1Detail:'Detajet e Karakteristikës 1',
        feature2Header:'Header i Karakteristikës 2',
        feature2Detail:'Detajet e Karakteristikës 2',
        feature3Header:'Header i Karakteristikës 3',
        feature3Detail:'Detajet e Karakteristikës 3',
        feature4Header:'Header i Karakteristikës 4',
        feature4Detail:'Detajet e Karakteristikës 4',
        registrationText:'Teksti i Regjistrimit',
        conactusText:'Teksti i Kontaktit',
        conactusTelephone:'Telefoni i Kontaktit',
        conactusEmail:'Emaili i Kontaktit',
        landingFooterText:'Teksti i Poshtëm i Faqes',
        footerFacebook:'Facebook i Këmbës',
        footerTwitter:'Twitter i Këmbës',
        footerLinkedIn:'LinkedIn i Këmbës',
        footerInstagram:'Instagrami i Këmbës',
        subject:'Subjekti',
        header:'Krye',
        forgetPasswordEmail:'Emaili i Harrova Fjalëkalimit',
        welcomeEmail:'Emaili i Mirëseardhjes',
        invitationEmail:'Emaili i Ftesave',
        reportEmail:'Emaili i Raportit',
        newPassword:'Fjalëkalimi i Ri',
        confirmNewPassword:'Konfirmo Fjalëkalimin e Ri',
        change:'Ndrysho',
        logInTime:'Koha e Hyrjes',
        logOutTime:'Koha e Daljes',
        ip:'IP',
        browser:'Shfletuesi',
        browserVersion:'Versioni i Shfletuesit',
        platform:'Platforma',
        id:'ID',
        quizCategoryName:'Qyteti',
        newCategory:'Qytet i Ri',
        editCategory:'Ndrysho Kategorinë e Qytetit',
        internetDisruption:'Shkëputje nga Interneti!',
        noInternetMsg:"Duket se lidhja juaj në internet nuk është e qëndrueshme ose jeni offline. Mund të qëndroni këtu, ne do t'ju njoftojmë kur të ktheheni në linjë.",
        entryRestricted:'Qasja e Kufizuar',
        forbiddenMsg:'Nuk keni qasje këtu. Po provoni diçka që është e kufizuar për ju.',
        errorBackMsg:'Provoni të ktheheni në faqen e kyçjes duke klikuar',
        here:'këtu',
        pagenotfound:'Faqja nuk u gjet',
        notFoundMsg:'Faqja që po përpiqeni të arrini nuk ekziston.',
        tokenExpired:'Tokeni Ka Skaduar',
        unauthorizedMsg:'Tokeni juaj aktual ka skaduar! Duhet të kyçeni përsëri.',
        numberofQuestions:'Numri i Pyetjeve',
        timeMin:'Koha (min)',
        marks:'Vlerësimet',
        multipleInput:'Shumë Hyrje',
        multipleAnswer:'Shumë Përgjigje',
        ipRequired:'IP është Obligative',
        scheduleStartDate:' Data e Fillimit',
        scheduleEndDate:'Data e Mbarimit të Orarit',
        status:'Statusi',
        goLive:'Shko Live',
        stopLive:'Ndalo Live',
        newAssessment:'Kuiz i Ri',
        editAssessment:'Ndrysho Kuizin',
        markOption:'Opsioni i Vlerësimit',
        isIpRequired:'A është patjetër IP-ja',
        buyTime: "Shpenzo",
        allowMultipleAnswer:'Prano përgjigje të shumta për një pyetje nga përdoruesi',
        isLekAward:'A fitohen Lekë?',
        reqTimeInMin:'Koha e Kërkuar (Në minuta)',
        passMarks:'Vendos Vlerën',
        certificateTemplate:'Shabllon i Certifikatës',
        displayStopOption:'Opsioni i ndaljes së shfaqjes',
        displaySkipOption:'Opsioni i blerjes së kohës së shfaqjes',
        questionSuffle:'Rrëshqitja e Pyetjeve',
        correctAnswerDisplay:'Shfaq përgjigjen e saktë për kandidatët',
        participantOption:'Opsioni i Pjesëmarrjes',
        sentInvitationtoEmail:'Dërgoni ftesë në email',
        participantsEmail:'Emailtë e Pjesëmarrësve (Ndarë me presje të shkronjës)',
        sentLive:'Jeni të sigurt që dëshironi të dërgoni këtë Kuiz live?',
        stopFromLive:'Je i sigurt që dëshiron ta anulosh këtë kuiz?',
        addQuestions:'Shtoni Pyetjet',
        addInstructions:'Shtoni Udëzimet',
        newInstruction:'Udëzim i Ri',
        editInstruction:'Ndrysho Udëzimin',
        quizPrice: 'Çmimi në LEK',
        instruction:'Udëzimi',
        newQuestion:'Pyetje e Re',
        editQuestion:'Ndrysho Pyetjen',
        sampleCSV:'CSV Shembull',
        uploadInstructions:'Ngarkoni Udëzimet',
        questions:'Pyetje',
        category:'Qyteti',
        correctAnswer:'Përgjigje e Saktë',
        codeSnippet:'Fjalëkalimi Snippet',
        questionType:'Lloji i Pyetjes',
        complexityLavel:'Niveli i Kompleksitetit',
        quizCategory:'Qyteti',
        questionYoutubeVideo:'Pyetje Video (video nga YouTube)',
        questionImage:'Imazhi i Pyetjes',
        answerExplanation:'Shënim',
        selectcategorytofilter:'Zgjidhni kuizin e qytetit për të filtruar',
        time:'Koha',
        minutes:' minuta',
        view:'Shiko',
        newTemplate:'Shablloni i Ri',
        editTemplate:'Ndrysho Shabllonin',
        certificateTitle:'Titulli i Certifikatës',
        certificateTopHeader:'Headeri i Lartë i Certifikatës',
        certificateMainText:'Teksti Kryesor i Certifikatës',
        topLeftLogo:'Logoja e Majtë e Lartë',
        topRightLogo:'Logoja e Djathtë e Lartë',
        bottomLogo:'Logoja e Poshtme',
        backGround:'Prapavija',
        signatureLeft:'Nënshkrimi Majtas',
        signatureLeftText:'Teksti i Nënshkrimit Majtas',
        signatureRight:'Nënshkrimi Djathtas',
        signatureRightText:'Teksti i Nënshkrimit Djathtas',
        selectonetofilter:'Zgjidhni një për të filtruar',
        sentEmail:'Dërgo Email',
        sentemailtocheckedstudents:'Dërgoni email tek kandidatët e zgjedhur',
        search:'Kërko',
        timeTakenMin:'Koha e marrë (min)',
        marksObtained:'Vlerësimet e Fituara',
        attemptNumber:'Numri i Përpjekjes',
        attemptDateTime:'Data',
        questionMark:'Vlerësimi i Pyetjes',
        answerInput:'Hyrja e Përgjigjes',
        skipped:'E Kapëryer?',
        yourMarks:'Vlerësimet Tuaja',
        pendingExamine:'Në pritje për vlerësim',
        resultSheet:'Fletë e Rezultateve',
        certificate:'Certifikata',
        amountPaidUSD:'Shuma e Paguar (USD)',
        noAssessment:'Nuk ka kuize të disponueshme!',
        availableAssessment:'Kuize të disponueshme',
        instructions:'Udëzimet',
        continue:'Vazhdo',
        Dashboard:'Dashboard',
        Menus:'Menytë',
        Roles:'Roli',
        Users:'Përdoruesit',
        Category:'Qytetet',
        cityQuizes:'Kuizet',
        Assessments:'Kuizet',
        Questions:'Pyetjet',
        Reports:'Raportet',
        CertificateTemplate:'Shablloni i Certifikatës',
        ExamineAndReports:'Raportet',
        Analytics:'Diamantë',
        Contacts:'Kontaktet',
        Payments:'Pagesat',
        Plans:'Diamantët',
        AppSettings:'Cilësimet e Aplikacionit',
        nameEmptyRuleLanding:'Emri nuk mund të jetë i zbrazët',
        nameCharacterRuleLanding:'Gjatësia duhet të jetë më e madhe ose e barabartë me 6 karaktere',
        emailEmptyRuleLanding:'Emaili nuk mund të jetë i zbrazët',
        emailWrongRuleLanding:'Emaili i Gabuar!',
        messageEmptyRuleLanding:'Mesazhi nuk mund të jetë i zbrazët',
        messageCharacterRuleLanding:'Gjatësia duhet të jetë më e madhe ose e barabartë me 10 karaktere',
        successfullySubmitted:'Dërgimi është kryer me sukses',
        planExpiredMsg:'Plani i shërbimit tuaj ka skaduar! Klikoni për të rinovuar',
        billingPlanRenewed:'Plani i shërbimit është rinovuar',
        planRenewalSuccess:'Rinovimi i planit të faturimit është i suksesshëm! Kyçuni përsëri.',
        newContentsAvailable:'Përmbajtje të reja në dispozicion! Klikoni për rifreskim për të aplikuar ndryshimet.',
        mainText:'Teksti kryesor',
        menuDeleteRestriction:'Kjo Meny është e kufizuar për tu fshirë.',
        successfullyDeleted:'Fshirja është kryer me sukses',
        successfullySaved:'Ruajtja është kryer me sukses',
        duplicateMenuTitle:'Titull i Dubluar i Menysë!',
        duplicateURL:'URL i Dubluar!',
        duplicateOrderNo:'Numri i Rradhës i Dubluar!',
        orderNomustgreaterthan0:'Numri i Rradhës duhet të jetë më i madh se 0!',
        somethingUnexpected:'Diçka e Papritur!',
        successfullyUpdated:'Përditësimi është kryer me sukses',
        successfullyAssigned:'Përcaktimi është kryer me sukses',
        successfullyUnAssigned:'Anulimi është kryer me sukses',
        limitExpired:'E keni kaluar kufirin e planit tuaj të faturimit. Nuk mund të shtoni më shumë se kaq.',
        questionEmptyRule:'Pyetja nuk mund të jetë e zbrazët',
        complexityLevelEmpty:'Niveli i Kompleksitetit nuk mund të jetë i zbrazët',
        selectCategory:'Zgjidh Kategorinë',
        questionTypeEmpty:'Lloji i Pyetjes nuk mund të jetë i zbrazët',
        correctAnswerEmpty:'Përgjigje e Saktë nuk mund të jetë e zbrazët',
        marksEmpty:'Vlerësimet nuk mund të jetë e zbrazët',
        incorrectAnswer:'Përgjigje e Gabuar!',
        successfullyUploaded:'Ngarkimi është kryer me sukses',
        uploadOnlyCSV:'Ju lutemi, ngarkoni vetëm skedarë CSV',
        uploadMax5MB:'Ju lutemi, ngarkoni një skedar të vogël maksimal 5MB',
        validImageType:'Ju lutemi, ngarkoni një lloj të valid të imazhit',
        validVideoType:'Ju lutemi, ngarkoni një lloj të valid të videove',
        instructionsEmptyRule:'Udëzimi nuk mund të jetë i zbrazët',
        instructionMinlengthRule:'Udëzimi duhet të jetë më i madh ose i barabartë me 20 karaktere',
        titleEmptyRule:'Titulli nuk mund të jetë i zbrazët',
        mainTextEmptyRule:'Teksti kryesor nuk mund të jetë i zbrazët',
        isLekAwardedEmptyRule:'A fitohen lekë? nuk mund të jetë i zbrazët',
        reqTimeInMinEmptyRule:'Koha e kërkuar (Në minuta) nuk mund të jetë e zbrazët',
        reqTimeInMinMinRule:'Koha e kërkuar (Në minuta) nuk mund të jetë më e vogël se 1',
        passMarksEmptyRule:'Vendos Vlerën nuk mund të jetë i zbrazët',
        certificateTemplateTitleEmptyRule:'Titulli i Shabllonit të Certifikatës nuk mund të jetë i zbrazët',
        certificateTemplateMainTextEmptyRule:'Teksti Kryesor i Shabllonit të Certifikatës nuk mund të jetë i zbrazët',
        validateEmail:'Ju lutemi, vendosni një email valid',
        emailNotAvailable:'Ky email nuk është i lirë për regjistrim',
        nameEmptyRule:'Emri nuk mund të jetë i zbrazët',
        nameCharacterRule:'Gjatësia duhet të jetë më e madhe ose e barabartë me 3 karaktere',
        passwordEmptyRule:'Fjalëkalimi nuk mund të jetë i zbrazët',
        passwordMinlengthRule:'Fjalëkalimi duhet të jetë më i madh ose i barabartë me 8 karaktere',
        passwordCharacterRule:'Fjalëkalimi duhet të përmbajë të paktën një numër, një shkronjë të madhe dhe një shkronjë të vogël',
        mobileEmptyRule:'Numri i celularit nuk mund të jetë i zbrazët',
        mobileMinlengthRule:'Numri i celularit duhet të jetë më i madh ose i barabartë me 10 karaktere',
        mobileMaxlengthRule:'Numri i celularit nuk mund të jetë më i madh se 15 karaktere',
        displayPictureEmptyRule:'Duhet të zgjidhni një foto',
        contactSavedSuccessfully:'Kontakti është ruajtur me sukses!',
        contactUpdatedSuccessfully:'Kontakti është përditësuar me sukses!',
        errorContact:'Ka ndodhur një gabim! Ju lutemi, provoni përsëri më vonë.',
        errorPayment:'Ka ndodhur një problem me pagesën tuaj. Ju lutemi, provoni përsëri ose kontaktoni mbështetjen tonë.',
        errorAssessment:'Ka ndodhur një gabim gjatë krijimit ose editimit të kuizit. Ju lutemi, provoni përsëri ose kontaktoni mbështetjen tonë.',
        selectTemplate:'Zgjidhni Shabllonin',
        sentInvitation:'Ftesa është dërguar me sukses',
        sentInvitationFailed:'Ftesa nuk mund të dërgohet për studentin: ',
        answerExplanationEmptyRule:'Shpjegimi i Përgjigjes nuk mund të jetë i zbrazët',
        imageUrlEmptyRule:'URL i Imazhit nuk mund të jetë i zbrazët',
        isIpRequiredEmptyRule:'A është patjetër IP-ja? nuk mund të jetë i zbrazët',
        questionMinLengthRule:'Pyetja duhet të jetë më e madhe ose e barabartë me 6 karaktere',
        questionMaxLengthRule:'Pyetja nuk mund të jetë më e madhe se 1000 karaktere',
        answerExplanationMinLengthRule:'Shpjegimi i Përgjigjes duhet të jetë më i madh ose i barabartë me 20 karaktere',
        answerExplanationMaxLengthRule:'Shpjegimi i Përgjigjes nuk mund të jetë më i madh se 1000 karaktere',
        preview:'Shiko paraprakisht',
        sentEmailMsg:'Emaili është dërguar me sukses!',
        noParticipant:'Nuk ka pjesëmarrës për këtë kuiz!',
        categoryEmptyRule:'Kuizi i Qytetit nuk mund të jetë i zbrazët',
        editCategoryValidationMsg:'Ky kuiz i qytetit është përdorur në ndonjë pyetje. Ju lutemi, ndryshoni ato pyetje që përdorin këtë kuiz të qytetit para se të editoni.',
        noReport:'Nuk ka raporte për të shfaqur!',
        noAssessmentForCertificate:'Nuk ka kuize për të përdorur shabllonin e certifikatës!',
        transactionSuccessfully:'Transaksioni është kryer me sukses!',
        contactDeleteConfirmation:'Jeni i sigurt që dëshironi të fshini kontaktin?',
        contactDeleteSuccessfully:'Kontakti është fshirë me sukses!',
        contactUnDeleteSuccessfully:'Kontakti është rikthyer me sukses!',
        invalidActivationLink:'Lidhja e aktivizimit është e pavlefshme!',
        invalidUser:'Përdoruesi është jo valid!',
        registrationSuccessMsg:'Regjistrimi është kryer me sukses!',
        registrationFailedMsg:'Regjistrimi dështoi! Ju lutemi, provoni përsëri më vonë.',
        accountActivatedSuccessfully:'Llogaria është aktivizuar me sukses!',
        invalidResetPasswordLink:'Lidhja e rivendosjes së fjalëkalimit është e jo valide!',
        passwordResetSuccess:'Fjalëkalimi është ndryshuar me sukses!',
        passwordResetFailed:'Fjalëkalimi nuk mund të ndryshohet!',
        welcomeEmailError:'Gabim gjatë dërgimit të emailit të mirëseardhjes!',
        activationMailError:'Gabim gjatë dërgimit të emailit të aktivizimit!',
        resetPasswordMailError:'Gabim gjatë dërgimit të emailit të rivendosjes së fjalëkalimit!',
        forgotPasswordEmailSent:'Emaili për të haruar fjalëkalimin është dërguar me sukses!',
        forgotPasswordEmailFailed:'Emaili për të haruar fjalëkalimin nuk mund të dërgohet! Ju lutemi, provoni përsëri më vonë.',
        noUserExists:'Nuk ka përdorues me këtë email!',
        quizPriceEmptyRule:'Çmimi i Kuizit nuk mund të jetë i zbrazët',
        insufficientCredits:'Kredi të pamjaftueshme!',
        duplicateAssessmentName:'Emri i Kuizit është duplikat!',
        participantMailEmptyRule:'Emailet e Pjesëmarrësve nuk mund të jetë i zbrazët',
        validMobileNumber:'Numri i celularit duhet të jetë një numër i valid',
        usernameCharacterRule:'Gjatësia duhet të jetë më e madhe ose e barabartë me 5 karaktere',
        userRoleEmptyRule:'Roli nuk mund të jetë i zbrazët',
        assessmentLiveErrorMsg:'Nuk mund të bëni këtë, një Kuiz tjetër tashmë është Live!',
        assessmentStopErrorMsg:'Nuk mund të ndalni këtë Kuiz, nuk është i gjallë!',
        assessmentDeletedSuccessfully:'Kuizi është fshirë me sukses!',
        invalidYouTubeLink:'Lidhja e YouTube e jo valide!',
        insufficientCreditsForLive:'Nuk keni kredi të mjaftueshme për të bërë këtë Kuiz Live!',
        noAccess:'Nuk keni qasje në këtë!',
        signInToYourAccount:'Ky veprim kërkon që të jeni i kyçur në llogarinë tuaj.',
        activeSubscription:'Abonimi Aktiv',
        expiredSubscription:'Abonimi i Skaduar',
        amountPaid:'Shuma e paguar',
        buySubscription:'Bli abonim',
        transactionID:'ID e Transaksionit',
        paymentReceipt:'Marrja e Pagesës',
        chooseYourPlan:'Zgjidhni planin tuaj',
        selectPlan:'Zgjidhni Planin',
        monthly:'Mujor',
        monthlyYearly:'Mujor dhe Vjetor',
        viewMore:'Shiko më shumë',
        validTill:'E vlefshme deri',
        chooseFile:'Zgjidh skedarin',
        fileCannotExceed5MB:'Skedari nuk mund të jetë më i madh se 5MB',
        invalidFileType:'Lloji i skedarit nuk është i valid',
        instructionSuccessfullySaved:'Udëzimi u ruajt me sukses',
        successfullyDownloadedCSV:'Skedari CSV u shkarkua me sukses!',
        downloadFailedCSV:'Skedari CSV nuk mund të shkarkohet! Ju lutemi, provoni përsëri më vonë.',
        assessmentOnLiveWarning:'Nëse ndryshoni ndonjë gjë, do të ndërpriten kuizet Live. Jeni i sigurt që dëshironi të ndryshoni?',
        assessmentOnLiveWarningTitle:'Të dhënat për ndryshim!',
        assessmentLiveStopMsg:'Ju nuk mund të bëni këtë, ky Kuiz është tashmë Live.',
        deleteContentMsg:'Jeni i sigurt që dëshironi të fshini këtë përmbajtje?',
        invalidContentType:'Lloji i përmbajtjes është i jo valid',
        invalidContentSize:'Madhësia e përmbajtjes është e jo valide',
        successfullyDeletedContent:'Përmbajtja është fshirë me sukses!',
        successfullySavedContent:'Përmbajtja u ruajt me sukses!',
        validUserMsg: 'Përdoruesi është valid',
        invalidUserMsg: 'Përdoruesi është jo valid',
        active: 'Aktiv',
        inactive: 'Pasiv',
        userSavedSuccessfully: 'Përdoruesi u ruajt me sukses',
        userDeletedSuccessfully: 'Përdoruesi është fshirë me sukses',
        userStatusUpdateSuccessfully: 'Statusi i Përdoruesit është përditësuar me sukses',
        changePasswordSuccessfully: 'Fjalëkalimi u ndryshua me sukses',
        feedbackEmptyRule: 'Fusha e feedback nuk mund të jetë e zbrazët',
        feedbackMinLengthRule: 'Fusha e feedback duhet të jetë më e madhe ose e barabartë me 10 karaktere',
        feedbackMaxLengthRule: 'Fusha e përshtypjeve nuk mund të jetë më e madhe se 1000 karaktere',
        feedbackSavedSuccessfully: ' Feedback u ruajtën me sukses',
        feedbackFailed: 'Feedback nuk mund të ruhen',
        dashboardErrorMsg: 'Nuk ka të dhëna për të shfaqur',
        pdfUploadErrorMsg: 'Skedari PDF nuk mund të ngarkohet! Ju lutemi, provoni përsëri ose kontaktoni mbështetjen tonë.',
        categoryDeletedSuccessfully: 'Kategoria është fshirë me sukses',
        categorySavedSuccessfully: 'Kategoria është ruajtur me sukses',
        categoryUpdatedSuccessfully: 'Kategoria është përditësuar me sukses',
        questionSavedSuccessfully: 'Pyetja u ruajt me sukses',
        questionDeletedSuccessfully: 'Pyetja është fshirë me sukses',
        questionUpdatedSuccessfully: 'Pyetja është përditësuar me sukses',
        invalidQuestionType: 'Lloji i pyetjes është i jovalid',
        invalidCityKuiz: 'Kuizi i qytetit është i jovalid',
        deleteCategoryMsg: 'Jeni i sigurt që dëshironi të fshini këtë kategori?',
        isSolved: 'Kuiz i Zgjidhur',
        totalQuestions:'Gjithsej Pyetje',
        totalCashBalance:'Lek',
        totalPointBalance:'Diamant',
        totalQuizzesSolved:'Trofe',
        userName:'Emri',
        userId:'Id e perdoruesit',
        balance:'Bilanci',
        paymentCompleted:'Pagesa eshte perfunduar',
        paymentCompletedDescription:'Pagesa juaj me PayPal është përfunduar me sukses. Faleminderit për transaksionin tuaj. Blerja juaj është konfirmuar. Nëse keni pyetje ose shqetësime të tjera në lidhje me pagesën tuaj, ju lutemi mos ngurroni të kontaktoni ekipin tonë të mbështetjes së klientëve.',
        noCategoriesAvailable:'Ju keni zgjidhur të gjitha kuizet në dispozicion.',
        btnBuyCredit: 'Blej kredi',
        accept:'Pajtohem',
        buyTimeMessagePopup: 'Që të zbuloni pyetjen 12 orë para orarit të planifikuar, ju duhet të shpenzoni',
        fromYourBalance: 'nga bilanci juaj.',
        hintMessagePopup:'Marrja e një sqarimi për pyetjen do të shpenzojë',
        needVerification:'Kërkohet verifikimi',
        needVerificationDesc:'Përpara se të vazhdojmë me pagesën, duhet të verifikojmë numrin tuaj. Ju lutemi ndiqni hapin e treguar më poshtë.',
        sendCode:'Dergo kodin',
        verify:'Verifiko'


    },
    // 'es': {   
    //     home:'Casa',
    //     pricing:'Precios',
    //     features:'Características',
    //     contact:'Contacto',
    //     signIn:'Registrarse',
    //     getRegister:'Regístrese',
    //     registerNow:'Regístrate ahora',
    //     contactUs:'Contáctenos',
    //     admin:'Administrador',
    //     candidate:'Candidatos',
    //     cashBalance:'Cash Balance',
    //     pointBalance:'Point Balance',
    //     quizSolved:'Quiz Solved',
    //     assess:'Evaluaciones',
    //     liveAssess:'Evaluaciones en vivo',
    //     question:'Preguntas',
    //     dashboardChart1:'Pago (fecha sabia)',
    //     dashboardChart2:'Participación (fecha sabia)',
    //     dashboardChart3:'Participación (tema sabio)',
    //     dashboardChart4:'Iniciar sesión (fecha sabia)',
    //     language:'Idioma',
    //     personalize:'Personalizar',
    //     leaveHeader:'¿Querer irse?',
    //     leaveConfirm:'Pulse Cerrar sesión para salir',
    //     stay:'Quédate aquí',
    //     signOut:'Desconectar',
    //     Profile:'Perfil',
    //     Password:'Clave',
    //     Browsing:'Hojeada',
    //     FAQ:'Preguntas más frecuentes',
    //     email:'Correo electrónico',
    //     telephone:'Teléfono',
    //     message:'Mensaje',
    //     submit:'Enviar',
    //     password:'Clave',
    //     name:'Nombre',
    //     forgetPassword:'Contraseña olvidada',
    //     register:'Registro',
    //     close:'Cerrar',
    //     sentPassword:'Contraseña enviada',
    //     candidateRegister:'Registro de candidatos',
    //     adminRegister:'Registro de administrador',
    //     passwordLengthMessage:'Al menos 8 carácteres',
    //     required:'Requerido',
    //     min8Character:'Mínimo 8 caracteres',
    //     emailInvalid:'El email debe ser válido',
    //     assessments:'evaluaciones',
    //     responsesForAllAssessment:'respuestas para todas las evaluaciones',
    //     responsesForEachAssessment:'respuestas para cada evaluación',
    //     subscribe:'Suscribir',
    //     newMenu:'Nuevo Menú',
    //     editMenu:'Menú de edición',
    //     menuTitle:'Título del menú',
    //     url:'URL',
    //     orderNo:'N º de pedido.',
    //     iconMaterial:'Icono(Material-Icono)',
    //     actions:'Comportamiento',
    //     save:'Ahorrar',
    //     cancel:'Cancelar',
    //     ok:'DE ACUERDO',
    //     deleteConfirmMessage:'¿Estás seguro de que quieres eliminar este artículo?',
    //     mobile:'Móvil',
    //     dateOfBirth:'Fecha de cumpleaños',
    //     displayName:'Nombre para mostrar',
    //     role:'Role',
    //     plan:'Plan',
    //     interval:'Intervalo',
    //     paymentMode:'Modo de pago',
    //     expireyDate:'Fecha de caducidad',
    //     transactionDetail:'Detalle de la transacción',
    //     newUser:'Nuevo usuario',
    //     editUser:'editar usuario',
    //     billingPlan:'Plan de facturación',
    //     profilePicture:'Foto de perfil',
    //     renewPlan:'Plan Renovar',
    //     previousPlan:'Plano anterior',
    //     previousPaymentMode:'Modo de pago anterior',
    //     previousTransactionDetail:'Detalle de transacción anterior',
    //     renew:'Renovar',
    //     newPlan:'Nuevo plan',
    //     newMode:'Nuevo modo',
    //     delete:'Borrar',
    //     description:'Descripción',
    //     newRole:'Nuevo rol',
    //     editRole:'Editar rol',
    //     assignedMenus:'Menús asignados',
    //     edit:'Editar',
    //     newFaq:'Nuevas preguntas frecuentes',
    //     editFaq:'Editar preguntas frecuentes',
    //     title:'Título',
    //     dateTime:'Fecha y hora',
    //     assessment:'Evaluación',
    //     priceUsd:'Precio (dólares americanos)',
    //     questionPerAssessmentCount:'Preguntas (por evaluación)',
    //     responsePerAssessmentCount:'Respuesta (por evaluación)',
    //     responseAllAssessmentCount:'Respuesta (todas las evaluaciones)',
    //     startDate:'Fecha de inicio',
    //     endDate:'Fecha de finalización',
    //     stripeSessionId:'ID de sesión de banda',
    //     userEmail:'Correo electrónico del usuario',
    //     transactionEmail:'Correo electrónico de transacción',
    //     editPlan:'Editar plan',
    //     additionalText:'Texto adicional',
    //     Monthly:'Mensual',
    //     Yearly:'Anual',
    //     generalSettings:'Configuración general',
    //     examSettings:'Configuración del examen',
    //     paymentSettings:'Configuración de pago',
    //     emailSettings:'Ajustes del correo electrónico',
    //     colorSettings:'Ajustes de color',
    //     landingPage:'Página de destino',
    //     emailText:'Texto de correo electrónico',
    //     siteTitle:'Título del sitio',
    //     welcomeMessage:'Mensaje de bienvenida',
    //     copyrightText:'Texto de derechos de autor',
    //     allowWelcomeEmail:'Permitir correo electrónico de bienvenida',
    //     allowFAQ:'Permitir preguntas frecuentes',
    //     siteLogo:'Logotipo del sitio',
    //     appVersion:'Version de aplicacion',
    //     siteFavicon:'Favicon del sitio',
    //     browserrefreshendtheexam:'Actualización del navegador finalizar el examen',
    //     sitelogoonexampage:'Logotipo del sitio en la página del examen',
    //     stripe:'Stripe',
    //     currency:'Divisa',
    //     currencySymbol:'Símbolo de moneda',
    //     stripeSecretkey:'Stripe Secret key',
    //     port:'Puerto de correo electrónico',
    //     hostSmtp:'Anfitrión (SMTP)',
    //     appBar:'Barra de aplicaciones',
    //     footer:'Pie de página',
    //     body:'Cuerpo',
    //     homeHeaderTop:'Parte superior del encabezado de inicio',
    //     homeDetailTop:'Inicio detalle superior',
    //     homeHeaderBottom:'Parte inferior del encabezado de inicio',
    //     homeDetailBottom:'Inicio detalle inferior',
    //     homeBox1Header:'Inicio Box1 Encabezado',
    //     homeBox1Detail:'Inicio Box1 Detalle',
    //     homeBox2Header:'Inicio Box2 Encabezado',
    //     homeBox2Detail:'Inicio Box2 Detalle',
    //     homeBox3Header:'Inicio Box3 Encabezado',
    //     homeBox3Detail:'Inicio Box3 Detalle',
    //     homeBox4Header:'Inicio Box4 Encabezado',
    //     homeBox4Detail:'Inicio Box4 Detalle',
    //     feature1Header:'Encabezado Característica1',
    //     feature1Detail:'Característica1 Detalle',
    //     feature2Header:'Encabezado Feature2',
    //     feature2Detail:'Característica2 Detalle',
    //     feature3Header:'Encabezado Feature3',
    //     feature3Detail:'Característica3 Detalle',
    //     feature4Header:'Encabezado Feature4',
    //     feature4Detail:'Característica4 Detalle',
    //     registrationText:'Texto de registro',
    //     conactusText:'Texto de contacto',
    //     conactusTelephone:'Contáctanos Teléfono',
    //     conactusEmail:'Correo electrónico de contacto',
    //     landingFooterText:'Texto del pie de página de aterrizaje',
    //     footerFacebook:'Pie de página de Facebook',
    //     footerTwitter:'Twitter de pie de página',
    //     footerLinkedIn:'Pie de página de LinkedIn',
    //     footerInstagram:'Pie de página de Instagram',
    //     subject:'Sujeto',
    //     header:'Encabezamiento',
    //     forgetPasswordEmail:'Olvidé mi contraseña Correo electrónico',
    //     welcomeEmail:'Correo electrónico de bienvenida',
    //     invitationEmail:'Correo electrónico de invitación',
    //     reportEmail:'Informe de correo electrónico',
    //     newPassword:'Nueva contraseña',
    //     confirmNewPassword:'Confirmar nueva contraseña',
    //     change:'Cambiar',
    //     logInTime:'Hora de inicio de sesión',
    //     logOutTime:'Hora de cierre de sesión',
    //     ip:'IP',
    //     browser:'Navegador',
    //     browserVersion:'Versión del navegador',
    //     platform:'Plataforma',
    //     id:'IDENTIFICACIÓN',
    //     quizCategoryName:'Pregunta Categoría Nombre',
    //     newCategory:'Nueva categoría',
    //     editCategory:'Editar categoria',
    //     internetDisruption:'Interrupción de Internet!',
    //     noInternetMsg:"Parece que su conexión a Internet no es estable o está desconectado. Puede quedarse aquí, le avisaremos cuando vuelva a conectarse.",
    //     entryRestricted:'Entrada restringida',
    //     forbiddenMsg:'No tienes acceso aquí. Estás intentando algo cuyo acceso está limitado para ti.',
    //     errorBackMsg:'Intente volver a la página de inicio de sesión haciendo clic en',
    //     here:'aquí',
    //     pagenotfound:'Página no encontrada',
    //     notFoundMsg:'La página a la que intenta acceder nunca existió en esta realidad o ha migrado a un universo paralelo.',
    //     tokenExpired:'Ficha caducada',
    //     unauthorizedMsg:'¡Tu token actual ha caducado! necesitas iniciar sesión de nuevo.',
    //     numberofQuestions:'numero de preguntas',
    //     timeMin:'Tiempo (min)',    
    //     marks:'Marcas',
    //     multipleInput:'Entrada múltiple',
    //     multipleAnswer:'Respuesta múltiple',
    //     multitleAttempt:'Intento múltiple',
    //     scheduleStartDate:'Programar fecha de inicio',
    //     scheduleEndDate:'Programar fecha de finalización',
    //     status:'Estado',
    //     goLive:'Ir a vivir',
    //     stopLive:'Detener en vivo',
    //     newAssessment:'Nueva evaluación',
    //     editAssessment:'Editar evaluación',
    //     markOption:'Marcar opción',
    //     allowMultipleAttempt:'Permitir intento ilimitado para un solo usuario',
    //     allowMultipleAnswer:'Tomar respuesta múltiple para una sola pregunta por usuario',
    //     allowMultipleInput:'Conjunto de opciones múltiples para una sola pregunta',
    //     reqTimeInMin:'Tiempo requerido (en minutos)',
    //     passMarks:'Aprobado',
    //     certificateTemplate:'Plantilla de certificado',
    //     displayStopOption:'Mostrar opción de parada',
    //     displaySkipOption:'Mostrar opción de omisión',
    //     questionSuffle:'Mezcla de preguntas',
    //     correctAnswerDisplay:'Mostrar la respuesta correcta a los candidatos',
    //     participantOption:'Opción de participación',
    //     sentInvitationtoEmail:'Invitación enviada al correo electrónico',
    //     participantsEmail:'Correo electrónico de los participantes (coma separada)',
    //     sentLive:'¿Está seguro de que desea enviar esta evaluación en vivo?',
    //     stopFromLive:'¿Está seguro de detener esta evaluación en vivo?',
    //     addQuestions:'Agregar preguntas',
    //     addInstructions:'Agregar instrucciones',
    //     newInstruction:'nueva instrucción',
    //     editInstruction:'Editar instrucción',
    //     instruction:'Instrucción',
    //     newQuestion:'nueva pregunta',
    //     editQuestion:'Editar pregunta',
    //     sampleCSV:'CSV de muestra',
    //     uploadInstructions:'Instrucciones de carga',
    //     questions:'Preguntas',
    //     category:'Categoría',
    //     correctAnswer:'Respuesta correcta',
    //     codeSnippet:'Fragmento de código',
    //     questionType:'tipo de pregunta',
    //     complexityLavel:'Nivel de complejidad',
    //     quizCategory:'Categoría de pregunta',
    //     questionYoutubeVideo:'Video de pregunta (video incrustado de youtube)',
    //     questionImage:'Imagen de pregunta',
    //     answerExplanation:'Respuesta Explicación',
    //     selectcategorytofilter:'Seleccionar categoría para filtrar',
    //     time:'Hora',
    //     minutes:' minutos',
    //     view:'Vista',
    //     newTemplate:'Nueva plantilla',
    //     editTemplate:'Editar plantilla',
    //     certificateTitle:'Título del certificado',
    //     certificateTopHeader:'Encabezado superior del certificado',
    //     certificateMainText:'Texto principal del certificado',
    //     topLeftLogo:'Logotipo superior izquierdo',
    //     topRightLogo:'Logotipo superior derecho',
    //     bottomLogo:'Logotipo inferior',
    //     backGround:'Antecedentes',
    //     signatureLeft:'Firma a la izquierda',
    //     signatureLeftText:'Firma Texto Izquierdo',
    //     signatureRight:'Derecho de firma',
    //     signatureRightText:'Firma Derecho Texto',
    //     selectonetofilter:'Seleccione uno para filtrar',
    //     sentEmail:'Enviar correo electrónico',
    //     sentemailtocheckedstudents:'Enviar correo electrónico a los estudiantes revisados',
    //     search:'Búsqueda',
    //     timeTakenMin:'Tiempo empleado (min)',
    //     marksObtained:'marcas obtenidas',
    //     attemptNumber:'Número de intento',
    //     attemptDateTime:'Intento Fecha-Hora',
    //     questionMark:'Signo de interrogación',
    //     answerInput:'Entrada de respuesta',
    //     skipped:'¿Omitido?',
    //     yourMarks:'tus marcas',
    //     pendingExamine:'Examen pendiente',
    //     resultSheet:'Hoja de resultados',
    //     certificate:'Certificado',
    //     amountPaidUSD:'Cantidad pagada (USD)',
    //     noAssessment:'¡No hay evaluación disponible!',
    //     availableAssessment:'Evaluaciones disponibles',
    //     instructions:'Evaluaciones disponibles',
    //     continue:'Continuar',
    //     Dashboard:'Tablero',
    //     Menus:'Menús',
    //     Roles:'Roles',
    //     Users:'Usuarios',
    //     Category:'Categoría',
    //     Assessments:'Evaluaciones',
    //     Questions:'Preguntas',
    //     Reports:'Informes',
    //     CertificateTemplate:'Plantilla de certificado',
    //     ExamineAndReports:'Examen e Informes',
    //     Analytics:'Analítica',
    //     Contacts:'Contactos',
    //     Payments:'Pagos',
    //     Plans:'planes',
    //     AppSettings:'Ajustes de Aplicacion',
    //     nameEmptyRuleLanding:'El nombre no puede estar vacío',
    //     nameCharacterRuleLanding:'La longitud debe ser mayor o igual a 6 caracteres',
    //     emailEmptyRuleLanding:'El correo electrónico no puede estar vacío',
    //     emailWrongRuleLanding:'¡Email incorrecto!',
    //     messageEmptyRuleLanding:'El mensaje no puede estar vacío',
    //     messageCharacterRuleLanding:'La longitud debe ser mayor o igual a 10 caracteres',
    //     successfullySubmitted:'Enviado correctamente',
    //     planExpiredMsg:'¡Tu plan de pago expiró! Haga clic en renovar para obtener eso nuevamente.',
    //     billingPlanRenewed:'Plan de facturación renovado',
    //     planRenewalSuccess:'¡La renovación del plan de facturación se realizó correctamente! Inicie sesión de nuevo.',
    //     newContentsAvailable:'¡Nuevos contenidos disponibles! Haga clic en actualizar para afectar los cambios.',
    //     mainText:'Texto principal',
    //     menuDeleteRestriction:'Este Menú está restringido para borrar.',
    //     successfullyDeleted:'Eliminado exitosamente',
    //     successfullySaved:'Guardado con éxito',
    //     duplicateMenuTitle:'¡Título del menú duplicado!',
    //     duplicateURL:'¡URL duplicada!',
    //     duplicateOrderNo:'¡Núm. de pedido duplicado!',
    //     orderNomustgreaterthan0:'¡El número de pedido debe ser mayor que 0!',
    //     somethingUnexpected:'¡Algo inesperado!',
    //     successfullyUpdated:'Actualizado exitosamente',
    //     successfullyAssigned:'Asignado con éxito',
    //     successfullyUnAssigned:'Exitosamente no asignado',
    //     limitExpired:'Excede el límite de su plan de facturación. ¡No está permitido crear uno nuevo!',
    //     dateExpired:'Excede la fecha de su plan de facturación. ¡No se permite crear uno nuevo!',
    //     duplicateAssessmentTitle:'¡Título de evaluación duplicado!',
    //     backOnline:'¡De nuevo en línea!',
    //     backOnlineMsg:'La conexión ha vuelto y puedes empezar a navegar de nuevo.',
    //     backgroundColor:'Color de fondo',
    //     pleasecheckyourEmail:'Por favor revise su correo electrónico',
    //     emailsentSuccessful:'Correo electrónico enviado con éxito',
    //     notanimage:'no es una imagen',
    //     duplicateFaq:'¡Preguntas frecuentes duplicadas!',
    //     duplicateInstruction:'¡Instrucción duplicada!',
    //     successfullyRenewed:'Renovado con éxito',
    //     notallowedtodeletethisondemoversion:'¡esta operación no está permitida en la versión de demostración!',
    //     thisplanhasexistingusersNotallowedtodeletethis:'Este plan tiene usuarios existentes. No se permite eliminar esto.',
    //     duplicateTitle:'¡Título duplicado!',
    //     duplicatePrice:'¡Precio duplicado!',
    //     thereisnouserforthisemail:'No hay usuario para este correo electrónico',
    //     pleasepayfirstthisisaninvalidsession:'Por favor, pague primero. ¡Esta es una sesión inválida!',
    //     thisemailalreadyhaveauser:'Este correo electrónico ya tiene un usuario!',
    //     thisemailnotallowedtoregister:'Este correo electrónico no permite registrarse. ¡Por favor, compruebe!',
    //     rolerestricted:'Esta función está restringida para eliminar.',
    //     duplicateRolename:'¡Nombre de rol duplicado!',
    //     surveyReset:'Al cambiar a Levantamiento, los valores de tiempo marcados y requeridos se restablecen al estado inicial.',
    //     marksBasedReset:'Como cambió a la evaluación basada en calificaciones, debe establecer la respuesta correcta para todas las preguntas de esta evaluación.',
    //     questionWiseReset:'A medida que cambió a la opción de marca de conjunto inteligente de preguntas, debe verificar la respuesta correcta y marcar todas las preguntas de esta evaluación.',
    //     equalDistributionReset:'Como cambió a la opción de marca de distribución equitativa, debe volver a vivir esta evaluación.',
    //     marksChangedReset:'Como cambiaste las Marcas, tienes que volver a vivir esta Evaluación.',
    //     liveInterruptionForNoQuestion:'No es posible vivir esta evaluación. ¡Por favor agregue algunas preguntas!',
    //     liveInterruptionForNoCorrectAnswer:'No es posible vivir esta evaluación. Las preguntas no tienen una respuesta correcta. ¡Por favor, configúrelas primero!',
    //     liveInterruptionForNoMarksSet:'No es posible vivir esta evaluación. Los signos de interrogación aún no se han establecido. ¡Por favor, póngalos primero!',
    //     thissssessmentislivenow:'¡Esta evaluación ya está disponible!',
    //     thisAssessmentisstoppedfromlive:'¡Esta evaluación se detuvo en vivo!',
    //     deleteAssessmentReset:'Como eliminó una pregunta de una evaluación en vivo, debe comenzar esta evaluación nuevamente desde la Lista de evaluaciones.',
    //     maxQuestionQuota:'¡Ya alcanzaste la cuota máxima de límite de preguntas! ¡No se permite crear uno nuevo!',
    //     duplicateQuestion:'¡Pregunta duplicada!',
    //     newQuestionAddAssessmentStart:'A medida que agregó una nueva pregunta a una evaluación en vivo, debe comenzar esta evaluación nuevamente desde la lista de evaluaciones',
    //     questionMarksChangedAssessmentStart:'Este elemento deja de estar activo cuando cambió las marcas de una evaluación en ejecución. debe comenzar esta evaluación nuevamente desde la lista de evaluaciones',
    //     bulkQuestionAdd:'A medida que agregó preguntas masivas, debe comenzar esta evaluación nuevamente desde la lista de evaluaciones si estaba activa',
    //     categoryUsageOthers:'Esta categoría se está utilizando con la pregunta. No es posible eliminar.',
    //     categoryExists:'¡Esta categoría ya existe!',
    //     pdf:'PDF',
    //     excel:'SOBRESALIR',
    //     csv:'CSV',
    //     typePassword:'Escriba contraseña',
    //     wrongPassword:'¡Contraseña incorrecta!',
    //     passwordNotMatched:'¡Nueva y Confirmar contraseña no coincide!',
    //     passwordChangedNotAllowedDemo:'El cambio de contraseña no está permitido para este usuario en la versión de demostración.',
    //     onlinePaymentnotallowed:'¡Pago en línea no permitido desde aquí!',
    //     pleaseCheckyouremail:'Por favor revise su correo electrónico',
    //     incorrectEmailPassword:'¡Correo electrónico/contraseña incorrectos!',
    //     errorNetworkConnection:'¡Error establecido en la conexión de red!',
    //     thisFieldMaynotbeEmpty:'Este campo no puede estar vacío',
    //     theValueMustbeanIntegerNumber:'El valor debe ser un número entero',
    //     theValueMustBeGreaterThanZero:'El valor debe ser mayor que cero.',
    //     emailSettingsNotDone:'¡La configuración de correo electrónico aún no se ha realizado! Pida a su administrador que lo haga.',
    //     noCheckedStudent:'¡No se encontraron candidatos marcados! Primero verifique los candidatos de la lista y luego envíeles un correo electrónico',
    //     emailSentSuccessfully:'Correo electrónico enviado con éxito',
    //     markCanNotBeLessZero:'Marcas Obtener no puede estar vacío y menos de 0!',
    //     marksObtaincannotgreaterthanQuestionMarks:'Mark Obtener no puede ser mayor que el signo de interrogación!',
    //     containsDescriptiveQuestions:'Esta evaluación contiene una pregunta descriptiva. Encontrará este resultado en los informes después de revisarlo el administrador.',
    //     student:'Alumno',
    //     yourAnswer:'Tu respuesta',
    //     questionMarks:'Signo de interrogación',
    //     thisAssessmentWilllive:'Esta evaluación vivirá según el cronograma.',
    //     paymentSettingsNotDone:'¡Tu configuración de pago aún no está lista! Establézcalos y luego imponga el precio aquí.',
    //     noScheduleStartDate:'¡No se encontró la fecha de inicio del programa! por favor pon uno.',
    //     scheduleStartDateShouldGreater:'¡La fecha de finalización del programa debe ser mayor o igual que la fecha de inicio del programa!',
    //     noCommaSeperatedEmail:'¡No se encontraron correos electrónicos separados por comas! Agregue algunos correos electrónicos separados por comas al correo electrónico de los participantes',
    //     passMarksshouldnotgreaterthanAssessmentMarks:'¡La marca de aprobación no debe ser mayor que la marca de evaluación!',
    //     writeYourAnswerHere:'Escribe tu respuesta aquí',   
    //     submitAnswer:'Enviar respuesta',     
    //     emptyAnswer:'¡Respuesta vacía!',
    //     multipleAnswernotAllowed:'¡Respuesta múltiple no permitida!',
    //     csvUploadInstruction:'¡No subido! Verifique su CSV cuidadosamente y también siga las instrucciones de carga.',
    //     csvUploadError:'¡Este no es un archivo CSV! Solo se permite la carga de archivos CSV',
    //     emptyCorrectAnswer:'¡La respuesta correcta está vacía! Verifique.',
    //     onlyYouTubeVideo:'¡Solo se permite el video incrustado de YouTube! Consulte el video de la pregunta.',
    //     billingPlanRenewalSuccess:'¡La renovación del plan de facturación se realizó correctamente! Inicie sesión de nuevo.',
    //     csvUpload:'Subir CSV',
    //     skip:'Saltar',
    //     start:'Comenzar',
    //     refresh:'Actualizar',
    //     pay:'Pago',
    //     billingCycleExpired:'¡Tu ciclo de facturación expiró!',
    //     singleAttemptLimitExpired:'¡Se ha superado el límite de intentos para esta evaluación!',
    //     totalAttemptLimitExpired:'¡Límite excedido! Se excedió su límite de evaluación.',
    //     allocationLimitExpired:'¡Límite excedido! Se excedió su asignación para todas las respuestas de evaluación.'
    // },
    // 'it': {   
    //     home:'Casa',
    //     pricing:'Prezzi',
    //     features:'Caratteristiche',
    //     contact:'Contatto',
    //     signIn:'Registrazione',
    //     getRegister:'Registrati',
    //     registerNow:'Iscriviti ora',
    //     contactUs:'Contattaci',
    //     admin:'Amministratore',
    //     candidate:'Candidati',
    //     assess:'Valutazioni',
    //     liveAssess:'Valutazioni dal vivo',
    //     question:'Domande',
    //     dashboardChart1:'Pagamento (data saggia)',
    //     dashboardChart2:'Partecipazione (data saggia)',
    //     dashboardChart3:'Partecipazione (argomento saggio)',
    //     dashboardChart4:'Accesso (data saggia)',
    //     language:'Lingua',
    //     personalize:'Personalizzare',
    //     leaveHeader:'Vuoi andartene?',
    //     leaveConfirm:'Premi Esci per uscire',
    //     stay:'Rimani qui',
    //     signOut:'Disconnessione',
    //     Profile:'Profilo',
    //     Password:'Parola dordine',
    //     Browsing:'Navigazione',
    //     FAQ:'Domande frequenti',
    //     email:'E-mail',
    //     telephone:'Telefono',
    //     message:'Messaggio',
    //     submit:'Invia',
    //     password:"parola d'ordine",
    //     name:'Nome',
    //     forgetPassword:'Dimenticare la password',
    //     register:'Registrati',
    //     close:'Chiudere',
    //     sentPassword:'Password inviata',
    //     candidateRegister:'Registrazione del candidato',
    //     adminRegister:'Registrazione amministratore',
    //     passwordLengthMessage:'Almeno 8 caratteri',
    //     required:'Necessario',
    //     min8Character:'Minimo 8 caratteri',
    //     emailInvalid:"L'email deve essere valida",
    //     assessments:'valutazioni',
    //     responsesForAllAssessment:'risposte per tutte le valutazioni',
    //     responsesForEachAssessment:'risposte per ogni valutazione',
    //     subscribe:'sottoscrivi',
    //     newMenu:'Nuovo Menù',
    //     editMenu:'Menù Modifica',
    //     menuTitle:'Titolo del menù',
    //     url:'URL',
    //     orderNo:"Numero d'ordine.",
    //     iconMaterial:'Icona (Icona materiale)',
    //     actions:'Azioni',
    //     save:'Salva',
    //     cancel:'Annulla',
    //     ok:'OK',
    //     deleteConfirmMessage:'Sei sicuro di voler eliminare questo articolo?',
    //     mobile:'Mobile',
    //     dateOfBirth:'Data di nascita',
    //     displayName:'Nome da visualizzare',
    //     role:'Ruolo',
    //     plan:'Piano',
    //     interval:'Intervallo',
    //     paymentMode:'Metodo di pagamento',
    //     expireyDate:'Data di scadenza',
    //     transactionDetail:'Dettaglio transazione',
    //     newUser:'Nuovo utente',
    //     editUser:'Modifica utente',
    //     billingPlan:'Piano di fatturazione',
    //     profilePicture:'Immagine del profilo',
    //     renewPlan:'Rinnova piano',
    //     previousPlan:'Piano precedente',
    //     previousPaymentMode:'Modalità di pagamento precedente',
    //     previousTransactionDetail:'Dettaglio della transazione precedente',
    //     renew:'Rinnovare',
    //     newPlan:'Nuovo Piano',
    //     newMode:'Nuova modalità',
    //     delete:'Elimina',
    //     description:'Descrizione',
    //     newRole:'Nuovo ruolo',
    //     editRole:'Modifica ruolo',
    //     assignedMenus:'Menu assegnati',
    //     edit:'Modificare',
    //     newFaq:'Nuove domande frequenti',
    //     editFaq:'Modifica domande frequenti',
    //     title:'Titolo',
    //     dateTime:'Appuntamento',
    //     assessment:'Valutazione',
    //     priceUsd:'Prezzo (USD)',
    //     questionPerAssessmentCount:'Domande (per valutazione)',
    //     responsePerAssessmentCount:'Risposta (per valutazione)',
    //     responseAllAssessmentCount:'Risposta (tutte le valutazioni)',
    //     startDate:'Data Inizio',
    //     endDate:'Data Fine',
    //     stripeSessionId:'Stripe Session ID',
    //     userEmail:"E-mail dell'utente",
    //     transactionEmail:'E-mail di transazione',
    //     editPlan:'Modifica piano',
    //     additionalText:'Testo aggiuntivo',
    //     Monthly:'Mensile',
    //     yearly:'Annuale',
    //     generalSettings:'impostazioni generali',
    //     examSettings:"Impostazioni dell'esame",
    //     paymentSettings:'Impostazioni di pagamento',
    //     emailSettings:"impostazioni dell 'email",
    //     colorSettings:'Impostazioni colore',
    //     landingPage:'Pagina di destinazione',
    //     emailText:'Testo e-mail',
    //     siteTitle:'Titolo del sito',
    //     welcomeMessage:'Messaggio di benvenuto',
    //     copyrightText:"Testo del diritto d'autore",
    //     allowWelcomeEmail:'Consenti e-mail di benvenuto',
    //     allowFAQ:'Consenti domande frequenti',
    //     siteLogo:'Logo del sito',
    //     appVersion:"Versione dell'app",
    //     siteFavicon:'Favicon del sito',
    //     browserrefreshendtheexam:"L'aggiornamento del browser termina l'esame",
    //     sitelogoonexampage:"Logo del sito sulla pagina dell'esame",
    //     stripe:'Stripe',
    //     currency:'Moneta',
    //     currencySymbol:'Simbolo di valuta',
    //     stripeSecretkey:'Stripe Secret key',
    //     port:'Porta e-mail',
    //     hostSmtp:'Host(SMTP)',
    //     appBar:"Barra dell'app",
    //     footer:'Piè di pagina',
    //     body:'Corpo',
    //     homeHeaderTop:'Intestazione iniziale in alto',
    //     homeDetailTop:'Dettaglio casa In alto',
    //     homeHeaderBottom:'Intestazione iniziale in basso',
    //     homeDetailBottom:'Dettaglio casa in basso',
    //     homeBox1Header:'Home Box1 Intestazione',
    //     homeBox1Detail:'Home Box1 Dettaglio',
    //     homeBox2Header:'Intestazione Home Box2',
    //     homeBox2Detail:'Home Box2 Dettaglio',
    //     homeBox3Header:'Intestazione Home Box3',
    //     homeBox3Detail:'Home Box3 Dettaglio',
    //     homeBox4Header:'Intestazione Home Box4',
    //     homeBox4Detail:'Home Box4 Dettaglio',
    //     feature1Header:'Caratteristica1 Intestazione',
    //     feature1Detail:'Caratteristica1 Dettaglio',
    //     feature2Header:'Caratteristica2 Intestazione',
    //     feature2Detail:'Caratteristica2 Dettaglio',
    //     feature3Header:'Feature3 Intestazione',
    //     feature3Detail:'Caratteristica3 Dettaglio',
    //     feature4Header:'Caratteristica4 Intestazione',
    //     feature4Detail:'Caratteristica4 Dettaglio',
    //     registrationText:'Testo di registrazione',
    //     conactusText:'Contattaci Testo',
    //     conactusTelephone:'Contattaci Telefono',
    //     conactusEmail:'Email di contatto',
    //     landingFooterText:'Testo del piè di pagina di destinazione',
    //     footerFacebook:'Piè di pagina Facebook',
    //     footerTwitter:'Twitter a piè di pagina',
    //     footerLinkedIn:'Piè di pagina LinkedIn',
    //     footerInstagram:'Piè di pagina Instagram',
    //     subject:'Argomento',
    //     header:'Intestazione',
    //     forgetPasswordEmail:'Password dimenticata E-mail',
    //     welcomeEmail:'E-mail di benvenuto',
    //     invitationEmail:'E-mail di invito',
    //     reportEmail:'E-mail di segnalazione',
    //     newPassword:'nuova password',
    //     confirmNewPassword:'Conferma la nuova password',
    //     change:'Modificare',
    //     logInTime:'Tempo di accesso',
    //     logOutTime:'Tempo di disconnessione',
    //     ip:'IP',
    //     browser:'Navigatore',
    //     browserVersion:'Versione browser',
    //     platform:'piattaforma',
    //     id:'ID',
    //     quizCategoryName:'Nome della categoria di domande',
    //     newCategory:'Nuova categoria',
    //     editCategory:'Modifica categoria',
    //     internetDisruption:'Interruzione di Internet!',
    //     noInternetMsg:"Sembra che la tua connessione Internet non sia stabile o che tu sia offline. Puoi rimanere qui, ti faremo sapere quando tornerai online.",
    //     entryRestricted:'Ingresso riservato',
    //     forbiddenMsg:'Non hai accesso qui. Stai provando qualcosa il cui accesso è limitato per te.',
    //     errorBackMsg:'Prova a tornare alla pagina di accesso facendo clic',
    //     here:'qui',
    //     pagenotfound:'pagina non trovata',
    //     notFoundMsg:'La pagina che stai cercando di raggiungere non è mai esistita in questa realtà o è migrata in un universo parallelo.',
    //     tokenExpired:'Token scaduto',
    //     unauthorizedMsg:'Il tuo token attuale è scaduto! devi accedere di nuovo.',
    //     numberofQuestions:'Numero di domande',
    //     timeMin:'Tempo (minuto)',    
    //     marks:'Segni',
    //     multipleInput:'Ingresso multiplo',
    //     multipleAnswer:'Risposta multipla',
    //     multitleAttempt:'Tentativo multiplo',
    //     scheduleStartDate:'Data di inizio pianificazione',
    //     scheduleEndDate:'Data di fine pianificazione',
    //     status:'Stato',
    //     goLive:'Andare in diretta',
    //     stopLive:'Interrompi dal vivo',
    //     newAssessment:'Nuova valutazione',
    //     editAssessment:'Modifica valutazione',
    //     markOption:"Contrassegna l'opzione",
    //     allowMultipleAttempt:'Consenti tentativo illimitato per un singolo utente',
    //     allowMultipleAnswer:'Rispondi a più risposte per singola domanda per utente',
    //     allowMultipleInput:'Opzione multipla impostata per singola domanda',
    //     reqTimeInMin:'Tempo richiesto (in minuti)',
    //     passMarks:'Punteggio minimo',
    //     certificateTemplate:'Modello di certificato',
    //     displayStopOption:"Visualizza l'opzione di arresto",
    //     displaySkipOption:"Visualizza l'opzione Salta",
    //     questionSuffle:'Rimescolamento delle domande',
    //     correctAnswerDisplay:'Visualizzazione della risposta corretta ai candidati',
    //     participantOption:'Opzione di partecipazione',
    //     sentInvitationtoEmail:"Invito inviato all'e-mail",
    //     participantsEmail:'Email dei partecipanti (separati da virgola)',
    //     sentLive:'Sei sicuro di voler inviare questa valutazione dal vivo?',
    //     stopFromLive:'Sei sicuro di interrompere questa valutazione dal vivo?',
    //     addQuestions:'Aggiungi domande',
    //     addInstructions:'Aggiungi istruzioni',
    //     newInstruction:'Nuova istruzione',
    //     editInstruction:'Modifica istruzione',
    //     instruction:'Istruzione',
    //     newQuestion:'Nuova domanda',
    //     editQuestion:'Modifica domanda',
    //     sampleCSV:'Campione CSV',
    //     uploadInstructions:'Carica istruzioni',
    //     questions:'Domande',
    //     category:'Categoria',
    //     correctAnswer:'Risposta corretta',
    //     codeSnippet:'Frammento di codice',
    //     questionType:'tipo di domanda',
    //     complexityLavel:'Livello di complessità',
    //     quizCategory:'Categoria domanda',
    //     questionYoutubeVideo:'Video della domanda (video incorporato di YouTube)',
    //     questionImage:'Immagine della domanda',
    //     answerExplanation:'Risposta Spiegazione',
    //     selectcategorytofilter:'Seleziona la categoria da filtrare',
    //     time:'Volta',
    //     minutes:' minuti',
    //     view:'Visualizzazione',
    //     newTemplate:'Nuovo modello',
    //     editTemplate:'Modifica modello',
    //     certificateTitle:'Titolo del certificato',
    //     certificateTopHeader:'Intestazione superiore del certificato',
    //     certificateMainText:'Testo principale del certificato',
    //     topLeftLogo:'Logo in alto a sinistra',
    //     topRightLogo:'Logo in alto a destra',
    //     bottomLogo:'Logo in basso',
    //     backGround:'Sfondo',
    //     signatureLeft:'Firma a sinistra',
    //     signatureLeftText:'Firma a sinistra del testo',
    //     signatureRight:'Firma a destra',
    //     signatureRightText:'Firma a destra del testo',
    //     selectonetofilter:'Selezionane uno da filtrare',
    //     sentEmail:'Invia una email',
    //     sentemailtocheckedstudents:'Invia email agli studenti selezionati',
    //     search:'Ricerca',
    //     timeTakenMin:'Tempo impiegato (min)',
    //     marksObtained:'Marco ottenuto',
    //     attemptNumber:'Numero di tentativi',
    //     attemptDateTime:'Tentativo Data-Ora',
    //     questionMark:'Punto interrogativo',
    //     answerInput:"Rispondi all'immissione",
    //     skipped:'Saltato?',
    //     yourMarks:'I tuoi segni',
    //     pendingExamine:'In attesa di esame',
    //     resultSheet:'Foglio dei risultati',
    //     certificate:'Certificato',
    //     amountPaidUSD:'Importo pagato (USD)',
    //     noAssessment:'Nessuna valutazione disponibile!',
    //     availableAssessment:'Valutazioni disponibili',
    //     instructions:'Istruzioni',
    //     continue:'Continua',
    //     Dashboard:'Pannello',
    //     Menus:'Menu',
    //     Roles:'Ruoli',
    //     Users:'Utenti',
    //     Category:'Categoria',
    //     Assessments:'Valutazioni',
    //     Questions:'Domande',
    //     Reports:'Rapporti',
    //     CertificateTemplate:'Modello di certificato',
    //     ExamineAndReports:'Esame e rapporti',
    //     Analytics:'Analitica',
    //     Contacts:'Contatti',
    //     Payments:'Pagamenti',
    //     Plans:'Piani',
    //     AppSettings:"Impostazioni dell'app",
    //     nameEmptyRuleLanding:'Il nome non può essere vuoto',
    //     nameCharacterRuleLanding:'La lunghezza deve essere maggiore o uguale a 6 caratteri',
    //     emailEmptyRuleLanding:"L'e-mail non può essere vuota",
    //     emailWrongRuleLanding:'Email sbagliata!',
    //     messageEmptyRuleLanding:'Il messaggio non può essere vuoto',
    //     messageCharacterRuleLanding:'La lunghezza deve essere maggiore o uguale a 10 caratteri',
    //     successfullySubmitted:'Inviato con successo',
    //     planExpiredMsg:'Il tuo piano di pagamento è scaduto! Fai clic su rinnova per ottenerlo di nuovo.',
    //     billingPlanRenewed:'Piano di fatturazione rinnovato',
    //     planRenewalSuccess:'Il rinnovo del piano di fatturazione è andato a buon fine! Accedi di nuovo.',
    //     newContentsAvailable:'Nuovi contenuti disponibili! Fare clic su Aggiorna per applicare le modifiche.',
    //     mainText:'Testo principale',
    //     menuDeleteRestriction:'Questo menu è limitato alla cancellazione.',
    //     successfullyDeleted:'Eliminato con successo',
    //     successfullySaved:'Salvato con successo',
    //     duplicateMenuTitle:'Titolo menu duplicato!',
    //     duplicateURL:'URL duplicato!',
    //     duplicateOrderNo:"Numero d'ordine duplicato!",
    //     orderNomustgreaterthan0:"Il numero d'ordine deve essere maggiore di 0!",
    //     somethingUnexpected:'Qualcosa di inaspettato!',
    //     successfullyUpdated:'Aggiornato con successo',
    //     successfullyAssigned:'Assegnato con successo',
    //     successfullyUnAssigned:"Annullamento dell'assegnazione riuscito",
    //     limitExpired:'Hai superato il limite del piano di fatturazione. Non è consentito crearne uno nuovo!',
    //     dateExpired:'Hai superato la data del tuo piano di fatturazione. Non è consentito crearne uno nuovo!',
    //     duplicateAssessmentTitle:'Titolo di valutazione duplicato!',
    //     backOnline:'Di nuovo in linea!',
    //     backOnlineMsg:'La connessione è tornata e puoi ricominciare a navigare.',
    //     backgroundColor:'Colore di sfondo',
    //     pleasecheckyourEmail:'Si prega di controllare la tua email',
    //     emailsentSuccessful:'Email inviata con successo',
    //     notanimage:"Non un'immagine",
    //     duplicateFaq:'Domande frequenti duplicate!',
    //     duplicateInstruction:'Istruzione duplicata!',
    //     successfullyRenewed:'Rinnovato con successo',
    //     notallowedtodeletethisondemoversion:'questa operazione non è consentita nella versione demo!',
    //     thisplanhasexistingusersNotallowedtodeletethis:'Questo piano ha utenti esistenti. Non è consentito eliminare questo.',
    //     duplicateTitle:'Titolo duplicato!',
    //     duplicatePrice:'Prezzo duplicato!',
    //     thereisnouserforthisemail:'Non ci sono utenti per questa email',
    //     pleasepayfirstthisisaninvalidsession:'Si prega di pagare prima. Questa è una sessione non valida!',
    //     thisemailalreadyhaveauser:'Questa e-mail non è autorizzata alla registrazione. Si prega di controllare!',
    //     thisemailnotallowedtoregister:'Questa e-mail non è autorizzata alla registrazione. Si prega di controllare!',
    //     rolerestricted:"Questo ruolo è limitato all'eliminazione.",
    //     duplicateRolename:'Nome ruolo duplicato!',
    //     surveyReset:'Quando si passa a Sondaggio, i valori Mark e Required time vengono reimpostati allo stato iniziale.',
    //     marksBasedReset:'Quando sei passato alla valutazione basata sui voti, devi impostare la risposta corretta per tutte le domande di questa valutazione.',
    //     questionWiseReset:"Quando sei passato all'opzione Imposta segno domanda saggia, devi controllare la risposta corretta e contrassegnare tutte le domande di questa valutazione.",
    //     equalDistributionReset:"Quando sei passato all'opzione per il punteggio di distribuzione equa, devi rivivere questa valutazione.",
    //     marksChangedReset:'Poiché hai cambiato i voti, devi rivivere questa valutazione.',
    //     liveInterruptionForNoQuestion:'Non è possibile vivere questa valutazione. Per favore, aggiungi alcune domande!',
    //     liveInterruptionForNoCorrectAnswer:'Non è possibile vivere questa valutazione. Le domande non hanno una risposta corretta. Per favore impostale prima!',
    //     liveInterruptionForNoMarksSet:'Non è possibile vivere questa valutazione. I punti delle domande non sono ancora stati impostati. Per favore, impostali prima!',
    //     thissssessmentislivenow:'Questa valutazione è in diretta ora!',
    //     thisAssessmentisstoppedfromlive:'Questa valutazione è interrotta dal vivo!',
    //     deleteAssessmentReset:"Poiché hai eliminato una domanda da una valutazione in tempo reale, devi ricominciare questa valutazione dall'elenco delle valutazioni.",
    //     maxQuestionQuota:'Hai già raggiunto la quota massima del limite di domande! Non è consentito crearne uno nuovo!',
    //     duplicateQuestion:'Domanda duplicata!',
    //     newQuestionAddAssessmentStart:"Poiché hai aggiunto una nuova domanda a una valutazione in tempo reale, devi ricominciare questa valutazione dall'elenco delle valutazioni",
    //     questionMarksChangedAssessmentStart:"Questo elemento viene interrotto dal vivo poiché hai modificato i segni di una valutazione in esecuzione. devi ricominciare questa valutazione dall'elenco delle valutazioni",
    //     bulkQuestionAdd:"Poiché hai aggiunto domande collettive, devi ricominciare questa valutazione dall'elenco delle valutazioni se era in diretta",
    //     categoryUsageOthers:'Questa categoria sta usando con la domanda. Non è possibile eliminare.',
    //     categoryExists:'Questa categoria esiste già!',
    //     pdf:'PDF',
    //     excel:'EXCELECCELLERE',
    //     csv:'CSV',
    //     typePassword:'Inserisci password',
    //     wrongPassword:'Password errata!',
    //     passwordNotMatched:'Nuova e Conferma password non abbinate!',
    //     passwordChangedNotAllowedDemo:'Modifica della password non consentita per questo utente nella versione demo.',
    //     onlinePaymentnotallowed:'Pagamento online non consentito da qui!',
    //     pleaseCheckyouremail:'Si prega di controllare la tua email',
    //     incorrectEmailPassword:'Email/password errata!',
    //     errorNetworkConnection:'Errore stabilito nella connessione di rete!',
    //     thisFieldMaynotbeEmpty:'Questo campo non può essere vuoto',
    //     theValueMustbeanIntegerNumber:'Il valore deve essere un numero intero',
    //     theValueMustBeGreaterThanZero:'Il valore deve essere maggiore di zero',
    //     emailSettingsNotDone:'Impostazioni e-mail non ancora completate! Chiedi al tuo amministratore di farlo.',
    //     noCheckedStudent:"Nessun candidato verificato trovato! Prima controlla i candidati dall'elenco, quindi invia loro un'e-mail",
    //     emailSentSuccessfully:'email inviata correttamente',
    //     markCanNotBeLessZero:'I voti ottenuti non possono essere vuoti e inferiori a 0!',
    //     marksObtaincannotgreaterthanQuestionMarks:'Mark Ottenere non può superare il punto interrogativo!',
    //     containsDescriptiveQuestions:"Questa valutazione contiene domande descrittive. Troverai questo risultato su Rapporti dopo la revisione da parte dell'amministratore.",
    //     student:'Alunno',
    //     yourAnswer:'La tua risposta',
    //     questionMarks:'Punto interrogativo',
    //     thisAssessmentWilllive:'Questa valutazione vivrà in base al programma.',
    //     paymentSettingsNotDone:'Le tue impostazioni di pagamento non sono ancora state completate! Si prega di impostare quelli quindi imporre il prezzo qui.',
    //     noScheduleStartDate:'Nessuna data di inizio programma trovata! per favore mettine uno.',
    //     scheduleStartDateShouldGreater:'La data di fine del programma deve essere maggiore o uguale alla data di inizio del programma!',
    //     noCommaSeperatedEmail:"Nessuna email separata da virgola trovata! Aggiungi alcune e-mail separate da virgole all'e-mail dei partecipanti",
    //     passMarksshouldnotgreaterthanAssessmentMarks:'Il punteggio minimo non deve superare il punteggio di valutazione!',
    //     writeYourAnswerHere:'Scrivi qui la tua risposta',   
    //     submitAnswer:'Inviare risposta',     
    //     emptyAnswer:'Risposta vuota!',
    //     multipleAnswernotAllowed:'Risposta multipla non consentita!',
    //     csvUploadInstruction:'Non caricato! Controlla attentamente il tuo CSV e segui anche le istruzioni per il caricamento.',
    //     csvUploadError:'Questo non è un file CSV! È consentito solo il caricamento di file CSV',
    //     emptyCorrectAnswer:'Risposta corretta vuota!controlla.',
    //     onlyYouTubeVideo:'È consentito solo il video di incorporamento di YouTube! Si prega di controllare il video della domanda.',
    //     billingPlanRenewalSuccess:'Il rinnovo del piano di fatturazione è andato a buon fine! Accedi di nuovo.',
    //     csvUpload:'Caricamento CSV',
    //     skip:'Saltare',
    //     start:'Inizio',
    //     refresh:'ricaricare',
    //     pay:'Paga',
    //     billingCycleExpired:'Il tuo ciclo di fatturazione è scaduto!',
    //     singleAttemptLimitExpired:'Il tuo limite di tentativi per questa valutazione è stato superato!',
    //     totalAttemptLimitExpired:'Limite superato! Il tuo limite di valutazione è stato superato.',
    //     allocationLimitExpired:'Limite superato! La tua allocazione per tutte le risposte alla valutazione è stata superata.'      

    // },
    // 'in': {   
    //     home:'घर',
    //     pricing:'मूल्य निर्धारण',
    //     features:'विशेषताएं',
    //     contact:'संपर्क करना',
    //     signIn:'साइन इन करें',
    //     getRegister:'पंजीकृत हो जाओ',
    //     registerNow:'अभी पंजीकरण करें',
    //     contactUs:'संपर्क करें',
    //     admin:'व्यवस्थापक',
    //     candidate:'उम्मीदवार',
    //     assess:'आकलन',
    //     liveAssess:'लाइव आकलन',
    //     question:'प्रशन',
    //     dashboardChart1:'भुगतान (तिथिवार)',
    //     dashboardChart2:'भागीदारी (तारीख वार)',
    //     dashboardChart3:'भागीदारी (विषयवार)',
    //     dashboardChart4:'लॉग इन करें (दिनांक वार)',
    //     language:'भाषा',
    //     personalize:'वैयक्तिकृत करें',
    //     leaveHeader:'छोड़ना चाहते हैं?',
    //     leaveConfirm:'जाने के लिए साइनआउट दबाएं',
    //     stay:'यहीं रुकिए',
    //     signOut:'प्रस्थान करें',
    //     Profile:'प्रोफ़ाइल',
    //     Password:'कुंजिका',
    //     Browsing:'ब्राउजिंग',
    //     FAQ:'सामान्य प्रश्न',
    //     email:'ईमेल',
    //     telephone:'टेलीफ़ोन',
    //     message:'संदेश',
    //     submit:'प्रस्तुत',
    //     password:'कुंजिका',
    //     name:'नाम',
    //     forgetPassword:'पासवर्ड भूल गए',
    //     register:'पंजीकरण करवाना',
    //     close:'बंद करना',
    //     sentPassword:'भेजा गया पासवर्ड',
    //     candidateRegister:'उम्मीदवार पंजीकरण',
    //     adminRegister:'व्यवस्थापक पंजीकरण',
    //     passwordLengthMessage:'कम से कम 8 अक्षर',
    //     required:'आवश्यक',
    //     min8Character:'न्यूनतम 8 वर्ण',
    //     emailInvalid:'ईमेल मान्य होना चाहिए',
    //     assessments:'आकलन',
    //     responsesForAllAssessment:'सभी मूल्यांकन के लिए प्रतिक्रियाएँ',
    //     responsesForEachAssessment:'प्रत्येक मूल्यांकन के लिए प्रतिक्रियाएँ',
    //     subscribe:'सदस्यता लेने के',
    //     newMenu:'नया मेनू',
    //     editMenu:'संपादन मेनू',
    //     menuTitle:'मेनू शीर्षक',
    //     url:'यूआरएल',
    //     orderNo:'आदेश संख्या.',
    //     iconMaterial:'चिह्न (सामग्री-चिह्न)',
    //     actions:'कार्रवाई',
    //     save:'सहेजें',
    //     cancel:'रद्द करना',
    //     ok:'ठीक है',
    //     deleteConfirmMessage:'क्या आप सुनिश्चित रूप से इस आइटम को मिटाना चाहते हैं?',
    //     mobile:'मोबाइल',
    //     dateOfBirth:'जन्म की तारीख',
    //     displayName:'प्रदर्शित होने वाला नाम',
    //     role:'भूमिका',
    //     plan:'योजना',
    //     interval:'मध्यान्तर',
    //     paymentMode:'भुगतान का प्रकार',
    //     expireyDate:'समाप्ति तिथि',
    //     transactionDetail:'लेन-देन का विवरण',
    //     newUser:'नया उपयोगकर्ता',
    //     editUser:'यूजर को संपादित करो',
    //     billingPlan:'बिलिंग योजना',
    //     profilePicture:'प्रोफ़ाइल फोटो',
    //     renewPlan:'नवीनीकरण योजना',
    //     previousPlan:'पिछली योजना',
    //     previousPaymentMode:'पिछला भुगतान मोड',
    //     previousTransactionDetail:'पिछला लेन-देन विवरण',
    //     renew:'नवीकरण',
    //     newPlan:'नई योजना',
    //     newMode:'नया मोड',
    //     delete:'मिटाना',
    //     description:'विवरण',
    //     newRole:'नयी भूमिका',
    //     editRole:'भूमिका संपादित करें',
    //     assignedMenus:'असाइन किया गया मेनू',
    //     edit:'संपादन करना',
    //     newFaq:'न्यू फैक',
    //     editFaq:'एफएक्यू संपादित करें',
    //     title:'शीर्षक',
    //     dateTime:'दिनांक समय',
    //     assessment:'मूल्यांकन',
    //     priceUsd:'मूल्य (यूएसडी)',
    //     questionPerAssessmentCount:'प्रश्न (प्रति मूल्यांकन)',
    //     responsePerAssessmentCount:'प्रतिक्रिया (प्रति आकलन)',
    //     responseAllAssessmentCount:'प्रतिक्रिया (सभी मूल्यांकन)',
    //     startDate:'दिनांक प्रारंभ',
    //     endDate:'तिथि समाप्ति',
    //     stripeSessionId:'धारी सत्र आईडी',
    //     userEmail:'उपयोगकर्ता ईमेल',
    //     transactionEmail:'लेन-देन ईमेल',
    //     editPlan:'योजना संपादित करें',
    //     additionalText:'अतिरिक्त पाठ',
    //     Monthly:'महीने के',
    //     yearly:'सालाना',
    //     generalSettings:'सामान्य सेटिंग्स',
    //     examSettings:'परीक्षा सेटिंग्स',
    //     paymentSettings:'भुगतान सेटिंग्स',
    //     emailSettings:'ईमेल सेटिंग',
    //     colorSettings:'रंग सेटिंग्स',
    //     landingPage:'लैंडिंग पेज',
    //     emailText:'ईमेल पाठ',
    //     siteTitle:'घटनास्थल शीर्षक',
    //     welcomeMessage:'स्वागत संदेश',
    //     copyrightText:'कॉपीराइट पाठ',
    //     allowWelcomeEmail:'स्वागत ईमेल की अनुमति दें',
    //     allowFAQ:'एफएक्यू की अनुमति दें',
    //     siteLogo:'साइट लोगो',
    //     appVersion:'एप्लिकेशन वेरीज़न',
    //     siteFavicon:'साइट फ़ेविकॉन',
    //     browserrefreshendtheexam:'ब्राउज़र रीफ्रेश परीक्षा समाप्त करें',
    //     sitelogoonexampage:'परीक्षा पृष्ठ पर साइट का लोगो',
    //     stripe:'Stripe',
    //     currency:'मुद्रा',
    //     currencySymbol:'मुद्रा चिन्ह',
    //     stripeSecretkey:'Stripe Secret key',
    //     port:'ईमेल पोर्ट',
    //     hostSmtp:'होस्ट (एसएमटीपी)',
    //     appBar:'ऐप बार',
    //     footer:'फ़ुटबाल',
    //     body:'शरीर',
    //     homeHeaderTop:'होम हैडर टॉप',
    //     homeDetailTop:'होम डिटेल टॉप',
    //     homeHeaderBottom:'होम हेडर बॉटम',
    //     homeDetailBottom:'होम डिटेल बॉटम',
    //     homeBox1Header:'होम बॉक्स1 हैडर',
    //     homeBox1Detail:'होम बॉक्स1 विवरण',
    //     homeBox2Header:'होम बॉक्स2 हैडर',
    //     homeBox2Detail:'होम बॉक्स2 विवरण',
    //     homeBox3Header:'होम बॉक्स3 हैडर',
    //     homeBox3Detail:'होम बॉक्स3 विवरण',
    //     homeBox4Header:'होम बॉक्स4 हैडर',
    //     homeBox4Detail:'होम बॉक्स4 विवरण',
    //     feature1Header:'फ़ीचर 1 हैडर',
    //     feature1Detail:'फ़ीचर 1 विवरण',
    //     feature2Header:'फ़ीचर 2 हैडर',
    //     feature2Detail:'फ़ीचर 2 विवरण',
    //     feature3Header:'फ़ीचर 3 हैडर',
    //     feature3Detail:'फ़ीचर 3 विवरण',
    //     feature4Header:'फ़ीचर 4 हैडर',
    //     feature4Detail:'फ़ीचर 4 विवरण',
    //     registrationText:'पंजीकरण पाठ',
    //     conactusText:'हमसे संपर्क करें पाठ',
    //     conactusTelephone:'हमसे संपर्क करें टेलीफोन',
    //     conactusEmail:'संपर्क-हमें ईमेल',
    //     landingFooterText:'लैंडिंग पाद पाठ',
    //     footerFacebook:'पाद फेसबुक',
    //     footerTwitter:'पाद चहचहाना',
    //     footerLinkedIn:'फुटर लिंक्डइन',
    //     footerInstagram:'फुटर इंस्टाग्राम',
    //     subject:'विषय',
    //     header:'हैडर',
    //     forgetPasswordEmail:'पासवर्ड ईमेल भूल जाओ',
    //     welcomeEmail:'स्वागत ईमेल',
    //     invitationEmail:'आमंत्रण ईमेल',
    //     reportEmail:'ईमेल की रिपोर्ट करें',
    //     newPassword:'नया पासवर्ड',
    //     confirmNewPassword:'नए पासवर्ड की पुष्टि करें',
    //     change:'परिवर्तन',
    //     logInTime:'लॉग इन समय',
    //     logOutTime:'लॉगआउट समय',
    //     ip:'आईपी',
    //     browser:'ब्राउज़र',
    //     browserVersion:'ब्राउज़र संस्करण',
    //     platform:'प्लैटफ़ॉर्म',
    //     id:'पहचान',
    //     quizCategoryName:'प्रश्न श्रेणी का नाम',
    //     newCategory:'नई श्रेणी',
    //     editCategory:'श्रेणी संपादित करें',
    //     internetDisruption:'इंटरनेट व्यवधान!',
    //     noInternetMsg:"ऐसा लगता है कि आपकी इंटरनेट कनेक्टिविटी स्थिर नहीं है या आप ऑफ़लाइन हैं। आप यहां रह सकते हैं, जब आप वापस ऑनलाइन आएंगे तो हम आपको बताएंगे.",
    //     entryRestricted:'प्रवेश प्रतिबंधित',
    //     forbiddenMsg:'यहां आपकी पहुंच नहीं है। आप कुछ ऐसा करने की कोशिश कर रहे हैं जिसकी पहुंच आपके लिए सीमित है.',
    //     errorBackMsg:'क्लिक करके साइन इन पृष्ठ पर वापस जाने का प्रयास करें',
    //     here:'यहाँ',
    //     pagenotfound:'पृष्ठ नहीं मिला',
    //     notFoundMsg:'आप जिस पृष्ठ को प्राप्त करने का प्रयास कर रहे हैं वह इस वास्तविकता में कभी अस्तित्व में नहीं था, या समानांतर ब्रह्मांड में स्थानांतरित हो गया है.',
    //     tokenExpired:'टोकन समाप्त हो गया',
    //     unauthorizedMsg:'आपका वर्तमान टोकन समाप्त हो गया है! आपको फिर से साइन इन करने की आवश्यकता है।',
    //     numberofQuestions:'प्रश्नों की संख्या',
    //     timeMin:'समय (मिनट)',    
    //     marks:'निशान',
    //     multipleInput:'एकाधिक इनपुट',
    //     multipleAnswer:'एकाधिक उत्तर',
    //     multitleAttempt:'बहुप्रयास',
    //     scheduleStartDate:'अनुसूची प्रारंभ तिथि',
    //     scheduleEndDate:'अनुसूची समाप्ति तिथि',
    //     status:'दर्जा',
    //     goLive:'प्रत्यक्ष जाना',
    //     stopLive:'लाइव बंद करो',
    //     newAssessment:'नया आकलन',
    //     editAssessment:'मूल्यांकन संपादित करें',
    //     markOption:'मार्क विकल्प',
    //     allowMultipleAttempt:'एकल उपयोगकर्ता के लिए असीमित प्रयास की अनुमति दें',
    //     allowMultipleAnswer:'उपयोगकर्ता द्वारा एक प्रश्न के लिए एकाधिक उत्तर लें',
    //     allowMultipleInput:'एकल प्रश्न के लिए एकाधिक विकल्प सेट',
    //     reqTimeInMin:'आवश्यक समय (मिनटों में)',
    //     passMarks:'उत्तीर्ण अंक',
    //     certificateTemplate:'प्रमाणपत्र टेम्पलेट',
    //     displayStopOption:'स्टॉप विकल्प प्रदर्शित करें',
    //     displaySkipOption:'छोड़ें विकल्प प्रदर्शित करें',
    //     questionSuffle:'प्रश्न फेरबदल',
    //     correctAnswerDisplay:'उम्मीदवारों को सही उत्तर प्रदर्शन',
    //     participantOption:'भागीदारी विकल्प',
    //     sentInvitationtoEmail:'ईमेल पर आमंत्रण भेजा गया',
    //     participantsEmail:'प्रतिभागियों का ईमेल (अल्पविराम से अलग)',
    //     sentLive:'क्या आप वाकई इस आकलन को लाइव भेजना चाहते हैं?',
    //     stopFromLive:'क्या आप वाकई इस मूल्यांकन को लाइव से रोकना चाहते हैं?',
    //     addQuestions:'प्रश्न जोड़ें',
    //     addInstructions:'निर्देश जोड़ें',
    //     newInstruction:'नया निर्देश',
    //     editInstruction:'निर्देश संपादित करें',
    //     instruction:'अनुदेश',
    //     newQuestion:'नया प्रश्न',
    //     editQuestion:'प्रश्न संपादित करें',
    //     sampleCSV:'नमूना सीएसवी',
    //     uploadInstructions:'निर्देश अपलोड करें',
    //     questions:'प्रशन',
    //     category:'वर्ग',
    //     correctAnswer:'सही उत्तर',
    //     codeSnippet:'सांकेतिक टुकड़ा',
    //     questionType:'प्रश्न प्रकार',
    //     complexityLavel:'जटिलता स्तर',
    //     quizCategory:'प्रश्न श्रेणी',
    //     questionYoutubeVideo:'प्रश्न वीडियो (यूट्यूब एम्बेड वीडियो)',
    //     questionImage:'प्रश्न छवि',
    //     answerExplanation:'उत्तर स्पष्टीकरण',
    //     selectcategorytofilter:'फ़िल्टर करने के लिए श्रेणी का चयन करें',
    //     time:'समय',
    //     minutes:' मिनट',
    //     view:'देखना',
    //     newTemplate:'नया टेम्पलेट',
    //     editTemplate:'टेम्प्लेट संपादित करें',
    //     certificateTitle:'प्रमाणपत्र शीर्षक',
    //     certificateTopHeader:'सर्टिफिकेट टॉप हैडर',
    //     certificateMainText:'प्रमाणपत्र मुख्य पाठ',
    //     topLeftLogo:'शीर्ष बायां लोगो',
    //     topRightLogo:'शीर्ष दायां लोगो',
    //     bottomLogo:'निचला लोगो',
    //     backGround:'पार्श्वभूमि',
    //     signatureLeft:'हस्ताक्षर बायां',
    //     signatureLeftText:'हस्ताक्षर वाम पाठ',
    //     signatureRight:'हस्ताक्षर अधिकार',
    //     signatureRightText:'हस्ताक्षर सही पाठ',
    //     selectonetofilter:'फ़िल्टर करने के लिए एक का चयन करें',
    //     sentEmail:'ईमेल भेजें',
    //     sentemailtocheckedstudents:'चेक किए गए छात्रों को ईमेल भेजें',
    //     search:'खोज',
    //     timeTakenMin:'लिया गया समय (मिनट)',
    //     marksObtained:'मार्क प्राप्त किया',
    //     attemptNumber:'प्रयास संख्या',
    //     attemptDateTime:'दिनांक-समय का प्रयास करें',
    //     questionMark:'प्रश्न चिन्ह',
    //     answerInput:'उत्तर इनपुट',
    //     skipped:'छोड़ दिया?',
    //     yourMarks:'आपका निशान',
    //     pendingExamine:'लंबित जांच',
    //     resultSheet:'रिजल्ट शीट',
    //     certificate:'प्रमाणपत्र',
    //     amountPaidUSD:'भुगतान की गई राशि (यूएसडी)',
    //     noAssessment:'कोई आकलन उपलब्ध नहीं है!',
    //     availableAssessment:'उपलब्ध आकलन',
    //     instructions:'निर्देश',
    //     continue:'जारी रखना',
    //     Dashboard:'डैशबोर्ड',
    //     Menus:'मेनू',
    //     Roles:'भूमिकाएँ',
    //     Users:'उपयोगकर्ताओं',
    //     Category:'वर्ग',
    //     Assessments:'आकलन',
    //     Questions:'प्रशन',
    //     Reports:'रिपोर्टों',
    //     CertificateTemplate:'प्रमाणपत्र टेम्पलेट',
    //     ExamineAndReports:'परीक्षा और रिपोर्ट',
    //     Analytics:'एनालिटिक्स',
    //     Contacts:'संपर्क',
    //     Payments:'भुगतान',
    //     Plans:'योजनाओं',
    //     AppSettings:'एप्लिकेशन सेटिंग',
    //     nameEmptyRuleLanding:'नाम खाली नहीं हो सकता',
    //     nameCharacterRuleLanding:'लंबाई 6 कैरैक्टर से अधिक या उसके बराबर होनी चाहिए',
    //     emailEmptyRuleLanding:'ईमेल खाली नहीं हो सकता',
    //     emailWrongRuleLanding:'गलत ईमेल!',
    //     messageEmptyRuleLanding:'संदेश खाली नहीं हो सकता',
    //     messageCharacterRuleLanding:'लंबाई 10 कैरैक्टर से अधिक या उसके बराबर होनी चाहिए',
    //     successfullySubmitted:'सफलतापूर्वक प्रस्तुत किया गया',
    //     planExpiredMsg:'आपकी भुगतान योजना समाप्त हो गई! इसे फिर से प्राप्त करने के लिए नवीनीकरण पर क्लिक करें.',
    //     billingPlanRenewed:'बिलिंग योजना का नवीनीकरण किया गया',
    //     planRenewalSuccess:'बिलिंग योजना का नवीनीकरण सफल रहा! फिर से साइन इन करें.',
    //     newContentsAvailable:'नई सामग्री उपलब्ध है! परिवर्तनों को प्रभावित करने के लिए ताज़ा करें पर क्लिक करें.',
    //     mainText:'मुख्य पाठ',
    //     menuDeleteRestriction:'यह मेनू हटाने के लिए प्रतिबंधित है.',
    //     successfullyDeleted:'सफलतापूर्वक हटा दिया गया',
    //     successfullySaved:'सफलतापूर्वक संचित कर लिया गया है',
    //     duplicateMenuTitle:'डुप्लिकेट मेनू शीर्षक!',
    //     duplicateURL:'डुप्लीकेट यूआरएल!',
    //     duplicateOrderNo:'डुप्लीकेट ऑर्डर नंबर!',
    //     orderNomustgreaterthan0:'आदेश संख्या 0 से अधिक होनी चाहिए!',
    //     somethingUnexpected:'कुछ अनपेक्षित!',
    //     successfullyUpdated:'सफलतापूर्वक उत्परिवर्तित',
    //     successfullyAssigned:'सफलतापूर्वक सौंपा गया',
    //     successfullyUnAssigned:'सफलतापूर्वक अन-असाइन किया गया',
    //     limitExpired:'आप अपनी बिलिंग योजना की सीमा पार कर चुके हैं। नया बनाने की अनुमति नहीं है!',
    //     dateExpired:'आप अपनी बिलिंग योजना तिथि पार कर चुके हैं। नया बनाने की अनुमति नहीं है!',
    //     duplicateAssessmentTitle:'डुप्लिकेट मूल्यांकन शीर्षक!',
    //     backOnline:'वापस ऑनलाइन!',
    //     backOnlineMsg:'कनेक्शन वापस आ गया है और आप फिर से ब्राउज़ करना शुरू कर सकते हैं।',
    //     backgroundColor:'पीछे का रंग',
    //     pleasecheckyourEmail:'कृपया अपनी ईमेल देखें',
    //     emailsentSuccessful:'ईमेल भेजा गया सफल',
    //     notanimage:'छवि नहीं',
    //     duplicateFaq:'डुप्लिकेट एफएक्यू!',
    //     duplicateInstruction:'डुप्लिकेट निर्देश!',
    //     successfullyRenewed:'सफलतापूर्वक नवीनीकरण किया गया',
    //     notallowedtodeletethisondemoversion:'डेमो संस्करण पर इस ऑपरेशन की अनुमति नहीं है!',
    //     thisplanhasexistingusersNotallowedtodeletethis:'इस योजना में मौजूदा उपयोगकर्ता हैं। इसे हटाने की अनुमति नहीं है.',
    //     duplicateTitle:'डुप्लीकेट शीर्षक!',
    //     duplicatePrice:'डुप्लीकेट कीमत!',
    //     thereisnouserforthisemail:'इस ईमेल के लिए कोई उपयोक्ता नहीं है',
    //     pleasepayfirstthisisaninvalidsession:'कृपया पहले भुगतान करें। यह एक अमान्य सत्र है!',
    //     thisemailalreadyhaveauser:'इस ईमेल में पहले से ही एक उपयोगकर्ता है!',
    //     thisemailnotallowedtoregister:'इस ईमेल को पंजीकृत करने की अनुमति नहीं है। कृपया जांचें!',
    //     rolerestricted:'यह भूमिका हटाने के लिए प्रतिबंधित है.',
    //     duplicateRolename:'डुप्लीकेट रोल नाम!',
    //     surveyReset:'जैसे ही आपने सर्वेक्षण पर स्विच किया, मार्क और आवश्यक समय मान प्रारंभिक अवस्था में रीसेट हो गए.',
    //     marksBasedReset:'जैसे ही आप अंक आधारित मूल्यांकन पर स्विच करते हैं, आपको इस मूल्यांकन के सभी प्रश्नों के लिए सही उत्तर निर्धारित करने की आवश्यकता होती है.',
    //     questionWiseReset:'जैसे ही आप प्रश्नवार सेट मार्क विकल्प पर स्विच करते हैं, आपको इस आकलन के सभी प्रश्नों के लिए सही उत्तर और चिह्न की जांच करने की आवश्यकता होती है.',
    //     equalDistributionReset:'जैसे ही आपने समान वितरण मार्क विकल्प पर स्विच किया, आपको इस आकलन को फिर से जीना होगा।',
    //     marksChangedReset:'जैसे तुमने निशान बदले, तुम्हें इस आकलन को फिर से जीना है।',
    //     liveInterruptionForNoQuestion:'इस आकलन को जीना संभव नहीं है। कृपया कुछ प्रश्न जोड़ें!',
    //     liveInterruptionForNoCorrectAnswer:'इस आकलन को जीना संभव नहीं है। प्रश्नों का कोई सही उत्तर नहीं है। कृपया उन्हें पहले सेट करें!',
    //     liveInterruptionForNoMarksSet:'इस मूल्यांकन को जीना संभव नहीं है। प्रश्न चिह्न अभी सेट नहीं किए गए हैं। कृपया उन्हें पहले सेट करें!',
    //     thissssessmentislivenow:'यह आकलन अभी लाइव है!',
    //     thisAssessmentisstoppedfromlive:'यह आकलन लाइव से रोक दिया गया है!',
    //     deleteAssessmentReset:'जैसा कि आपने एक लाइव मूल्यांकन से एक प्रश्न हटा दिया है, आपको मूल्यांकन सूची से इस आकलन को फिर से शुरू करना होगा.',
    //     maxQuestionQuota:'आप पहले ही प्रश्न सीमा के अधिकतम कोटा तक पहुँच चुके हैं! नया बनाने की अनुमति नहीं है!',
    //     duplicateQuestion:'डुप्लीकेट प्रश्न!',
    //     newQuestionAddAssessmentStart:'जैसे ही आपने एक लाइव असेसमेंट में एक नया प्रश्न जोड़ा, आपको असेसमेंट सूची से इस असेसमेंट को फिर से शुरू करना होगा',
    //     questionMarksChangedAssessmentStart:'यह आइटम लाइव से रोक दिया गया है क्योंकि आपने चल रहे आकलन के अंक बदल दिए हैं। आपको यह आकलन फिर से आकलन सूची से शुरू करना होगा',
    //     bulkQuestionAdd:'जैसा कि आपने बल्क प्रश्नों को जोड़ा है, यदि यह मूल्यांकन लाइव था, तो आपको इस मूल्यांकन को फिर से मूल्यांकन सूची से शुरू करना होगा',
    //     categoryUsageOthers:'यह श्रेणी प्रश्न के साथ प्रयोग कर रही है। हटाना संभव नहीं है.',
    //     categoryExists:'यह श्रेणी पहले से मौजूद है!',
    //     pdf:'पीडीएफ',
    //     excel:'एक्सेल',
    //     csv:'सीएसवी',
    //     typePassword:'पासवर्ड लिखें',
    //     wrongPassword:'गलत पासवर्ड!',
    //     passwordNotMatched:'नया और पुष्टि पासवर्ड मेल नहीं खाता!',
    //     passwordChangedNotAllowedDemo:'डेमो संस्करण पर इस उपयोगकर्ता के लिए पासवर्ड बदलने की अनुमति नहीं है.',
    //     onlinePaymentnotallowed:'यहां से ऑनलाइन भुगतान की अनुमति नहीं है!',
    //     pleaseCheckyouremail:'कृपया अपनी ईमेल देखें',
    //     incorrectEmailPassword:'गलत ईमेल/पासवर्ड!',
    //     errorNetworkConnection:'नेटवर्क कनेक्शन में स्थापित त्रुटि!',
    //     thisFieldMaynotbeEmpty:'यह फ़ील्ड खाली नहीं हो सकता',
    //     theValueMustbeanIntegerNumber:'मान एक पूर्णांक संख्या होना चाहिए',
    //     theValueMustBeGreaterThanZero:'मान शून्य से अधिक होना चाहिए',
    //     emailSettingsNotDone:'ईमेल सेटिंग अभी तक नहीं की गई है! कृपया अपने एडमिन से ऐसा करने के लिए कहें.',
    //     noCheckedStudent:'कोई चेक किए गए उम्मीदवार नहीं मिले! पहले उम्मीदवारों को सूची से जांचें फिर उन्हें ईमेल भेजा',
    //     emailSentSuccessfully:'सफलतापूर्वक भेजा गया ईमेल',
    //     markCanNotBeLessZero:'प्राप्त अंक खाली और 0 से कम नहीं हो सकते!',
    //     marksObtaincannotgreaterthanQuestionMarks:'मार्क प्राप्त करना प्रश्न चिह्न से अधिक नहीं हो सकता!',
    //     containsDescriptiveQuestions:'इस आकलन में वर्णनात्मक प्रश्न शामिल हैं। एडमिन द्वारा समीक्षा करने के बाद आपको यह परिणाम रिपोर्ट्स पर मिलेगा।',
    //     student:'छात्र',
    //     yourAnswer:'आपका उत्तर',
    //     questionMarks:'प्रश्न चिह्न',
    //     thisAssessmentWilllive:'यह मूल्यांकन शेड्यूल के आधार पर लाइव होगा।',
    //     paymentSettingsNotDone:'आपकी भुगतान सेटिंग अभी पूरी नहीं हुई है! कृपया उन्हें सेट करें और फिर यहां कीमत लगाएं।',
    //     noScheduleStartDate:'कोई शेड्यूल प्रारंभ दिनांक नहीं मिला! कृपया एक लगाएं।',
    //     scheduleStartDateShouldGreater:'शेड्यूल की समाप्ति तिथि, शेड्यूल की आरंभ तिथि से अधिक या उसके बराबर होनी चाहिए!',
    //     noCommaSeperatedEmail:'अल्पविराम से अलग किया गया कोई ईमेल नहीं मिला! प्रतिभागियों के ईमेल में अल्पविराम से अलग किए गए कुछ ईमेल जोड़ें',
    //     passMarksshouldnotgreaterthanAssessmentMarks:'पास मार्क असेसमेंट मार्क से बड़ा नहीं होना चाहिए!',
    //     writeYourAnswerHere:'अपना उत्तर यहाँ लिखें',   
    //     submitAnswer:'उत्तर सबमिट करें',     
    //     emptyAnswer:'खाली उत्तर!',
    //     multipleAnswernotAllowed:'एकाधिक उत्तर की अनुमति नहीं है!',
    //     csvUploadInstruction:'अपलोड नहीं किया गया! कृपया अपने सीएसवी की सावधानीपूर्वक जांच करें अपलोड निर्देशों का भी पालन करें।',
    //     csvUploadError:'यह एक CSV फ़ाइल नहीं है! केवल CSV फ़ाइल अपलोड की अनुमति है',
    //     emptyCorrectAnswer:'सही उत्तर खाली है! कृपया जांचें।',
    //     onlyYouTubeVideo:'केवल यूट्यूब एम्बेड वीडियो की अनुमति है! कृपया प्रश्न वीडियो देखें।',
    //     billingPlanRenewalSuccess:'बिलिंग योजना का नवीनीकरण सफल रहा! फिर से साइन इन करें।',
    //     csvUpload:'सीएसवी अपलोड',
    //     skip:'छोडना',
    //     start:'शुरू',
    //     refresh:'ताज़ा करना',
    //     pay:'भुगतान',
    //     billingCycleExpired:'आपका बिलिंग चक्र समाप्त हो गया!',
    //     singleAttemptLimitExpired:'इस मूल्यांकन के लिए आपके प्रयास की सीमा पार हो गई है!',
    //     totalAttemptLimitExpired:'सीमा पार हो गई! आपकी मूल्यांकन सीमा पार हो गई है।',
    //     allocationLimitExpired:'सीमा पार हो गई! सभी मूल्यांकन प्रतिक्रिया के लिए आपका आवंटन पार हो गया है।'      

    // },
};
const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'bn', // set fallback locale
    messages, // set locale messages
});
export default i18n;