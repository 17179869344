<template>
    <v-container class="px-0">
        <!-- <v-row class=""> -->
            <!-- <v-col cols="12" sm="4" md="4">
                <v-btn @click="generatePdf" small outlined>{{$t('pdf')}}</v-btn>
                <vue-excel-xlsx class="btnExcel" :data="itemsResults" :columns="excelColumnsResults" :filename="'quiz-result-table'" :sheetname="'quizes'">{{$t('excel')}}</vue-excel-xlsx>
                <v-btn small outlined><download-csv :data="itemsResults" name="quiz-result-table.csv">{{$t('csv')}}</download-csv></v-btn>
            </v-col>           -->
        <!-- </v-row> -->
        <v-row class="pt-4"> 
            <v-col cols="12" sm="8" md="6">
                <v-select
                    :label="$t('selectonetofilter')"
                    :items="filterReport"
                    item-text="text"
                    item-value="value"
                    v-on:change="filterResult"
                    prepend-icon="emoji_objects"
                    menu-props="auto"
                    clearable
                >
                </v-select>
            </v-col>
            <v-col cols="12" sm="8" md="6">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <!-- table -->
        <v-data-table
            :headers="headersResults"
            :items="itemsResults"
            :search="search"
            class="elevation-1"
        >
            <template v-slot:top>
                <v-dialog
                    v-model="dialogReport"
                    max-width="1000"
                >
                    <v-card>
                        <v-card-text style="word-break: keep-all;" >
                            <v-container class="px-0"> 
                                <v-row justify="center">
                                    <v-col cols="6" sm="2" class="font-weight-black"><h3>{{$t('resultSheet')}}</h3></v-col>
                                </v-row>    
                                                    
                                <v-data-table
                                    :headers="headersSingleResult"
                                    :items="itemsSingleResult"
                                    disable-pagination
                                    hide-default-footer
                                >
                                    <template v-slot:[`item.questionDetail`]="{item}">
                                        <span>{{formatQuestionDetail(item)}}</span> 
                                    </template>
                                    <template v-slot:[`item.isBuyTime`]="{item}">
                                        <span>{{formatBuyTime(item)}}</span> 
                                    </template>
                                    <template v-slot:[`item.debitBalance`]="{item}">
                                        <span>{{formatDebitBalance(item)}}</span> 
                                    </template>
                                </v-data-table>
                            </v-container>                           
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
            <template v-slot:[`item.actions`]="{item}">
                <v-icon
                    class="mr-2"
                    small
                    @click="getDetailResult(item)"
                >
                    description
                </v-icon>
            </template>
            <template v-slot:[`item.isExamined`]="{item}">
                <span>{{formatStatus(item)}}</span> 
            </template>
            <template v-slot:[`item.dateAdded`]="{item}">
                <span>{{formatDateTime(item)}}</span> 
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import config from '../../../public/config'

export default {
    name:'Admin',
    components:{
    //   Loading
    },
    data(){
        return{
            allSettings:{},
            singleSelect: false,
            rules:{
                required:value=>!!value||this.$t('required'),
                minimumVal:value=>value>0,
                minimumMark:value=>value>=0
            },
            selectedReportType:null,
            selectedQuiz:null,
            typeOfReports:[],
            search: '',
            dialogReport:false,
            dialogExamine:false,
            userInfo:{},
            quizes:[],
            itemsResults:[],
            filteredItemsResults:[],
            filterReport:[
                { value: 1, text:this.$t('january')},
                { value: 2, text:this.$t('february')},
                { value: 3, text:this.$t('march')},
                { value: 4, text:this.$t('april')},
                { value: 5, text:this.$t('may')},
                { value: 6, text:this.$t('june')},
                { value: 7, text:this.$t('july')},
                { value: 8, text:this.$t('august')},
                { value: 9, text:this.$t('september')},
                { value: 10, text:this.$t('octember')},
                { value: 11, text:this.$t('november')},
                { value: 12, text:this.$t('december')}
            ],
            headersResults:[
                {text:this.$t('userId'),value:'userId'},
                {text:this.$t('userName'),value:'fullName'},
                {text:this.$t('email'),value:'email'},
                {text:this.$t('title'),value:'quizTitle'},
                {text:this.$t('userQuizPosition'),value:'userQuizPosition'},
                {text:this.$t('cashBalance'),value:'cashBalance'},
                {text:this.$t('pointBalance'),value:'pointBalance'},
                {text:this.$t('dateTime'),value:'dateAdded'},
                {text:this.$t('actions'), value:'actions', sortable: false },
            ],            
            itemsSingleExamine:[],
            itemsSingleResult:[],
            headersFiltered:[
                {text:this.$t('question'),value:'questionDetail'},
                {text:this.$t('debitBalance'),value:'debitBalance'},
                {text:this.$t('buyTime'),value:'isBuyTime'},
            ],
            allowCorrectOption:null,
            name:'',
            email:'',
            dateAdded:'',
            quizTitle:'',
            timeTaken:'',
            quizMarks:'',
            marksObtained:'',
            nameExamine:'',
            emailExamine:'',
            quizTitleExamine:'',
            timeTakenExamine:'',
            quizMarksExamine:'',
            excelColumnsResults : [
                {label:'userId',field:'userId'},
                {label:'userName',field:'fullName'},
                {label:'email',field:'email'},
                {label:'title',field:'quizTitle'},
                {label:'userQuizPosition',field:'userQuizPosition'},
                {label:'cashBalance',field:'cashBalance'},
                {label:'pointBalance',field:'pointBalance'},
                {label:'dateTime',field:'dateAdded'},
            ],
            defaultLogoUrl:window.location.origin+'/upload/logo.png'          
        }
    },
    methods:{     
        generatePdf(){
            const doc = new jsPDF()
            doc.autoTable({
                margin: { top: 10,left:2,right:1 },
                body: this.itemsResults,
                columns: [
                    {header:this.$t('userId'),dataKey:'userId'},
                    {header:this.$t('userName'),dataKey:'fullName'},
                    {header:this.$t('email'),dataKey:'email'},      
                    {header:this.$t('title'),dataKey:'quizTitle'},   
                    {text:this.$t('userQuizPosition'),value:'userQuizPosition'},                 
                    {header:this.$t('cashBalance'),dataKey:'cashBalance'},
                    {header:this.$t('pointBalance'),dataKey:'pointBalance'},
                    {header:this.$t('dateTime'),dataKey:'dateAdded'},
                ],
            })
            doc.save('quiz-results-table.pdf')
        },
        chkQuizSelected(){
            if(this.isEmailAllowed==false){
                this.dialogEmail=false
                setTimeout(() => {
                    this.$root.$emit('message_from_parent_long',this.$t('emailSettingsNotDone'));
                }, 2000);
            }else if(this.selectedEmails.length==0){
                this.dialogEmail=false
                setTimeout(() => {
                    this.$root.$emit('message_from_parent_long',this.$t('noCheckedStudent'));
                }, 2000);
            }else
            {
                this.dialogEmail=true
            }
        },
        sentEmail(){
            if(this.$refs.form.validate()){
                var inputEmails=this.selectedEmails.map(e=>e.email)
                var formatEmails=[]
                for(var i=0;i<inputEmails.length;i++){
                    if(inputEmails[i].length>3){
                        formatEmails.push({'email':inputEmails[i],'logoPath':'','siteUrl':'','body':''})
                    }           
                }
                const obj={
                    logoPath:config.hostname+(this.$store.getters['settings/logoPath']==''?this.defaultLogoUrl:this.$store.getters['settings/logoPath'].replace(/\\/g, '/')),      
                    siteUrl:window.location.origin,
                    subject:this.emailSubject,
                    body:this.emailBody,
                    emails:formatEmails
                }
                this.$store.dispatch('report/sentCheckedEmailToNotifyStudents',obj)
                // this.$root.$emit('message_from_parent_long',this.$t('emailSentSuccessfully'))
                setTimeout(() => {
                    this.$root.$emit('message_from_parent_long',this.$t('emailSentSuccessfully'));
                }, 2000);
                this.dialogEmail=false
            }
        },
        submitAnswer(id){
            var questionMark=document.getElementById('q-'+id).innerHTML          
            var inputMark=document.getElementById('m-'+id).value
            if(inputMark=='' || inputMark<0){
                // this.$root.$emit('message_from_parent_long',this.$t('markCanNotBeLessZero'))
                setTimeout(() => {
                    this.$root.$emit('message_from_parent',this.$t('markCanNotBeLessZero'));
                }, 2000);
            }else if(inputMark>questionMark){
                // this.$root.$emit('message_from_parent_long',this.$t('marksObtaincannotgreaterthanQuestionMarks'))
                setTimeout(() => {
                    this.$root.$emit('message_from_parent_long',this.$t('marksObtaincannotgreaterthanQuestionMarks'));
                }, 2000);
            }else{
                const obj={
                    id:id,
                    marks:document.getElementById('m-'+id).value
                }
                this.$store.dispatch('report/saveMarksObtain',obj)
                .then(response=>{
                    if(response.status==200){
                        this.quizExamine(parseInt(response.data.responseMsg))
                    }
                })
                .catch(err=>{
                    console.log(err)
                    this.$router.push({name:'NoInternet'})
                })
            }
        },
        //List of data in the table
        getQuizes(){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('report/fetchReportResults')
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsResults=response.data
                this.filteredItemsResults=this.itemsResults
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        filterResult(selectedMonth) {
            if (selectedMonth == null) {
                this.itemsResults = this.filteredItemsResults;
            } else {
                const filteredData = this.filteredItemsResults.filter(item => {
                    const itemDate = new Date(item.dateAdded);
                    return itemDate.getMonth() + 1 === selectedMonth;
                });
                console.log(filteredData)
                this.itemsResults = filteredData;
            }
        },  
        quizResult(id) {
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('dashboard/fetchFinishedExamResult', id)
            .then((response) => {
                this.$store.dispatch('dashboard/cancelLoading')
                if (response && response.data) {
                    this.itemsSingleResult = response.data
                } else {
                    console.error("Response or response data is undefined.");
                }
            })
            .catch((err) => {
                console.log(err)
                this.$router.push({ name: 'NoInternet' })
            })
        },
        getDetailResult(item){
            this.dialogReport=true
            this.quizResult(item.quizResponseInitialId)           
        },
        getExamineData(item){
            this.dialogExamine=true
            this.quizExamine(item.quizResponseInitialId)
        },
        chkEmailAllowed(){
            if(this.allSettings.defaultEmail=='' || this.allSettings.password=='' || this.allSettings.port==0 || this.allSettings.host==''){
                this.isEmailAllowed=false
            }else{
                this.isEmailAllowed=true
            }
        },
        chkReportVisibility(item){
            return item.isExamined==true?true:false
        },
        chkReviewVisibility(item){
            return item.isExamined==true?false:true
        },
        formatQuizMarks(item){
            return item.quizMark==0?'n/a':item.quizMark
        },
        formatQuestionDetail(item){
            return item.questionDetail!=''?item.questionDetail.replace(/#####/g,','):''
        },
        formatDebitBalance(item){
            return item.debitBalance==0?'n/a':item.debitBalance
        },
        formatBuyTime(item){
            return item.isBuyTime==true?'yes':'no'
        },
        formatObtainedMarks(item){
            return item.isExamined==false?'':(item.quizMark==0?'n/a':item.userObtainedQuizMark)
        },
        formatStatus(item){           
            return item.isExamined==true && item.quizMark>0 && (item.userObtainedQuizMark>=item.quizPassMarks)?'Po':(item.quizMark==0?'n/a':(item.isExamined==false?'Pending':'Failed'))
        },
        formatAnswerSkipped(item){
            return item.isAnswerSkipped==true?'yes':'no'
        },
        formatQuestionMarks(item){
            return item.questionMark==0?'n/a':item.questionMark
        },
        formatUserMarks(item){
            return item.questionMark==0?'n/a':item.userObtainedQuestionMark
        },
        formatDateTime(item){
            return item.dateAdded.replace('T',' ').substring(0,19)
        },
        formatCorrectAnswer(item){
            return item.correctAnswer!='' && item.correctAnswer!=null?item.correctAnswer.replace(/#####/g,','):''
        },
    },
    computed:{
        participantName:function(){
            return this.name
        },
        participantEmail:function(){
            return this.email
        },
        quizName:function(){
            return this.quizTitle
        },
        quizAssignTime:function(){
            return this.timeTaken
        },
        quizAssignMarks:function(){
            return this.quizMarks
        },
        userObtainedMarks:function(){
            return this.marksObtained
        },
        headersSingleResult:function(){
            return this.userInfo.roleName=='Admin'?this.headersFiltered:
            (this.userInfo.roleName=='Student' && this.allowCorrectOption==true?this.headersFiltered:this.headersFiltered.filter(function(item){
                return item.text!='Correct Answer'
            }))
        },
        participantNameExamine:function(){
            return this.nameExamine
        },
        participantEmailExamine:function(){
            return this.emailExamine
        },
        quizNameExamine:function(){
            return this.quizTitleExamine
        },
        quizAssignTimeExamine:function(){
            return this.timeTakenExamine
        },
        quizAssignMarksExamine:function(){
            return this.quizMarksExamine
        },
    },
    created(){
        this.userInfo=this.$store.getters['dashboard/userInfo']
        this.allSettings=this.$store.getters['settings/allSettings']
        this.getQuizes()
        this.chkEmailAllowed()    
    }
}
</script>