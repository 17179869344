<template>
    <v-container class="px-0">
        <Message/>
        <!-- <v-btn @click="generatePdf" small outlined>{{$t('pdf')}}</v-btn>
        <vue-excel-xlsx class="btnExcel" :data="itemsTopics" :columns="excelColumnsTopics" :filename="'topics-table'" :sheetname="'quiz'">{{$t('excel')}}</vue-excel-xlsx>
        <v-btn small outlined><download-csv :data="itemsTopics" name="topics-table.csv">{{$t('csv')}}</download-csv></v-btn> -->
        <v-data-table
        :headers="headersTopics"
        :items="itemsTopics"
        class="elevation-1 mt-5"
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >         
                    <v-dialog
                        v-model="dialog"
                        max-width="900"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                class="text-capitalize mb-2"
                                v-bind="attrs"
                                v-on="on"               
                                absolute
                                right
                            >
                            {{$t('newAssessment')}}
                            </v-btn>
                        </template>
                        <v-card>
                            <Loading/>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-form ref="form">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.quizTitle"
                                                    :label="$t('title')"
                                                    :rules="[rules.required]"
                                                    clearable                       
                                                ></v-text-field>
                                        </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-select
                                                    v-model="categoryOptionSelect"
                                                    :label="$t('quizCategory')"
                                                    :items="categoryOptionItems"
                                                    item-text="quizCategoryName"
                                                    item-value="quizCategoryId"
                                                    :rules="[rules.required]"                                                                                              
                                                    clearable
                                                    return-object                       
                                                ></v-select> 
                                            </v-col>

                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-menu
                                                    v-model="startTime"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="editedItem.quizscheduleStartTime"
                                                            :label="$t('scheduleStartDate')"
                                                            prepend-icon="mdi-calendar"
                                                            :rules="[rules.required]"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            clearable                                                         
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="editedItem.quizscheduleStartTime"
                                                        @input="startTime = false"
                                                        :min="currentDate"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>                                                               
                                        </v-row>
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                            >
                                                <v-checkbox
                                                    v-model="editedItem.isLekAward"
                                                    :label="$t('isLekAward')" 
                                                >
                                                </v-checkbox>
                                            </v-col>  
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                                v-if="editedItem.isLekAward==true"
                                            >
                                                <v-checkbox
                                                    v-model="editedItem.isIpRequired"
                                                    :label="$t('isIpRequired')"
                                                >
                                                </v-checkbox>
                                            </v-col>                                    
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                                v-if="editedItem.isLekAward==true"
                                            >
                                                <v-text-field
                                                    v-model="editedItem.quizPrice"                                       
                                                    :label="$t('quizPrice')"
                                                    type="number"                                      
                                                    :rules="[rules.required,rules.minimumVal]"
                                                    clearable                       
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>                
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="close"
                                >
                                {{$t('cancel')}}
                                </v-btn>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                                >
                                {{$t('save')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="590px">
                        <v-card>
                            <v-card-title class="headline">{{$t('deleteConfirmMessage')}}</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDialog">{{$t('cancel')}}</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{$t('ok')}}</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogStartQuiz" max-width="620px">
                        <v-card>
                            <v-card-title class="headline">{{$t('sentLive')}}</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDialog">{{$t('cancel')}}</v-btn>
                                <v-btn color="blue darken-1" text @click="startQuizConfirm">{{$t('ok')}}</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogStopQuiz" max-width="570px">
                        <v-card>
                            <v-card-title class="headline">{{$t('stopFromLive')}}</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDialog">{{$t('cancel')}}</v-btn>
                                <v-btn color="blue darken-1" text @click="stopQuizConfirm">{{$t('ok')}}</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.publish`]="{item}"> 
                <v-tooltip v-if="item.isRunning==false" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small                                         
                            @click="startQuiz(item)" 
                            v-bind="attrs"
                            v-on="on"               
                        >
                            play_arrow
                        </v-icon>
                    </template>
                    <span>{{$t('goLive')}}</span>
                </v-tooltip> 
                <v-tooltip v-if="item.isRunning==true" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small               
                            @click="stopQuiz(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                            block
                        </v-icon>
                    </template>
                    <span>{{$t('stopLive')}}</span>
                </v-tooltip>    
            </template>
            <template v-slot:[`item.actions`]="{item}">
                <v-tooltip  v-if="!item.isQuizSolved" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="editItem(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-pencil
                        </v-icon>
                    </template>                   
                    <span>{{$t('edit')}}</span>
                </v-tooltip>  
                <v-tooltip v-if="!item.isQuizSolved" top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="addQuestions(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                            add_circle
                        </v-icon>
                    </template>                   
                    <span>{{$t('addQuestions')}}</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="addInstructions(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                            add_comment
                        </v-icon>
                    </template>                   
                    <span>{{$t('addInstructions')}}</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="deleteItem(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-delete
                        </v-icon>
                    </template>                   
                    <span>{{$t('delete')}}</span>
                </v-tooltip>
            </template>
           <template v-slot:[`item.isLekAward`]="{item}">
                <span>{{formatLekAward(item)}}</span> 
            </template>
            <template v-slot:[`item.isQuizSolved`]="{item}">
                <span>{{formatIsQuizSolved(item)}}</span> 
            </template>
            <template v-slot:[`item.isIpRequired`]="{item}">
                <span>{{formatMultipleAttempt(item)}}</span> 
            </template>
            <template v-slot:[`item.quizscheduleStartTime`]="{item}">
                <span>{{formatStartTime(item)}}</span> 
            </template>
            <template v-slot:[`item.quizscheduleEndTime`]="{item}">
                <span>{{formatEndTime(item)}}</span> 
            </template>
            <template v-slot:[`item.quizTotalMarks`]="{item}">
                <span>{{formatQuizMarks(item)}}</span> 
            </template>
            <template v-slot:[`item.isRunning`]="{item}">
                <v-chip text-color="primary" outlined dark>{{formatRunning(item)}}</v-chip> 
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import config from '../../../public/config'
import Message from '../../components/common/Message'
import Loading from '../../components/common/Loading'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
    name:'Topics',
    components:{
      Message,
      Loading
    },
    data(){
        return{
            allSettings:{},
            isEmailAllowed:false,
            isPaymentAllowed:false,
            emailInvitationToEmail:false,
            topicId:null,
            isParticipantsEmailVisible:false,
            isLekVisible: false,
            isMultipleInputVisible:false,
            startTime:false,
            endTime:false,
            templateOptionItems:[],
            quizStartDate:null,
            categoryOptionSelect: null,
            categoryOptionItems:[],     
            rules:{
                required:value=>!!value||this.$t('required'),
                minimumVal:value=>value>0,
                passVal:value=>value>=0
            },
            dialog:false,
            dialogDelete:false,
            dialogStartQuiz:false,
            dialogStopQuiz:false,
            headersTopics:[
                {text:this.$t('title'),value:'quizTitle'},
                {text:this.$t('category'),value:'quizCategoryName'},
                {text:this.$t('question'),value:'questionsCount'},
                {text:this.$t('quizPrice'),value:'quizPrice'},
                {text:this.$t('ipRequired'),value:'isIpRequired'},
                {text:this.$t('stardDate'),value:'quizscheduleStartTime'},
                {text:this.$t('solved'),value:'isQuizSolved'},
                {text:this.$t('status'),value:'isRunning'},
                {text:this.$t('goLive'), value: 'publish', sortable: false},
                {text:this.$t('actions'), value: 'actions', sortable: false},
            ],
            itemsTopics:[],
            editedIndex:-1,
            editedItem:{
                quizTopicId:null,
                quizTitle:'',
                quizCategoryId:null,
                certificateTemplateId:null,
                isLekAward:false,
                isIpRequired:false,
                allowQuizStop:true,
                allowQuizSkip:false,
                quizscheduleStartTime:null,
                quizscheduleEndTime:null,
                quizPrice:0,           
            },
            defaultItem:{
                quizTopicId:null,
                quizTitle:'',
                quizCategoryId:null,
                certificateTemplateId:null,
                isLekAward:false,
                isIpRequired:false,
                allowQuizStop:true,
                allowQuizSkip:false,
                allowQuestionSuffle:false,
                quizscheduleStartTime:null,
                quizscheduleEndTime:null,
                quizPrice:0              
            },
            excelColumnsTopics : [
                {label:'Title',field:'quizTitle'},
                {label:'Number of Questions',field:'questionsCount'},
                {label:'Lek Award',field:'isLekAward'},
                {label:'Quiz Price',field:'quizPrice'},
                {label:'IP Required',field:'isI'},
                {label:'Schedule Start Time',field:'quizscheduleStartTime'},       
           ],
           defaultLogoUrl:window.location.origin+'/upload/logo.png',
           userInfo:{}
        }
    },
    methods:{
        generatePdf(){
            const doc = new jsPDF()
            doc.autoTable({
                margin: { top: 10,left:2,right:1 },
                body: this.itemsTopics,
                columns: [
                    {header:'Title',dataKey:'quizTitle'},
                    {header:'Number of Questions',dataKey:'questionsCount'},
                    {header:'Lek Award',dataKey:'isLekAward'},
                    {header:'Quiz Price',dataKey:'quizPrice'},
                    {header:'IP Required',dataKey:'isIpRequired'},
                    {header:'Schedule Start Time',dataKey:'quizscheduleStartTime'},
                    {header:'Schedule End Time',dataKey:'quizscheduleEndTime'}                    
                ],
            })
            doc.save('topics-table.pdf')
        },
        chkLekAwardOptions(obj)
        {
            if(obj==false)
            {
                this.isLekVisible = false;
            }
            else
            {
                this.isLekVisible = true;
            }
        },
        chkTemplateOptions(obj){
            if(obj==null){
                this.editedItem.certificateTemplateId=null
            }else{
                this.editedItem.certificateTemplateId=obj.certificateTemplateId
            }
        },
        formatLekAward(item){
            return item.isLekAward==true?'yes':'no'
        },
        formatMultipleAttempt(item){
            return item.isIpRequired==true?'yes':'no'
        },
        formatIsQuizSolved(item){
            return item.isQuizSolved==true?'yes':'no'
        },
        formatStartTime(item){
            return item.quizscheduleStartTime==null?null:item.quizscheduleStartTime.substring(0,10)
        },
        formatEndTime(item){
            return item.quizscheduleEndTime==null?null:item.quizscheduleEndTime.substring(0,10)
        },
        formatRunning(item){
            if(item.isRunning){
                if(item.quizscheduleStartTime!=null && item.quizscheduleEndTime==null){
                    if(new Date().toJSON().slice(0,10)>=new Date(item.quizscheduleStartTime).toJSON().slice(0,10)){
                        return 'Live'
                    }else{
                        return 'Draft'
                    }
                }else if(item.quizscheduleStartTime!=null && item.quizscheduleEndTime!=null){
                    if(new Date().toJSON().slice(0,10)>=new Date(item.quizscheduleStartTime).toJSON().slice(0,10) && new Date().toJSON().slice(0,10)<=new Date(item.quizscheduleEndTime).toJSON().slice(0,10)){
                        return 'Live'
                    }else{
                        return 'Draft'
                    }
                }else{
                    return 'Live'
                }
            }else{
                return 'Draft'
            }
        },
        getCategoryOptions(){
            this.$store.dispatch('quiz/fetchCategoryOptions',this.userInfo.userId)
            .then((response)=>{
                this.categoryOptionItems=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        getTemplates(){
            this.$store.dispatch('quiz/fetchTemplates')
            .then((response)=>{
                this.templateOptionItems=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        initialize(){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('quiz/fetchQuizTopics')
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsTopics=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        editItem(item){
            item.quizscheduleStartTime=item.quizscheduleStartTime==null?null:item.quizscheduleStartTime.substr(0,10)
            item.quizscheduleEndTime=item.quizscheduleEndTime==null?null:item.quizscheduleEndTime.substr(0,10)
            this.chkLekAwardOptions(this.isLekAward)
        
            this.categoryOptionSelect={quizCategoryId:item.quizCategoryId}
        
            this.templateOptionSelect={certificateTemplateId:item.certificateTemplateId}

            this.editedIndex=this.itemsTopics.indexOf(item)
            this.editedItem=Object.assign({},item)
            this.dialog=true
        },
        addQuestions(item){
            this.$store.dispatch('question/fetchSingleQuizTopic',item.quizTopicId)
            .then(response=>{
                if(response.status==200){
                    this.$router.push({name:'QuizQuestions'})
                }else if(response.status==202){
                    this.$root.$emit('message_from_parent_long',response.data.responseMsg)
                }
            })
            .catch(err=>{
                console.log(err)
            })
        },
        addInstructions(item){
            this.$store.dispatch('question/storeSingleQuiz',item)
            this.$router.push({name:'Instruction'})
        },
        startQuiz(item){
            this.editedItem = Object.assign({}, item)
            this.dialogStartQuiz=true
        },
        startQuizConfirm(){
            this.$store.dispatch('dashboard/applyLoading')
            this.quizStartDate=this.editedItem.quizscheduleStartTime
            this.$store.dispatch('quiz/startQuiz',this.editedItem)
            .then(response=>{
                this.$store.dispatch('dashboard/cancelLoading')
                if(response.status==200){
                    if(this.quizStartDate!=null){
                        this.$root.$emit('message_from_parent_long',this.$t('thisAssessmentWilllive'))
                    }else{
                        this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                    }
                    this.quizStartDate=null
                    this.$store.dispatch('quiz/fetchQuizTopics')
                    .then((response)=>{
                        this.itemsTopics=response.data                  
                    })
                    .catch((err)=>{
                        console.log(err)
                    })
                }else if(response.status==202){
                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                }
            })
            .catch(err=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
            this.closeDialog()
        },
        stopQuiz(item){
            this.editedItem = Object.assign({}, item)
            this.dialogStopQuiz=true
        },
        stopQuizConfirm(){
            this.$store.dispatch('quiz/stopQuiz',this.editedItem)
            .then(response=>{
                if(response.status==200){
                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                    this.$store.dispatch('quiz/fetchQuizTopics')
                    .then((response)=>{
                        this.itemsTopics=response.data                  
                    })
                    .catch((err)=>{
                        console.log(err)
                    })
                }else if(response.status==202){
                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                }
            })
            .catch(err=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
            this.closeDialog()
        },
        deleteItem(item){
            this.editedItem = Object.assign({}, item)
            this.dialogDelete=true
        },
        deleteItemConfirm () {        
            this.$store.dispatch('quiz/deleteQuizTopic',this.editedItem.quizTopicId)
            .then(response=>{
                if(response.status==200){
                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                    this.$store.dispatch('quiz/fetchQuizTopics')
                    .then((response)=>{
                        this.itemsTopics=response.data                  
                    })
                    .catch((err)=>{
                        console.log(err)
                    })
                }else if(response.status==202){
                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                }
            })
            .catch(err=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
            this.closeDialog()
        },
        close(){
            this.dialog=false
            this.$nextTick(()=>{   
                this.templateOptionSelect=null        
                this.editedItem=Object.assign({},this.defaultItem)
                this.editedIndex=-1
            })
        },
        closeDialog () {
            this.dialogDelete = false
            this.dialogStartQuiz=false
            this.dialogStopQuiz=false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        saveParticipantsEmail(){
            var inputEmails=this.participantsEmail.trim().split(',')
            var sendEmails=[]
            for(var i=0;i<inputEmails.length;i++){
                if(inputEmails[i].length>5){
                    sendEmails.push({'quizTopicId':this.topicId,'email':inputEmails[i].trim()})
                }           
            }
            this.$store.dispatch('quiz/insertParticipantsEmail',sendEmails)
            .then(response=>{
                if(response.status==200){
                    if(this.emailInvitationToEmail==true){
                        var logoPath=config.hostname+(this.$store.getters['settings/logoPath']==''?this.defaultLogoUrl:this.$store.getters['settings/logoPath'].replace(/\\/g, '/'))
                        var siteUrl=window.location.origin
                        var registrationLink=siteUrl
                        var siteTitle=this.$store.getters['settings/siteTitle']

                        var invitationEmails=[]
                        for(var i=0;i<inputEmails.length;i++){
                            if(inputEmails[i].length>5){
                                invitationEmails.push({'siteTitle':siteTitle,'email':inputEmails[i].trim(),'registrationLink':registrationLink,'logoPath':logoPath,'siteUrl':siteUrl})
                            }           
                        }
                        this.$store.dispatch('quiz/inviteParticipantsByEmail',invitationEmails)
                    }
                }
            })
            .catch(err=>{
                console.log(err)
            })
        },
        chkEmailAllowed(){
            if(this.allSettings.defaultEmail!='' && this.allSettings.password!='' &&this.allSettings.port!=0 &&this.allSettings.host!=''){
                this.isEmailAllowed=true
            }else{
                this.isEmailAllowed=false
            }
        },
        save (){
            if(this.$refs.form.validate()){
                const objTopic={
                    quizTitle:this.editedItem.quizTitle.trim(),
                    quizCategoryId:this.categoryOptionSelect.quizCategoryId,
                    certificateTemplateId:this.editedItem.certificateTemplateId,
                    isLekAward:this.editedItem.isLekAward,
                    isIpRequired:this.editedItem.isIpRequired,
                    allowQuizStop:this.editedItem.allowQuizStop,
                    allowQuizSkip:this.editedItem.allowQuizSkip,
                    allowQuestionSuffle:this.editedItem.allowQuestionSuffle,
                    quizscheduleStartTime:this.editedItem.quizscheduleStartTime,
                    quizscheduleEndTime:this.editedItem.quizscheduleEndTime,
                    quizPrice:this.editedItem.quizPrice=='' || this.editedItem.quizPrice==null?0:this.editedItem.quizPrice,
                    addedBy:parseInt(localStorage.getItem('loggedUserId')),
                }
                if(this.emailInvitationToEmail==true && this.isEmailAllowed==false){
                    this.$root.$emit('message_from_parent_long',this.$t('emailSettingsNotDone'))
                }else if(this.editedItem.quizscheduleStartTime==null && this.editedItem.quizscheduleEndTime!=null){
                    this.$root.$emit('message_from_parent_long',this.$t('noScheduleStartDate'))
                }else if(this.editedItem.quizscheduleStartTime!=null && this.editedItem.quizscheduleEndTime!=null&&new Date(this.editedItem.quizscheduleStartTime)>new Date(this.editedItem.quizscheduleEndTime)){
                    this.$root.$emit('message_from_parent_long',this.$t('scheduleStartDateShouldGreater'))
                }else if(objTopic.quizParticipantOptionId==2 && this.participantsEmail.search(',')==-1){
                    this.$root.$emit('message_from_parent_long',this.$t('noCommaSeperatedEmail'));
                }
                else{  
                    this.$store.dispatch('dashboard/applyLoading')                  
                    if (this.editedIndex > -1){
                        objTopic.quizTopicId=this.editedItem.quizTopicId
                        objTopic.lastUpdatedBy=parseInt(localStorage.getItem('loggedUserId'))
                        
                        this.$store.dispatch('quiz/updateQuizTopic',objTopic)
                        .then(response=>{
                            this.$store.dispatch('dashboard/cancelLoading')
                            if(response.status==200){
                                this.topicId=this.editedItem.quizTopicId
                                this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                                this.$store.dispatch('quiz/fetchQuizTopics')
                                .then((response)=>{                              
                                    this.close()
                                    this.itemsTopics=response.data
                                    if(objTopic.quizParticipantOptionId==2){
                                        this.saveParticipantsEmail()
                                    }
                                })
                                .catch((err)=>{
                                    console.log(err)
                                })
                            }else if(response.status==202){
                                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                                this.$router.push({name:'NoInternet'})
                            })

                    }else{ 
                        this.$store.dispatch('quiz/createQuizTopic',objTopic)
                        .then(response=>{
                            this.$store.dispatch('dashboard/cancelLoading')
                            if(response.status==200){
                                this.topicId=response.data.quizTopicId
                                this.$root.$emit('message_from_parent',this.$t('successfullySaved'))
                                this.$store.dispatch('quiz/fetchQuizTopics')
                                .then((response)=>{                              
                                    this.close()
                                    this.itemsTopics=response.data
                                    if(objTopic.quizParticipantOptionId==2){
                                        this.saveParticipantsEmail()
                                    }
                                })
                                .catch((err)=>{
                                    console.log(err)
                                })
                            }else if(response.status==202){
                                    this.$root.$emit('message_from_parent_long',this.$t(response.data.responseMsg))
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                                this.$router.push({name:'NoInternet'})
                            })
                
                    }
                }
            }
        },
    },
    computed:{
        currentDate() {
            const today = new Date();
            // Ensure proper formatting for the min prop
            return today.toISOString().split('T')[0];
        },
        formTitle(){
            return this.editedIndex===-1?this.$t('newAssessment'):this.$t('editAssessment')
        },
        chkLekAward(){
            return this.isLekVisible
        },
        chkMultipleInputVisibility(){
            return this.isMultipleInputVisible
        },        
        chkLekSetVisibility(){
            return this.isSetLekVisible
        },
        getCurrency(){
            return this.$store.getters['settings/currencySymbol']
        }
    },
    watch:{
        dialog (val) {         
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDialog()
        },
        dialogStartQuiz(val){
            val || this.closeDialog()
        },
        dialogStopQuiz(val){
            val || this.closeDialog()
        },
    },
    created(){
        this.userInfo=this.$store.getters['dashboard/userInfo']
        this.allSettings=this.$store.getters['settings/allSettings']
        this.initialize()
        this.getCategoryOptions()
        this.getTemplates()
    }
}
</script>