<template>
    <v-container class="px-0">
        <v-row v-if="itemsCategories.length == 0 && roleName == 'Student'" justify="center"
            class="font-weight-bold">{{ $t('noAssessment') }}</v-row>
        <v-row v-else-if="itemsCategories.length > 0 && roleName == 'Student'" justify="center"
            class="font-weight-bold">{{ $t('Category') }}</v-row>
        <v-row justify="center" v-if="roleName == 'Student'">
            <v-col cols="12" sm="6" md="4" v-for="item in itemsCategories" :key="item.quizTopicId">
                <v-card color="grey lighten-3" shaped elevation="6" v-if="item.quizTopicsCount > 0">
                    <v-card-text class="pb-0">
                        <p class="title text--primary">{{ item.quizCategoryName }}</p>
                    </v-card-text>
                    <v-list class="pa-0">
                        <v-list-item class="py-0">
                            <v-list-item-icon><v-icon>help</v-icon></v-list-item-icon>
                            <v-list-item-title>{{ $t('quizCard') }}</v-list-item-title>
                            <v-list-item-title>{{ item.quizTopicsCount }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    <v-card-actions>
                        <v-btn class="white--text" color="grey darken-2" @click="initializeCategory(item)">{{
            $t('startQuiz') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="p-5" v-if="allQuizTopicsCountZero() && roleName == 'Student'">
            <h3>{{ $t('noCategoriesAvailable') }}</h3>
        </v-row>
        <v-row v-if="roleName == 'Admin'">
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card :to="{ name: 'Users' }" class="grey lighten-1">
                    <v-card-title>
                        <v-icon x-large left>{{ this.userIcon }}</v-icon>
                    </v-card-title>
                    <v-card-text>{{ $t('candidate') }} <span class="black--text">{{ totalStudents }}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card :to="{ name: 'QuizTopics' }" class="grey lighten-2">
                    <v-card-title>
                        <v-icon x-large left>{{ this.assessIcon }}</v-icon>
                    </v-card-title>
                    <v-card-text>{{ $t('assess') }} <span class="black--text">{{ totalQuizes }}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card :to="{ name: 'QuizTopics' }" class="grey lighten-3">
                    <v-card-title>
                        <v-icon x-large left>{{ this.assessIcon }}</v-icon>
                    </v-card-title>
                    <v-card-text>{{ $t('liveAssess') }} <span class="black--text">{{ liveQuizes }}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card :to="{ name: 'Quizes' }" class="grey lighten-4">
                    <v-card-title>
                        <v-icon x-large left>{{ this.questionIcon }}</v-icon>
                    </v-card-title>
                    <v-card-text>{{ $t('question') }} <span class="black--text">{{ totalQuestions }}</span></v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark>
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-toolbar-title>{{ $t('instructions') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn text class="text-capitalize" @click="continueQuiz()">
                                <h3>{{ $t('continue') }}</h3>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-timeline align-top dense>
                        <v-timeline-item small dark v-for="item in itemsInstruction" :key="item.instructionId">
                            {{ item.description }}
                        </v-timeline-item>
                    </v-timeline>
                </v-card>
            </v-dialog>
        </v-row>
        <Analytics class="pa-0" v-if="roleName=='Admin'" />
    </v-container>
</template>

<script>
import config from '../../../public/config'
import Analytics from '../../components/common/Analytics'

export default {
    name: 'Dashboard',
    components: {
        Analytics,
    },
    data() {
        return {
            itemsCategories: [],
            itemsQuiz: [],
            itemsInstruction: [],
            userInfo: {},
            totalStudents: '',
            totalQuizes: '',
            liveQuizes: '',
            totalQuestions: '',
            userId: null,
            dialog: false,
            notifications: false,
            userIcon: '',
            assessIcon: '',
            questionIcon: '',
            allSettings: {}
        }
    },
    methods: {
        allQuizTopicsCountZero() {
            return this.itemsCategories.every(topic => topic.quizTopicsCount === 0);
        },
        fetchMenus() {
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('menu/fetchMenu')
                .then((response) => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    this.userIcon = response.data[3].iconClass
                    this.assessIcon = response.data[5].iconClass
                    this.questionIcon = response.data[6].iconClass
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        initializeCategories() {
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('dashboard/fetchCategories', this.userInfo.userId)
                .then((response) => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    this.itemsCategories = response.data
                })
                .catch((err) => {
                    console.log(err)
                    this.$router.push({ name: 'NoInternet' })
                })
        },
        initializeAdmin() {
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('dashboard/fetchSummary')
                .then((response) => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    if (response.status == 200) {
                        this.totalStudents = response.data.totalStudents
                        this.totalQuizes = response.data.totalQuizes
                        this.liveQuizes = response.data.liveQuizes
                        this.totalQuestions = response.data.totalQuestions
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.$router.push({ name: 'NoInternet' })
                })
        },
        initializeCategory(item) {
            this.$store.dispatch('dashboard/applyLoading')
            const obj = {
                quizCategoryId: item.quizCategoryId,
                userId: this.userInfo.userId
            }
            this.$store.dispatch('dashboard/fetchCategoryQuizes', obj)
                .then((response) => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    if (response.status == 200 && response.data.length > 0) {
                        this.$router.push({ name: 'CategoryQuizes' })
                    } else if (response.status == 200 && response.data.status == 'error') {
                        this.$root.$emit('message_from_parent_long', response.data.responseMsg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        makeRenewal() {
            var url = new URL(window.location.href);
            var renewId = url.searchParams.get('renew_id')
            if (renewId) {
                var stripeUniqueId = renewId.replace(/{|}/g, '')
                fetch(config.hostname + '/checkout-session?sessionId=' + stripeUniqueId)
                    .then(response => response.json())
                    .then(data => {
                        if (data.id == stripeUniqueId && data.paymentStatus == 'paid') {
                            const objNewPlan = {
                                addedBy: this.userInfo.userId,
                                transactionEmail: data.customerDetails.email,
                                userEmail: this.userInfo.email,
                                stripeSessionId: stripeUniqueId,
                                billingPlanId: localStorage.getItem('billingPlanId'),
                                paymentMode: 'Online',
                                transactionDetail: stripeUniqueId
                            }
                            this.$store.dispatch('user/updateBillingPlan', objNewPlan)
                                .then((response) => {
                                    if (response.status == 200) {
                                        this.$router.push({ name: 'Landing' })
                                        this.$root.$emit('message_from_parent_long',this.$t('billingPlanRenewalSuccess'))  
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                            }
                        }
                    )
                    .catch(function (err) {
                        console.log('Error when fetching Checkout session', err);
                        this.$router.push({ name: 'NoInternet' })
                    }
                );
            }
        }
    },
    computed: {
        roleName() {
            return this.userInfo.roleName
        }
    },
    created() {
        this.userInfo = this.$store.getters['dashboard/userInfo']
        this.$store.dispatch('dashboard/changeVisibility')
        this.$store.dispatch('dashboard/saveQuestionSerial', 0)
        this.$store.dispatch('dashboard/saveSerialToShow', 1)
        this.$store.dispatch('dashboard/saveQuizFlag', false)
        this.fetchMenus()
        if (this.userInfo.roleName == 'Student') {
            this.makeRenewal()
            this.initializeCategories()
        } else if (this.userInfo.roleName == 'Admin') {
            this.initializeAdmin()
        }
    }
}
</script>